// src/routes/AllRoutes.jsx
import React from "react";
import { Routes, Route } from "react-router-dom";
import Product from "../Pages/Product";
import Homepage from "../Pages/Homepage";
import Profile from "../Pages/Profile";
import Recommendations from "../Pages/Recommendations";
import Savedproject from "../Pages/Savedproject";
import CommingSoon from "../Pages/CommingSoon";
import Login from "../Components/Login";
import Signup from "../Components/Signup";
import FilmsPage from "../Pages/Films";
import FilmsDetailsComponent from "../Pages/Films/FilmsDetails/FilmsDetails";
import Start from "../Pages/Start/Start";
import Project from "../Pages/Start/Project/Project";
import Edit from "../Pages/Start/Project/Edit/Edit";
import Category from "../Pages/Films/FilmsDetails/Category/Category";
import ForgotPassword from "../Components/ForgotPassword";
import ResetPassword from "../Components/ResetPassword";
import { ScrollToTop } from "../libs/functions";
import AuthGuard from "../libs/authGuard";
import Settings from "../Pages/Settings/Settings";
import ProductCheckoutPage from "../Components/StripeCheckout/ProductCheckout";
import SuccessPageComponent from "../Components/StripeCheckout/successPage";
import MyProjects from "../Pages/MyProjects/MyProjects";
import AdminAuthGuard from "../libs/adminGuard";
import AdminAllProjects from "../Pages/Admin/AllProjects/AllProjects";
import ProjectDetails from "../Pages/MyProjects/ProjectDetails";
import AdminProjectDetails from "../Pages/Admin/AllProjects/ProjectDetails";
import AdminWithdrawRequests from "../Pages/Admin/AllProjects/WithdrawalRequests";
import AdminAllCreators from "../Pages/Admin/AllProjects/AllCreators";
import MyBackList from "../Pages/My-Backlist/MyBacklist";
import Home from "../Pages/Home";
import ViewProfile from "../Pages/Settings/ViewProfile/ViewProfile";
import ViewFullProfile from "../Pages/ViewFullProfile/ViewFullProfile";
import AdminApprovalRequests from "../Pages/Admin/AllProjects/ApprovalRequests";



const AllRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/view-campaigns" element={<Homepage />} />
        <Route path="/view-full-profile/:id" element={<ViewFullProfile />} />
        <Route path="/profile/:id" element={<ViewFullProfile />} />
        <Route path="/films" element={<FilmsPage />} />
        <Route path="/film/:id" element={<FilmsDetailsComponent />} />
        <Route path="/product" element={<Product />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/recommendations" element={<Recommendations />} />
        <Route path="/profile/savedprojects" element={<Savedproject />} />
        <Route path="/create-project" element={<CommingSoon />} />
        <Route path="/project/:id" element={<Project />} />
        <Route path="/project-stats/:id" element={<ProjectDetails />} />
        <Route path="/back-this/:id" element={<Category />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/checkout/:id" element={<AuthGuard><ProductCheckoutPage /></AuthGuard>} />
        <Route path="/success" element={<SuccessPageComponent />} />

        {/* Protected Routes */}
        <Route path="/start" element={<AuthGuard><Start /></AuthGuard>} />
        <Route path="/settings" element={<AuthGuard><Settings /></AuthGuard>} />
        <Route path="/my-projects" element={<AuthGuard><MyProjects /></AuthGuard>} />
        <Route path="/my-backlist" element={<AuthGuard><MyBackList /></AuthGuard>} />
        <Route path="/view-profile" element={<AuthGuard><ViewProfile /></AuthGuard>} />
        <Route
          path="/edit/:id"
          element={
            <AuthGuard>
              <Edit />
            </AuthGuard>
          }
        />

        {/* Admin Protected Routes */}
        <Route path="/all-projects" element={<AdminAuthGuard><AdminAllProjects /></AdminAuthGuard>} />
        <Route path="/project-details/:id" element={<AdminAuthGuard><AdminProjectDetails /></AdminAuthGuard>} />
        <Route path="/withdraw-requests" element={<AdminAuthGuard><AdminWithdrawRequests /></AdminAuthGuard>} />
        <Route path="/approval-requests" element={<AdminAuthGuard><AdminApprovalRequests /></AdminAuthGuard>} />
        <Route path="/all-creators" element={<AdminAuthGuard><AdminAllCreators /></AdminAuthGuard>} />

      </Routes>
    </>
  );
};

export default AllRoutes;
