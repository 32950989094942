import { Box, Button, Flex, Grid, GridItem, Input, Text, IconButton, Image } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { FaPlus, FaEdit, FaTrash, FaImage, FaTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import useUpdateProject from "../../../../../../libs/useUpdateProjectService";
import axiosInstance from "../../../../../../libs/axiosService";

const Item = ({ item, onEdit, onDelete }) => {
  return (
    <Flex
      p={4}
      mb={4}
      borderWidth={1}
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Image
          src={item.image ? process.env.REACT_APP_API_BASE_URL + item.image : ""}
          alt={item.title}
          boxSize="100px"
          objectFit="cover"
          mr={4}
        />
        <Box textAlign="left" marginLeft="20px">
          <Text fontWeight="bold" fontSize="lg">{item.title}</Text>
        </Box>
      </Flex>
      <Flex>
        <IconButton
          icon={<FaEdit />}
          mr={2}
          onClick={() => onEdit(item._id)}
        />
        <IconButton
          icon={<FaTrash />}
          onClick={() => onDelete(item._id)}
        />
      </Flex>
    </Flex>
  );
};

const ItemForm = ({ onItemAdded, setItemFormVisible, editItem, setEditItem }) => {
  const [title, setTitle] = useState(editItem ? editItem.title : "");
  const [image, setImage] = useState(editItem ? editItem.image : "");

  const handleSave = () => {
    const newItem = {  title, image };
    onItemAdded(newItem);
    setItemFormVisible(false);
  };



  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);


    try {
      const response = await axiosInstance.post('/upload', formData); // Adjust the URL according to your API
      setImage(response?.data?.urls?.image); // Adjust according to your API response
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      console.log('Image uploaded successfully');
    }
  };

  const handleImageDelete = (url) => {
    // get the image name from the URL
    const imageName = url.split('/').pop();
    // send a DELETE request to the server
    axiosInstance.delete(`/uploads/${imageName}`)
      .then(response => {
        // clear the selected image
        setImage("");

      })
      .catch(error => console.error('Error deleting image:', error));

    setImage("");
  };


  return (
    <Box textAlign="start">
      <Text fontSize="35px">Add a new item</Text>
      <Box mt="50px">
        <Grid templateColumns={{ base: "1fr", lg: "1fr 2fr" }} gap="20px">
          <GridItem colSpan={{ base: 1, lg: 1 }}>
            <Text fontSize="18px">Item title</Text>
            <Text color="#656969">Add a title that quickly and clearly describes this item</Text>
          </GridItem>
          <GridItem colSpan={{ base: 1, lg: 1 }}>
            <Input
              mt="10px"
              type="text"
              placeholder="Digital photo"
              size="md"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </GridItem>
        </Grid>

        <Grid mt={"30px"} templateColumns={{ base: "1fr", lg: "1fr 2fr" }} gap={"20px"}>
          <GridItem colSpan={{ base: "1fr" }}>
            <Box>
              <Text fontSize={"15px"} fontWeight={""}>
                Item image (optional)
              </Text>
              <Text fontSize={""} color={"#656969"}>
                Add a picture of your item to help backers <br /> understand exactly what comes with their <br /> rewards.
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: "1fr" }}>
            {(!image || image === "") ? (
              <Box
                border={"1px solid rgba(0,0,0,0.1)"}
                p={"40px"}
                width={"100%"}
                bg={"#FBFBFA"}
                position="relative"
              >
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  opacity="0"
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  cursor="pointer"
                />
                <Flex justifyContent={"center"} mb={"30px"}>
                  <Box
                    borderRadius={"50%"}
                    bg={"white"}
                    p={"20px"}
                    boxShadow={"1px 1px 10px gray"}
                  >
                    <FaImage size={"30px"} />
                  </Box>
                </Flex>
                <Text
                  fontSize={""}
                  textAlign={"center"}
                  fontWeight={""}
                  color={""}
                >
                  Drop an image here or select a file
                </Text>
                <Text
                  fontSize={"14px"}
                  textAlign={"center"}
                  fontWeight={""}
                  mt={"5px"}
                  color={"#656E72"}
                >
                  It must be a JPG, PNG, GIF, or WEBP, no larger than 50 MB.
                </Text>
              </Box>
            ) : (
              <Box position="relative" width={"50%"}>
                <Image src={process.env.REACT_APP_API_BASE_URL + image} alt="Selected" borderRadius="10px" />
                <IconButton
                  icon={<FaTrashAlt />}
                  position="absolute"
                  top="10px"
                  right="10px"
                  onClick={() => handleImageDelete(image)}
                  aria-label="Delete Image"
                />
              </Box>
            )}
          </GridItem>
        </Grid>



        <Flex justifyContent="end" mt="20px">
          <Button
            mr="20px"
            colorScheme="gray"
            onClick={() => setItemFormVisible(false)}
          >
            Cancel
          </Button>
          <Button colorScheme="orange" onClick={handleSave}>
            Save
          </Button>

        </Flex>
      </Box>
    </Box>
  );
};

const Items = ({ project, handleNextClick }) => {
  const [addNewItem, setAddNewItem] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    if (project?.rewards?.reward_items.length > 0) {
      setItemList(project.rewards.reward_items);
    }
  }, [project]);

  const handleEdit = (_id) => {
    const itemToEdit = itemList.find((item) => item._id === _id);
    setEditItem(itemToEdit);
    setAddNewItem(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (index) => {
  
    // get the item with the given index
    const itemToDelete = itemList[index];
    console.log('Item to delete:', itemToDelete); 
    if (itemToDelete.image) {
      // delete the image from the server
      axiosInstance.delete(`uploads/${itemToDelete.image.split('/').pop()}`)
        .then(response => {
          console.log('Image deleted successfully');
        })
        .catch(error => console.error('Error deleting image:', error));
    }

    // remove the item from the list
    setItemList((prevList) =>
      prevList.filter((item, i) => i !== index)
    );

    const items = itemList.filter((item, i) => i !== index);

    if (items.length === 0) {
      updateProjectField("rewards", { reward_items: items });
    }

  };

  const handleNewItem = () => {
    setEditItem(null);
    setAddNewItem(true);
    window.scrollTo(0, 0);
  };

  const handleItemAdded = (newItem) => {
    if (editItem) {
      setItemList((prevList) =>
        prevList.map((item) => (item._id === editItem._id ? newItem : item))
      );
    } else {
      setItemList((prevList) => [...prevList, newItem]);
    }
    setAddNewItem(false);
  };

  const { id } = useParams();
  const { updateProjectField } = useUpdateProject(id);

  useEffect(() => {
    if (itemList.length > 0) {
      updateProjectField("rewards", { reward_items: itemList });
    }
  }, [itemList, updateProjectField]);

  const saveContinue = () => {
    updateProjectField("rewards", { reward_items: itemList });
    handleNextClick();
  };

  return (
    <>
      {!addNewItem && (
        <Box mt="30px" textAlign="start">
          <Flex justifyContent="space-between">
            <Text>
              Including items in your rewards and add-ons makes it easy for <br />
              backers to understand and compare your offerings. An item can <br />
              be anything you plan to offer your backers. Some examples include<br />
              playing cards, a digital copy of a book, a ticket to a play, or <br />
              even a thank-you in your documentary.
            </Text>
            <Button
              onClick={handleNewItem}
              width="150px"
              colorScheme="orange"
              fontSize="14px"
              fontWeight={500}
              size="md"
            >
              <FaPlus size="14px" style={{ marginRight: "10px" }} />
              New Item
            </Button>
          </Flex>
          <Text mt="20px" fontSize="14px" color="orange" textDecoration="underline">
            Learn about creating items
          </Text>
        </Box>
      )}

      {addNewItem && (
        <ItemForm
          onItemAdded={handleItemAdded}
          setItemFormVisible={setAddNewItem}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      )}

      {(itemList.length > 0 && !addNewItem) && (
        <Box mt={4}>
          {itemList.map((item, index) => (
            <Item
              key={index}
              item={item}
              onEdit={handleEdit}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Box>
      )}
      <Flex justifyContent="end">
        <Button
          mt="20px"
          w="160px"
          colorScheme="orange"
          fontSize="14px"
          fontWeight={500}
          onClick={saveContinue}
        >
          Save & Continue
        </Button>
      </Flex>
    </>
  );
};

export default Items;
