import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { IoBookSharp } from "react-icons/io5";
import CustomNotepad from "./CustomNotepad";
import { CiLocationOn } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useParams } from "react-router-dom";
import useUpdateProject from "../../../../../libs/useUpdateProjectService";
import { debounce } from "lodash";

const Story = ({ project, handleNextClick }) => {
  const [contentValue, setContentValue] = useState("0");
  const [addAnotherFaq, setAddAnotherFaq] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [aiContent, setAiContent] = useState("no");

  const { id } = useParams();
  const { updateProjectField } = useUpdateProject(id);

  const debouncedUpdateProjectField = useCallback(
    debounce((field, value) => {
      updateProjectField(field, value);

    }, 300),
    []
  );

  useEffect(() => {

    if (project?.story?.use_of_ai) {
      setAiContent(project.story.use_of_ai);
    }
    if (project?.story?.faqs.length > 0) {
      setFaqs(project.story.faqs);
    }

  }, [project]);

  const handleChange = (e, key, customValue) => {
    if (customValue) {
      debouncedUpdateProjectField(key, customValue);
      return;
    }
    if (e.target.value) {
      const { value } = e.target;
      debouncedUpdateProjectField(key, value);
    }
  };

  const handleAiContentChange = (field, value) => {
    debouncedUpdateProjectField("story.use_of_ai", value);
  };

  const handleFaqChange = (index, field, value) => {
    const updatedFaqs = faqs.map((faq, i) =>
      i === index ? { ...faq, [field]: value } : faq
    );
    setFaqs(updatedFaqs);
    debouncedUpdateProjectField("story.faqs", updatedFaqs);
  };

  const addFaq = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
    setAddAnotherFaq(true);
  };

  const removeFaq = (index) => {
    const updatedFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(updatedFaqs);
    debouncedUpdateProjectField("story.faqs", updatedFaqs);
  };

  return (
    <Box textAlign={"start"}>
      <Box py={"50px"}>
        <Text textAlign={"center"} fontSize={"30px"}>
          Introduce your project
        </Text>
        <Text
          mt={"15px"}
          textAlign={"center"}
          fontSize={"20px"}
          color={"#656969"}
        >
          Tell people why they should be excited about your project. Get
          specific but be clear and be <br /> brief.
        </Text>
      </Box>

      <Divider my={"30px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />

      <Box>
        <Text fontSize={"20px"}>Project story</Text>
        <Text mt={"15px"} color={"#656969"} fontSize={""}>
          Describe what you're raising funds to do, why you care about it, how
          you plan to <br /> make it happen, and who you are. Read more about
          telling your story.
        </Text>

        <Flex
          mt={"20px"}
          py={"15px"}
          px={"20px"}
          border={"1px solid rgba(0,0,0,0.1)"}
          flexDirection={{ base: "column", lg: "row" }}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Flex alignItems={"center"} gap={"20px"}>
            <IoBookSharp size={"22px"} />
            <Text mt={""} color={"#656969"} fontSize={""}>
              Welcome to our improved story editor
            </Text>
          </Flex>
          <Text py={"10px"} px={"20px"} border={"1px solid rgba(0,0,0,0.1)"}>
            Learn about its features
          </Text>
        </Flex>



        <Flex
          w={"100%"}
          minH={"500px"}
          alignItems={"start"}
          justifyContent={"center"}
          
        >
        
        <Box mt={"20px"} width={"100%"}>
            <CustomNotepad  handleChange={handleChange} project={project} />
        </Box>
        </Flex>
      </Box>

      <Divider my={"60px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />

      <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Risks and challenges</Text>
            <Text mt={"15px"} color={"#656969"} fontSize={""}>
              Be honest about the potential risks and challenges <br /> of this
              project and how you plan to overcome them <br /> to complete it.
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          <Textarea
            mt={""}
            placeholder="Get an early copy"
            resize={"none"}
            defaultValue={project?.story?.risks_challenges || ""}
            onChange={(e) => handleChange(e, "story.risks_challenges")}
          />
          <Flex mt={"20px"} alignItems={"center"} gap={"10px"}>
            <CiLocationOn size={"20px"} color="orange" fontWeight={"bold"} />
            <Text fontSize={"12px"} fontWeight={"bold"} color={"orange"}>
              Give backers the best first impression of your project with great
              titles. Learn more...
            </Text>
          </Flex>
        </GridItem>
      </Grid>

      <Divider my={"60px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />

      <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Use of AI</Text>
            <Text w={"400px"} mt={"15px"} color={"#656969"} fontSize={""}>
              GorillaFunder supports creative work and the humans behind that
              work. Projects that involve AI tools and technologies are allowed
              in some situations. If your project involves AI in any capacity,
              tell us a bit more so we can determine if it meets our policy
              requirements.
            </Text>
            <Text w={"400px"} mt={"15px"} color={"#656969"} fontSize={""}>
              Learn about AI policy on GorillaFunder
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          <Text fontSize={"18px"}>
            Will your project involve the development of AI technology or use AI
            content?
          </Text>

          <RadioGroup
            mt={"15px"}
            onChange={(value) => handleAiContentChange("use_of_ai", value)}
            value={aiContent}
          >
            <Stack>
              <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                <Radio value="yes">
                  <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                    Yes
                  </Text>
                </Radio>


              </Box>
              <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                <Radio value="no">
                  <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                    No
                  </Text>
                </Radio>
              </Box>
            </Stack>
          </RadioGroup>
        </GridItem>
      </Grid>

      <Divider my={"60px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />

      <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Frequently Asked Questions</Text>
            <Text w={""} mt={"15px"} color={"#656969"} fontSize={""}>
              Post answers to frequently asked questions
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          {faqs.map((faq, index) => (
            <Box key={index} mb={"20px"} bg={"#F7F7F6"} py={"20px"} px={"40px"}>
              <Text>Question</Text>
              <Textarea
                bg={"white"}
                mt={"10px"}
                placeholder=""
                rows={"auto"}
                resize={"none"}
                value={faq.question}
                onChange={(e) =>
                  handleFaqChange(index, "question", e.target.value)
                }
              />
              <Text mt={"20px"}>Answer</Text>
              <Textarea
                bg={"white"}
                mt={"10px"}
                placeholder=""
                rows={"5"}
                resize={"none"}
                value={faq.answer}
                onChange={(e) => handleFaqChange(index, "answer", e.target.value)}
              />

              <Flex justifyContent={"end"}>
                <Flex
                  cursor={"pointer"}
                  alignItems={"center"}
                  bg={"white"}
                  p={"5px"}
                  mt={"15px"}
                  border={"1px solid rgba(0,0,0,0.2)"}
                  onClick={() => removeFaq(index)}
                >
                  <MdDelete />
                  <Text>Delete</Text>
                </Flex>
              </Flex>
            </Box>
          ))}

          <Text
            cursor={"pointer"}
            onClick={addFaq}
            textAlign={"center"}
            p={"10px"}
            border={"1px solid rgba(0,0,0,0.1)"}
            fontSize={"18px"}
          >
            Add another FAQ
          </Text>
          <Flex justifyContent={"end"}>
            <Button
              mt={"30px"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}
              onClick={() => {
                debouncedUpdateProjectField("story.faqs", faqs)
                handleNextClick();
                window.scrollTo(0, 0);
              }}
            >
              Save & Continue
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Story;
