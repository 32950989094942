import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaRegBookmark } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import { LuClock3 } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";
import "./filmsPage.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../libs/axiosService";
import LoadingBox from "../../Components/shared/Loading";
import { FaPlay } from "react-icons/fa";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { IoSearch } from "react-icons/io5";

const FilmsGrid = ({ category }) => {
  const gridColumns = useBreakpointValue({ base: "1fr", md: "repeat(3, 1fr)" });

  const [loading, setLoading] = useState(true);

  const [projects, setProjects] = useState([]);
  console.log("projects", projects);
  const [originalProjects, setOriginalProjects] = useState([]);
  const toast = useToast();
  const [techInnovation, setTechInnovation] = useState(false);
  const [creativeWorks, setCreativeWorks] = useState(false);
  const [communityprojects, setCommunityProjects] = useState(false);
  const [search, setSearch] = useState("");
  // get query param searchText
	const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchParam = params.get("search");

  useEffect(() => {

    if (searchParam && originalProjects.length > 0) {
      setSearch(searchParam);
      // filter projects based on searchParam from originalProjects state
      setProjects((prev) => {
        return originalProjects.filter((project) => {
          return project.basic.title.toLowerCase().includes(searchParam.toLowerCase());
        });
      });
    }
    else {
      setProjects(originalProjects);
    }

  }, [searchParam, originalProjects]);




  
  const navigate = useNavigate();
  const navigateToDetails = (id) => {
  
      navigate(`/film/${id}`);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = category
          ? await axiosInstance.get(`/project-lists?category=${category}`)
          : await axiosInstance.get("/project-lists");
        setProjects(response.data.projects);
        setOriginalProjects(response.data.projects);
        setLoading(false);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch projects.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        setLoading(false);
      }
    };

    fetchProjects();
  }, [toast, category]);
  

  if (loading) {
    return (
      <>
        <LoadingBox />
      </>
    );
  }


  return (
    <>
      <Box
        backgroundImage={"url(/images/movies/exploreBanner.jpg)"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        h={"250px"}
        w={"full"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Text fontSize={{base:"40px",lg:"96px"}} fontWeight={"500"} color={"white"}>
          Explore it all
        </Text>
      </Box>
      <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
        <Grid templateColumns={{ base: "1fr", lg: "2fr 10fr" }} gap={10}>
          <GridItem bg={""} colSpan={{ base: "1fr" }}>
            <Box textAlign={"start"} p={2} px={"20px"}>
              <Text fontSize={"18px"} fontWeight={""} color={"#1f0733"} mb={"20px"}>
                Filter results
              </Text>
              <Text color={"#1f0733"} fontSize={"14px"} fontWeight={"600"}>
                CATEGORY
              </Text>

              <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                All Categories
              </Text>
              <Flex
                onClick={() => setTechInnovation(!techInnovation)}
                alignItems={"center"}
                mt={"10px"}
                gap={"5px"}
              >
                <Text
                  color={techInnovation ? "orange" : ""}
                  fontSize={"14px"}
                  fontWeight={""}
                >
                  Tech & Innovation
                </Text>
                {techInnovation ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </Flex>

              {techInnovation && (
                <Box pl={"20px"}>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Audio
                  </Text>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Camera Gear
                  </Text>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Education
                  </Text>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Energy & Green Tech
                  </Text>
                </Box>
              )}

              <Flex
                onClick={() => setCreativeWorks(!creativeWorks)}
                alignItems={"center"}
                mt={"10px"}
                gap={"5px"}
              >
                <Text
                  color={creativeWorks ? "orange" : ""}
                  fontSize={"14px"}
                  fontWeight={""}
                >
                  Creative Works
                </Text>
                {creativeWorks ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </Flex>

              {creativeWorks && (
                <Box pl={"20px"}>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Audio
                  </Text>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Camera Gear
                  </Text>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Education
                  </Text>
                  <Text fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Energy & Green Tech
                  </Text>
                </Box>
              )}

              <Flex
                onClick={() => setCommunityProjects(!communityprojects)}
                alignItems={"center"}
                mt={"10px"}
                gap={"5px"}
              >
                <Text
                  color={communityprojects ? "orange" : ""}
                  fontSize={"14px"}
                  fontWeight={""}
                >
                  Community Projects
                </Text>
                {communityprojects ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </Flex>

              {communityprojects && (
                <Box pl={"20px"}>
                  <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Audio
                  </Text>
                  <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Camera Gear
                  </Text>
                  <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Education
                  </Text>
                  <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""} mt={"10px"}>
                    Energy & Green Tech
                  </Text>
                </Box>
              )}
              <Box h={"2px"} bg={"#DDDDDD"} my={"20px"} />

              <Text color={"#1f0733"} fontSize={"14px"} fontWeight={"600"} mb={""}>
                CAMPAIGN TIMING
              </Text>

              <RadioGroup
              //  onChange={setValue} value={value}
              >
                <Radio value="1" mt={"10px"}>
                  <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                    All
                  </Text>
                </Radio>
                <Box mt={"10px"}>
                  <Radio value="2">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Launching soon
                    </Text>
                  </Radio>
                </Box>
                <Box mt={"10px"}>
                  <Radio value="3">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Ending soon
                    </Text>
                  </Radio>
                </Box>
                <Box mt={"10px"}>
                  <Radio value="4">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Just launched
                    </Text>
                  </Radio>
                </Box>
              </RadioGroup>

              <Box h={"2px"} bg={"#DDDDDD"} my={"20px"} />

              <Text color={"#1f0733"} fontSize={"14px"} fontWeight={"600"} mb={""}>
                PRODUCT STAGE
              </Text>

              <RadioGroup
              //  onChange={setValue} value={value}
              >
                <Radio value="1" mt={"10px"}>
                  <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                    All
                  </Text>
                </Radio>
                <Box mt={"10px"}>
                  <Radio value="2">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Concept
                    </Text>
                  </Radio>
                </Box>
                <Box mt={"10px"}>
                  <Radio value="3">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Prototype
                    </Text>
                  </Radio>
                </Box>
                <Box mt={"10px"}>
                  <Radio value="4">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Production
                    </Text>
                  </Radio>
                </Box>
                <Box mt={"10px"}>
                  <Radio value="5">
                    <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                      Shipping
                    </Text>
                  </Radio>
                </Box>
              </RadioGroup>

              <Box h={"2px"} bg={"#DDDDDD"} my={"20px"} />

              <Flex
                mt={"20px"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text color={"#1f0733"} fontSize={"14px"} fontWeight={""}>
                  Include closed campaigns
                </Text>
                <Switch colorScheme="orange" size="md" />
              </Flex>
            </Box>
          </GridItem>

          <GridItem bg={""} colSpan={{ base: "1fr" }}>
            <Box bg={""} w={{ base: "100%", lg: "100%" }}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <IoSearch />
                </InputLeftElement>
                <Input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigate(`/view-campaigns?search=${e.target.value}`)
                     }
                  }}
                  placeholder="Search for campaigns - press enter"
                  outline={"none"}
                  border={"1px solid black"}
                />
              </InputGroup>
            </Box>

            <Box h={"2px"} bg={"#DDDDDD"} my={"40px"}></Box>

            <Flex my={"10px"} justifyContent={"end"}>
              <Flex alignItems={"center"} gap={"10px"}>
                <Text fontSize={"18px"} color={"#1f0733"}>Sort by</Text>
                <Select placeholder="Trending" w="auto" fontSize={"16px"}>
                  <option style={{color:"black"}} value="Most Funded">Most Funded</option>
                  <option style={{color:"black"}} value="Perk Price(Lowest)">Perk Price(Lowest)</option>
                  <option style={{color:"black"}} value="Perk Price(Highest)">Perk Price(Highest)</option>
                </Select>
              </Flex>
            </Flex>

            {projects.length > 0 ? (
              <Grid
                templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
                mt={"20px"}
                gap={"40px"}
              >
                {projects?.map((ele, index) => (
                  <GridItem colSpan={{ base: "1fr" }}>
              
                      <Box
                        textAlign={"start"}
                        w={{ base: "100%", lg: "336px" }}
                        h={"560px"}
                        onClick={() => {
                          navigateToDetails(ele._id);
                        }}
                        bg={""}
                      >
                        <Link to={`/film/${ele._id}`}>
                        <Box
                          h={"312px"}
                          borderRadius={"20px"}
                          position={"relative"}
                          className="relative-box"
                        >
                          <Box
                            w={"100%"}
                            h={"100%"}
                            position={"absolute"}
                            top={0}
                            left={0}
                            className="absolute-box"
                            borderRadius={"20px"}
                          >
                          
                            <Box
                              w={"full"}
                              px={"10px"}
                              className="view-campaign-btn"
                            >
                              <Button
                                w={"full"}
                                size={"lg"}
                                colorScheme="orange"
                              >
                                View Campaign
                              </Button>
                            </Box>
                          </Box>
                          <Image
                            h={"312px"}
                            objectFit={"cover"}
                            src={
                              process.env.REACT_APP_API_BASE_URL +
                              ele.basic?.project_image
                            }
                            borderRadius={"20px"}
                            />
                        </Box>

                        <Box py={"10px"}>
                          <Text
                            lineHeight={"24px"}
                            fontSize={"18px"}
                            fontWeight={"400"}
                            color={"#1f0733"}
                            >
                            {" "}
                            {ele.basic.title}
                          </Text>
                          <Text
                            fontSize={"14px"}
                            fontWeight={"400"}
                            color={"#1f0733"}
                            mt={"15px"}
                            >
                            STARTING AT
                          </Text>
                          <Text
                            fontSize={"24px"}
                            fontWeight={"500"}
                            color={"#1f0733"}
                            mt={""}
                            >
                            {ele.basic.funding_goal_type}
                            {ele.basic?.funding_goal_amount?.toLocaleString()}
                          </Text>
                          <Progress
                            mt={"15px"}
                            colorScheme="orange"
                            value={ele?.funded_percentage?.toFixed(2)}
                            max={100}
                            className="films-grid-progress"
                            />
                          <Flex mt={"5px"} alignItems={"center"} gap={"15px"}>
                            <Text
                              fontSize={"14px"}
                              fontWeight={""}
                              color={"#1f0733"}
                              >
                               ${ele.fund_raised?.toLocaleString()} raised
                            </Text>
                            <Text
                              fontSize={"14px"}
                              fontWeight={""}
                              color={"#1f0733"}
                              mt={""}
                              >
                              {ele.funded_percentage?.toFixed(2)}% funded
                            </Text>
                          </Flex>
                          <Text
                            fontSize={"14px"}
                            fontWeight={""}
                            color={""}
                            mt={"10px"}
                            >
                               {ele.basic.subcategory}
                          </Text>
                        </Box>
                </Link>
                      </Box>
                
                  </GridItem>
                ))}
              </Grid>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="200px"
                width="100%"
                bg="white.100"
                borderRadius="md"
                m={5}
                boxShadow="md"
              >
                Couldn't find any campaign.
              </Box>
            )}
          </GridItem>
        </Grid>
      </Container>
    </>

    //     <Grid templateColumns={gridColumns} gap={6} m="auto" p={["5vh 5vw", "5vh 10vw"]}>
    //     {randomData?.map((ele) => (
    //         <Link to={`/film/${ele.id}`}>
    //             <Box key={ele.id} textAlign="left">
    //                 <Image src={ele.photo.ed} alt={ele.name} className="films-grid-img" />
    //                 <Progress colorScheme="orange" value={ele.progress} max={100} className="films-grid-progress" />
    //                 <Box p="1vh 0.1vw">
    //                     <Box display="flex" sx={{ position: "relative" }} alignItems="center">
    //                         <Image src={ele.creator.photo} alt={ele.creator.name} className="films-grid-creator-img" />
    //                         <Heading as="h3" size="sm" className="films-grid-heading" ml={2}>
    //                             {ele.name}
    //                             <Text className="films-grid-creator">{ele.creator.name}</Text>
    //                         </Heading>
    //                         <Tooltip placement="top" label="Save this film" hasArrow>
    //                             <Box>
    //                                 <FaRegBookmark title="Save this film" className="films-grid-bookmark" />
    //                             </Box>
    //                         </Tooltip>
    //                     </Box>
    //                     <Box display="flex" alignItems="center" style={{ marginLeft: "45px" }} mt={2}>
    //                         <LuClock3 size={18} />
    //                         <Text fontSize="sm" ml={1}>
    //                             3 days left
    //                         </Text>
    //                         <GoDotFill size={10} style={{ marginLeft: "10px" }} />
    //                         <Text fontSize="sm" ml={2}>
    //                             {ele.progress}% funded
    //                         </Text>
    //                     </Box>

    //                 </Box>
    //             </Box>
    //         </Link>
    //     ))}
    // </Grid>
  );
};

export default FilmsGrid;
