import { Box, Button, Flex, Image, Text, IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import NewReward from "./NewReward/NewReward";
import { useParams } from "react-router-dom";
import useUpdateProject from "../../../../../../libs/useUpdateProjectService";
import axiosInstance from "../../../../../../libs/axiosService";

const RewardItem = ({ reward, onEdit, onDelete }) => {
  return (
    <Flex
      p={4}
      mb={4}
      borderWidth={1}
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Image
          src={process.env.REACT_APP_API_BASE_URL + reward.image}
          alt={reward.title}
          boxSize="100px"
          objectFit="cover"
          mr={4}
        />
        <Box sx={{textAlign: "left", marginLeft: "20px" }}>
          <Text fontWeight="bold" fontSize="lg">
            {reward.title}
          </Text>
          <Text fontSize="md">${reward.pricing}</Text>
          <Text>{
              // Truncate the description to 100 characters and add "..." at the end
              reward.description.length > 100
                ? reward.description.substring(0, 100) + "..."
                : reward.description
            }</Text>
        </Box>
      </Flex>
      <Flex>
        <IconButton
          icon={<FaEdit />}
          mr={2}
          onClick={() => onEdit(reward.id)}
        />
        <IconButton
          icon={<FaTrash />}
          onClick={() => onDelete(reward.id)}
        />
      </Flex>
    </Flex>
  );
};

const RewardsTier = ({ project, handleNextClick }) => {
  const [newReward, setNewReward] = useState(false);
  const [rewardList, setRewardList] = useState([]);
  const [editReward, setEditReward] = useState(null);

  useEffect(() => {

    if (project?.rewards?.reward_tiers.length > 0) {
      setRewardList(project.rewards.reward_tiers);
    }

  }, [project]);

  const handleEdit = (reward) => {
    console.log("edited reward ", reward);
    setEditReward(reward);
    setNewReward(true); // Show NewReward component for editing
    window.scrollTo(0, 0);
  };

  const handleDelete = (index) => {
    // Remove the reward from the list by filtering out the reward with the given index
    setRewardList((prevList) =>
      prevList.filter((reward, i) => i !== index)
    );


    
    // get the item with the given index
    const itemToDelete = rewardList[index];
    if (itemToDelete.image) {
      // delete the image from the server
      axiosInstance.delete(`uploads/${itemToDelete.image.split('/').pop()}`)
        .then(response => {
          console.log('Image deleted successfully');
        })
        .catch(error => console.error('Error deleting image:', error));
    }

    // remove the item from the list
    setRewardList((prevList) =>
      prevList.filter((reward, i) => i !== index)
    );

    const items = rewardList.filter((item, i) => i !== index);

    if (items.length === 0) {
      updateProjectField("rewards", { reward_tiers: items });
    }

  };

  const handleNewReward = () => {
    
    setEditReward(null);
    setNewReward(true);
    window.scrollTo(0, 0);
  };

  const handleRewardAdded = (newReward) => {
    setRewardList((prevList) => [...prevList, newReward]);
    setNewReward(false); // Hide NewReward component after adding a new reward
  };

  const { id } = useParams();
  const { updateProjectField } = useUpdateProject(id);

 useEffect(() => {

  if (rewardList.length > 0) {
    updateProjectField("rewards", { reward_tiers: rewardList });
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [rewardList]);

  const saveContinue = () => {
    updateProjectField("rewards", { reward_tiers: rewardList });
    handleNextClick();
  };

  return (
    <>
      {!newReward && (
        <Box mt={"30px"} textAlign={"start"}>
          <Flex justifyContent={"space-between"}>
            <Text>
              Most creators offer 3-10 reward tiers, which can be physical items
              or special <br />
              experiences. Make sure to set reasonable backer expectations.
            </Text>
            <Button
              onClick={handleNewReward}
              width={"150px"}
              colorScheme="orange"
              fontWeight={500}
              size={"md"}
              fontSize={"14px"}
            >
              <FaPlus size={"15px"} style={{marginRight: "10px"}} />
              New Reward
            </Button>
          </Flex>

          <Text
            mt={"20px"}
            fontSize={""}
            fontWeight={""}
            color={"orange"}
            textDecoration={"underline"}
          >
            Learn about creating and managing rewards
          </Text>
        </Box>
      )}

      {newReward && (
        <NewReward
          project={project}
          onRewardAdded={handleRewardAdded}
          setNewReward={setNewReward}
          editReward={editReward}
          setEditReward={setEditReward}
          setRewardList={setRewardList}
          rewardList={rewardList}
        />
      )}

      {(rewardList.length > 0 && !newReward) && (
        <Box mt={4}>
          {rewardList.map((reward, index) => (
            <RewardItem
              key={index}
              reward={reward}
              onEdit={() => handleEdit(reward)}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Box>
      )}
      <Flex justifyContent={"end"}>

        <Button
          mt={"20px"}
          w={"160px"}
          colorScheme="orange"
          fontSize={"14px"}
          fontWeight={500}
          onClick={() => {
            saveContinue();
          }}
        >
          Save & Continue
        </Button>
      </Flex>

    </>
  );
};

export default RewardsTier;
