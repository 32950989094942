import {  VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import Footer from "../../../Components/Footer";
import ProjectDetailsComponent from "../../../Components/AdminComponents/ProjectDetailsComponent";

const AdminProjectDetails = () => {
	

	return (
		<>
		<VStack spacing={"auto"}>
			<Navbar logoLevel="2" />
				<ProjectDetailsComponent />
		</VStack>
			<Footer  />
		</>
	);
};

export default AdminProjectDetails;
