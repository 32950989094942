import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  useBreakpointValue,
  Spinner,
  SimpleGrid,
  Grid,
  GridItem,
  Image,
  Text,
  Avatar,
} from "@chakra-ui/react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import axiosInstance from "../libs/axiosService";
import useGetUser from "../libs/useGetUser";
import { AppContext } from "../Context/AuthContextProvider";
import { AuthModalComponent } from "./AuthModal";

const SubNavbar = ({ exploreHover, subNav }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const fontSize = useBreakpointValue({ base: "xs", md: "sm" });
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const padding = useBreakpointValue({ base: "2vh 5vw", md: "2vh 12vw" });
  const location = useLocation();
  const { user } = useGetUser();
  const navigate = useNavigate()
  const context = useContext(AppContext);
  const { logout } = context;


  const logoutClick = () => {
    logout();
    // navigate("/login");
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/movies-categories");
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleUrl = (url) => {
    console.log(url);
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="100vh">
        <Spinner color="orange.500" size="xl" />
      </Flex>
    );
  }

  const firstSevenCategories = categories.slice(0, 8);
  const SecondSevenCategories = categories.slice(8, 16);
  const thirdSevenCategories = categories.slice(16, 24);
  const fourthSevenCategories = categories.slice(24);

  return (
    <>
      {/* <Flex
      w="100%"
      p={padding}
      justifyContent="space-evenly"
      borderWidth="1px"
      fontSize={fontSize}
      // flexDirection={flexDirection}
      flexWrap="wrap"
    >
      {firstSevenCategories.map((category) => (
        <Link
          key={category.id}
          to={{
            pathname: "/films",
            search: `?category=${category.category}`,
          }}
          onClick={() => handleUrl(category.category)}
        >
          {category.category}
        </Link>
      ))}
      <Menu isLazy>
        <MenuButton>
          More
        </MenuButton>
        <MenuList minWidth='90vw'>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2} p={2}>
            {remainingCategories.map((category) => (
              <MenuItem
                key={category.id}
                as={Link}
                to={{
                  pathname: "/films",
                  search: `?category=${category.category}`,
                }}
                onClick={() => handleUrl(category.category)}
              >
                {category.category}
              </MenuItem>
            ))}
          </SimpleGrid>
        </MenuList>
      </Menu>
    </Flex> */}

      <Box
      color={subNav?"black":"black"}
      bg={subNav?"white":"white"}
        className="mysubnavbar"
        overflowY={"auto"}
        maxH={{
          base: subNav ? "430px" : "0px",
          lg: exploreHover ? "430px" : "0px",
        }}
        overflow={""}
        px={{base:"",lg:"40px"}}
        w={"full"}
      >
        <Flex px={"30px"} py={"20px"} alignItems={"center"} justifyContent={"space-between"}  display={{base:"flex",lg:"none"}}>
        <NavLink to="/create-project" className="active-link">
          <Text
            display={{ base: "block", lg: "none" }}
            fontSize={{base:"13px",lg:"15px"}}
            fontWeight={"500"}
            textTransform={"uppercase"}
            _hover={{ bg: "orange" }}
            border={"2px solid orange"}
            borderRadius={"8px"}
            bg={location.pathname === "/" ? "transparent" : "orange"}
            py={{base:"10px",lg:"10px"}}
            px={{base:"10px",lg:"10px"}}
          >
            {" "}
            Start a Campaign
          </Text>
          </NavLink>
          {user ? (
            <Menu>
              <MenuButton>
                <Avatar
                  size="sm"
                  name={user?.name}
                  src={process.env.REACT_APP_API_BASE_URL + user?.avatar}
                  borderRadius="50%"
                />
              </MenuButton>
              <MenuList zIndex={"9999"}>
                <MenuItem
                  onClick={() => navigate(`/view-profile`)}
                >
                  My Profile
                </MenuItem>

                {user.role === "admin" && (
                  <>
                    <MenuItem onClick={() => navigate(`/all-projects`)}>
                      All Projects
                    </MenuItem>

                    {/* <MenuItem onClick={() => navigate(`/my-backlist`)}>
                      My Backlist
                    </MenuItem> */}

                    <MenuItem onClick={() => navigate(`/withdraw-requests`)}>
                      Withdraw Request
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/all-creators`)}>
                      All Creators
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/settings`)}>
                      Settings
                    </MenuItem>
                  </>
                )}

                {user.role === "user" && (
                  <>
                    {/* <MenuItem onClick={() => navigate(`/my-projects`)}>
                      My Projects
                    </MenuItem>

                    <MenuItem onClick={() => navigate(`/my-backlist`)}>
                      My Backlist
                    </MenuItem> */}


                    <MenuItem
                      onClick={() =>
                        navigate(`/settings`)
                      }
                    >
                      Settings
                    </MenuItem>


                    {/* <MenuItem
                      onClick={() =>
                        navigate(`/settings?tab=Payment%20Methods`)
                      }
                    >
                      Payment Methods
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        navigate(`/settings?tab=Shipping%20Address`)
                      }
                    >
                      Shipping Address
                    </MenuItem> */}
                  </>
                )}

                <MenuItem onClick={logoutClick}>Logout</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            

            <AuthModalComponent />
            
          )}

        </Flex>
        <Box h={"2px"} bg={"#EDF2F7"}  display={{base:"block",lg:"none"}}></Box>

        <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr 1fr" }}>
          <GridItem bg={""} colSpan={{ base: "1fr" }} p={"20px"}>
         
            {firstSevenCategories.map((category) => (
              <Link
                key={category.id}
                to={{
                  pathname: "/films",
                  search: `?category=${category.category}`,
                }}
                onClick={() => handleUrl(category.category)}
              >
                <Text
                  fontSize={"18px"}
                  fontWeight={"500"}
                  textAlign={"start"}
                  mt={"5px"}
                  color={"#1f0733"}
                   className="subCategory"
                >
                  {" "}
                  {category.category}
                </Text>
              </Link>
            ))}
          </GridItem>
          <Box h={"2px"} bg={"#EDF2F7"} display={{base:"block",lg:"none"}}></Box>
          <GridItem bg={""} colSpan={{ base: "1fr" }} p={"20px"}>

            {SecondSevenCategories.map((category) => (
              <Link
                key={category.id}
                to={{
                  pathname: "/films",
                  search: `?category=${category.category}`,
                }}
                onClick={() => handleUrl(category.category)}
              >
                <Text
                  fontSize={"18px"}
                  fontWeight={"500"}
                  textAlign={"start"}
                  mt={"5px"}
                  color={"#1f0733"}
                   className="subCategory"
                >
                  {" "}
                  {category.category}
                </Text>
              </Link>
            ))}
          </GridItem>
          <Box h={"2px"} bg={"#EDF2F7"} display={{base:"block",lg:"none"}}></Box>
          <GridItem colSpan={{ base: "1fr" }} p={"20px"}>
           
            {thirdSevenCategories.map((category) => (
              <Link
                key={category.id}
                to={{
                  pathname: "/films",
                  search: `?category=${category.category}`,
                }}
                onClick={() => handleUrl(category.category)}
              >
                <Text
                  fontSize={"18px"}
                  fontWeight={"500"}
                  textAlign={"start"}
                  mt={"5px"}
                  color={"#1f0733"}
                   className="subCategory"
                  
                >
                  {" "}
                  {category.category}
                </Text>
              </Link>
            ))}
          </GridItem>
          <Box h={"2px"} bg={"#EDF2F7"} display={{base:"block",lg:"none"}}></Box>
          <GridItem colSpan={{ base: "1fr" }} p={"20px"}>
           
            {fourthSevenCategories.map((category) => (
              <Link
                key={category.id}
                to={{
                  pathname: "/films",
                  search: `?category=${category.category}`,
                }}
                onClick={() => handleUrl(category.category)}
              >
                <Text
                  fontSize={"18px"}
                  fontWeight={"500"}
                  textAlign={"start"}
                  mt={"5px"}
                  color={"#1f0733"}
                  className="subCategory"
                >
                  {" "}
                  {category.category}
                </Text>
              </Link>
            ))}
          </GridItem>

          {/* <GridItem colSpan={{base:'1fr'}} p={"20px"}>
      <Text fontSize={"16px"} fontWeight={"500"} textAlign={"start"}>Featured Collections</Text>
      <Flex alignItems={"center"} gap={"20px"}>
      <Box w={"200px"} bg={"red"} mt={"10px"}>
     <Image src="/images/movies/movies (1).jpeg"/>
      </Box>
      <Box w={"200px"} bg={"red"} mt={"10px"}>
     <Image src="/images/movies/movies (2).jpg"/>
      </Box>
      </Flex>
    </GridItem> */}
        </Grid>
      
      </Box>
    </>
  );
};

export default SubNavbar;
