import React, { useContext, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Box,
  Flex,
  Image,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Signup from "./ModalSignup";
import Login2 from "./Login2";

export function AuthModalComponent({ remindMe, seeMore, faq, userDetails }) {
  const [showScreen, setShowScreen] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure();





  return (
    <>
      {/* <Button >Open Modal</Button> */}
      <Text
        onClick={onOpen}
        // mt={"20px"}
        // colorScheme="orange"
        fontSize={"14px"}
        fontWeight={500}
      // borderRadius={"5px"}
      >
        Login / Sign up
      </Text>

      <>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "", md: "1200px", lg: "1200px" }} maxH={"800px"}>
            <ModalCloseButton />

            <ModalBody overflow={"hidden"} >
              <Grid gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
                <GridItem display={{ base: "none", lg: "block" }} colSpan={{ base: "1fr" }}>
                  <Box transform={"rotate(5deg)"} mt={""}>

                    <Image
                      src="../../images/movies/authSideImage.jpg"
                      height={"800px"}
                      width={"100%"}
                      // objectPosition={"contain"}
                      objectFit={"cover"}
                    />
                  </Box>
                </GridItem>

                <GridItem colSpan={{ base: "1fr" }} px={"2vw"}>

                  <Flex
                    borderBottom={"1px solid rgba(0,0,0,0.2)"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gap={"12"}
                    bg={""}
                    h={"40px"}
                  >
                    <Text
                      borderBottom={` ${showScreen ? "1px solid black" : ""}`}
                      pb={"15px"}
                      fontSize="16"
                      fontWeight={""}
                      color={""}
                      textAlign={""}
                      onClick={() => setShowScreen(true)}
                      cursor={"pointer"}
                    >
                      Login
                    </Text>
                    {/* <Link to="/signup"> */}
                    <Text
                      pb={"15px"}
                      fontSize={"16"}
                      fontWeight={""}
                      borderBottom={` ${showScreen ? "" : "1px solid black"}`}
                      color={""}
                      textAlign={""}
                      onClick={() => setShowScreen(false)}
                      cursor={"pointer"}
                    >
                      Signup
                    </Text>
                    {/* </Link> */}
                  </Flex>
                  {showScreen && (


                    <>

                      <Box h={{
                        base: "460px",
                        md: "660px"
                      }} display={"flex"} alignItems={"center"} justifyContent={"center"} w={"100%"}>

                        <Login2 />
                      </Box>

                    </>
                  )}
                  {!showScreen && (


                    <Box h={{
                      base: "460px",
                      md: "660px"
                    }} display={"flex"} alignItems={"center"} justifyContent={"center"} w={"100%"}>

                      <Signup />
                    </Box>


                  )}

                </GridItem>


              </Grid>
            </ModalBody>
          </ModalContent>
        </Modal>

      </>
    </>
  );
}
