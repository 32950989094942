import React, { useEffect, useState } from "react";
import { Box, Text, Input, Button, FormControl, FormLabel, FormErrorMessage, useToast, Flex, Spinner } from "@chakra-ui/react";
import axiosInstance from "../../../libs/axiosService";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetUser from "../../../libs/useGetUser";

const AccountSettings = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const toast = useToast();
    const navigate = useNavigate();

    const { user } = useGetUser();
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        try {
            await axiosInstance.put("/change-password", { password: newPassword });
            toast({
                title: "Success",
                description: "Password changed successfully. Please login with your new password.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            navigate(`/login`);
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.error || "Something went wrong.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

    };

    useEffect(() => {

        if (token) {
            verifyToken();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    const verifyToken = async () => {
        try {
            await axiosInstance.post("/verify-email-token", { token });
            toast({
                title: "Success",
                description: "Email verified successfully.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data?.error || "Something went wrong.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }

    };

    return (
        <Box mt="30px" p="4" borderWidth="1px" bg={"#FBFBFA"} textAlign={"left"} borderRadius="lg">

            <Box width={""}>
                <FormControl w={{ base: "100%", lg: "700px" }} id="email" mb="4">
                    <FormLabel>
                        <Flex>
                            Email
                            {user?.is_auth ?
                                <Text color="green.500" ml="2" mt=".2" fontWeight={600} fontSize="sm">
                                    {`(Verified)`}
                                </Text>
                                :
                                <Text color="red.500" ml="2" mt=".2" fontWeight={600} fontSize="sm">
                                    {`(Unverified)`}
                                </Text>
                            }

                        </Flex>
                    </FormLabel>
                    <Input
                        sx={{
                            "&:read-only": {
                                bg: "gray.100",
                                cursor: "not-allowed",
                                color: "gray.500",
                            },
                        }}
                        type="email"
                        value={user?.email}
                        readOnly
                        placeholder="Enter your email"
                        borderRadius={"1px"}
                        bg={"white"}
                    />

                   {!user?.is_auth &&
                    <Button
                        colorScheme="orange"
                        size="sm"
                        fontWeight={500}
                        mt="2"
                        onClick={() => {
                            setLoading(true);
                            axiosInstance.post("/verify-email", {
                                email: user?.email,
                                clientUrl: window.location.origin,
                            }).then(() => {
                                setLoading(false);
                                toast({
                                    title: "Email Sent",
                                    description: "Verification email sent successfully.",
                                    status: "success",
                                    duration: 9000,
                                    isClosable: true,
                                });
                            });
                        }}
                        isDisabled={loading}
                    >

                        {loading && <Spinner color="white.500" size="xs" mr={2} />}
                        Send Verification Email
                    </Button>}

                </FormControl>

                <Box mt="8">
                    <Text fontSize="xl" mb="4">
                        Change Password
                    </Text>

                    <FormControl w={{ base: "100%", lg: "700px" }} id="new-password" mb="4" isInvalid={error}>
                        <FormLabel>New Password</FormLabel>
                        <Input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="Enter new password"
                            borderRadius={"1px"}
                            bg={"white"}
                        />
                    </FormControl>

                    <FormControl w={{ base: "100%", lg: "700px" }} id="confirm-password" mb="4" isInvalid={error}>
                        <FormLabel>Confirm Password</FormLabel>
                        <Input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm new password"
                            borderRadius={"1px"}
                            bg={"white"}
                        />
                        {error && <FormErrorMessage>{error}</FormErrorMessage>}
                    </FormControl>

                    <Button
                        colorScheme="orange"
                        fontWeight={500}
                        onClick={handlePasswordChange}
                        isDisabled={!newPassword || !confirmPassword}
                    >
                        Change Password
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default AccountSettings;
