import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import Footer from "../../../Components/Footer";
import AllCreatorsComponent from "../../../Components/AdminComponents/AllCreatorsComponent";

const AdminAllCreators = () => {

    const [creators, setCreators] = React.useState(0);

    return (
        <>
            <VStack spacing={"auto"}>
                <Navbar logoLevel="2" />
                <Box mb={5} mt={2} w={"90%"}>
                    <Heading as="h2" size="lg" p="3vh">
                        All Creators ({creators})
                    </Heading>
                    <Text className="sub_headings">
                        Here you can view all the creators.
                    </Text>
                    <AllCreatorsComponent setCreators={setCreators} />
                </Box>
            </VStack>
            <Footer />
        </>
    );
};

export default AdminAllCreators;
