import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Container, Flex, Text } from "@chakra-ui/react";
import AccountSettings from "./Account/Account";
import EditProfile from "./EditProfile/EditProfile";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import ShippingAddress from "./ShippingAddress/ShippingAddress";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";

const Settings = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const tabNames = ["Account", "Edit Profile", "Payment Methods", "Shipping Address"];

    useEffect(() => {
        const tab = searchParams.get("tab");
        const tabIndex = tabNames.indexOf(tab);
        if (tabIndex !== -1) {
            setActiveTabIndex(tabIndex);
        }
    }, [searchParams, tabNames]);

    const handleTabClick = (index) => {
        navigate(`/settings?tab=${tabNames[index]}`);
        setActiveTabIndex(index);
    };

    return (
        <>
            <Navbar />
            <Container px={{base:"0px",lg:"20px"}}  py={{base:"20px",lg:"40px"}} bg={""} maxW={{ base: "sm", md: "container.xl" }} >
            <Link to={"/view-profile"}>

            <Flex alignItems={"center"} gap={"5px"}>
            <IoEyeSharp />
                <Text>View Profile</Text>
            </Flex>
            </Link>
            <Box mt={"30px"}>
                <Tabs index={activeTabIndex} onChange={handleTabClick}>
                    <TabList display="flex"  flexDirection={["row", null, "row"]}>
                        {tabNames.map((tabName, index) => (
                            <Tab  key={index} fontSize={{base:"14px",lg:"16px"}}>
                                {tabName}
                            </Tab>
                        ))}
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <AccountSettings />
                        </TabPanel>
                        <TabPanel>
                            <EditProfile />
                        </TabPanel>
                        <TabPanel>
                            <PaymentMethods />
                        </TabPanel>
                        <TabPanel>
                            <ShippingAddress />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            </Container>
            <Footer />
        </>
    );
};

export default Settings;
