import { useEffect, useState } from "react";
import { Flex, Heading, Text, Image, Box, Button, Avatar, VStack, Grid, GridItem } from "@chakra-ui/react";
import axiosInstance from "../../libs/axiosService";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../shared/Loading";

const AdminAllProjectsComponent = () => {
  const [projects, setProjects] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/all-projects");
        setProjects(response.data.projects);
        setLoading(false);

        const statsResponse = await axiosInstance.get("/portal-stats");
        setStats(statsResponse.data);

      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleEdit = (projectId) => {
    // Implement edit functionality
    navigate(`/project-details/${projectId}`)
  };

  const handleDelete = (projectId) => {
    // Implement delete functionality
    console.log("Delete project:", projectId);
  };

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Box mb={5} mt={2}>
      <Heading as="h2" size="lg" p="3vh">
        Track All Projects of Creators.
      </Heading>
      <Text className="sub_headings">
        Here you can view all the projects created by creators. You can view the details of the project.
      </Text>
      <Flex flexWrap="wrap" justifyContent="center" sx={{ marginBottom: "20px" }}>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {stats?.total_projects || 0}
          </Heading>
          <Text>films funded</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${stats?.total_amount_raised?.toLocaleString() || 0}
          </Heading>
          <Text>towards creative work</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {stats?.total_pledges || 0}
          </Heading>
          <Text>pledges</Text>
        </Flex>
      </Flex>
      <VStack spacing={4}>
        {projects?.map((project) => (
          <Box
            key={project._id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            w={"90%"}
            p={5}
            m={2}
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Flex align="center">
                <Avatar src={process.env.REACT_APP_API_BASE_URL + project?.people?.user?.avatar} name={
                  project?.people?.user?.name
                } mr={3} />
                <Box>
                  <Heading as="h3" size="md">
                    {project?.basic?.title}
                  </Heading>
                  <Text>{project?.people?.user?.name}</Text>
                </Box>
              </Flex>
              <Box>
                <Button colorScheme="teal" size="sm" onClick={() => handleEdit(project._id)}>
                  View Details
                </Button>
              </Box>
            </Flex>
            <Grid templateColumns="repeat(7, 1fr)" gap={6} alignItems="center" textAlign={"left"}>
              <GridItem colSpan={[7, 1]}>
                <Image
                  borderRadius={8}
                  src={process.env.REACT_APP_API_BASE_URL + project?.basic?.project_image}
                  alt={project?.basic?.title}
                  boxSize="100px"
                  objectFit="cover"
                />
              </GridItem>
              <GridItem colSpan={[7, 2]}>
                <Text><strong>Title:</strong> {project?.basic?.title}</Text>
                <Text><strong>Subcategory:</strong> {project?.basic?.subcategory}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Funding Goal:</strong> ${project?.basic?.funding_goal_amount?.toLocaleString()}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Fund Raised:</strong> ${project?.fund_raised.toLocaleString() || "0"}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Location:</strong> {project?.basic?.location}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Status:</strong> {project?.published ? <span className="published">Published</span> :
                  <span className="unpublished">UnPublished</span>}</Text>
              </GridItem>
            </Grid>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default AdminAllProjectsComponent;
