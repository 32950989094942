import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Grid,
  GridItem,
  useToast,
  Text,
  Select,
  Flex,
} from "@chakra-ui/react";
import axiosInstance from "../../../libs/axiosService";
import useGetUser from "../../../libs/useGetUser";

const PaymentMethods = () => {
  const { user } = useGetUser();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [formData, setFormData] = useState({
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    payment_info: {
      account_holder_name: "",
      bank_name: "",
      account_number: "",
      iban_number: "",
      payment_method: "",
    },
  });

  useEffect(() => {
    if (user) {
      setFormData({
        address: user.address || "",
        city: user.city || "",
        state: user.state || "",
        zip: user.zip || "",
        country: user.country || "",
        payment_info: {
          account_holder_name: user.payment_info?.account_holder_name || "",
          bank_name: user.payment_info?.bank_name || "",
          account_number: user.payment_info?.account_number || "",
          iban_number: user.payment_info?.iban_number || "",
          payment_method: user.payment_info?.payment_method || "",
        },
      });
    }

    if (user?.payment_info?.payment_method) {
      setPaymentMethod(user.payment_info.payment_method);
    }
  }, [user]);

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the changed input is within payment_info, update that nested field
    if (name.startsWith("payment_info.")) {
      console.log(name.split("."));
      const paymentInfoField = name.split(".")[1];
      setFormData((prevState) => ({
        ...prevState,
        payment_info: {
          ...prevState.payment_info,
          [paymentInfoField]: value || "", // Handle undefined value by setting it to empty string
        },
      }));
    } else {
      // For other inputs outside payment_info, update directly in formData
      setFormData({ ...formData, [name]: value || "" }); // Handle undefined value by setting it to empty string
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put("/profile", formData);
      toast({
        title: "Success",
        description: "Payment method added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data ||
          "An error occurred while adding the payment method",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt="30px">
      <form onSubmit={handleSubmit}>
        <Text textAlign={"start"} fontWeight={"600"} fontSize="2xl" mb={4}>
          Bank Account Information
        </Text>
        <Text textAlign={"start"} fontSize="sm" mb={4} width={{base:"100%",lg:"50%"}}>
          Add the checking account where you want to receive funds. This account
          must be able to receive direct deposits in the currency in which you
          raise funds. We don’t support wire transfers, savings accounts, or
          virtual bank accounts.
        </Text>

        <Box bg={"#FBFBFA"} p={{base:"10px",lg:"40px"}} borderRadius={"10px"}>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  isRequired mb={4}>
            <FormLabel>Payment Method</FormLabel>
            <Select
              borderRadius={"1px"}
              bg={"white"}
              placeholder="Select payment method"
              name="payment_info.payment_method"
              value={paymentMethod || formData.payment_info.payment_method}
              onChange={(e) => {
                handleChange(e);
                setPaymentMethod(e.target.value);
              }}
            >
              <option value="Paypal">Paypal</option>
              <option value="Stripe">Stripe</option>
              <option value="Bank">Bank</option>
            </Select>
          </FormControl>

          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  isRequired mb={4}>
            <FormLabel>Account Holder Name</FormLabel>
            <Input
              placeholder="Enter account holder name"
              name="payment_info.account_holder_name"
              defaultValue={formData.payment_info.account_holder_name}
              onChange={handleChange}
              borderRadius={"1px"}
              bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  isRequired mb={4}>
            <FormLabel>Bank Name</FormLabel>
            <Input
              placeholder="Enter bank name"
              name="payment_info.bank_name"
              defaultValue={formData.payment_info.bank_name}
              onChange={handleChange}
                borderRadius={"1px"}
                            bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  isRequired mb={4}>
            <FormLabel>Account Number</FormLabel>
            <Input
              placeholder="Enter account number"
              name="payment_info.account_number"
              defaultValue={formData.payment_info.account_number}
              onChange={handleChange}
                borderRadius={"1px"}
                            bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  isRequired mb={4}>
            <FormLabel>IBAN Number</FormLabel>
            <Input
              placeholder="Enter iban number"
              name="payment_info.iban_number"
              defaultValue={formData.payment_info.iban_number}
              onChange={handleChange}
                borderRadius={"1px"}
                            bg={"white"}
            />
          </FormControl>
        </Box>




        <Box mt={"30px"} bg={"#FBFBFA"} p={{base:"10px",lg:"40px"}} borderRadius={"10px"}>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  mb={4}>
            <FormLabel>Address</FormLabel>
            <Input
              placeholder="Enter address"
              name="address"
              defaultValue={formData.address}
              onChange={handleChange}
              borderRadius={"1px"}
              bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  mb={4}>
            <FormLabel>City</FormLabel>
            <Input
              placeholder="Enter city"
              name="city"
              defaultValue={formData.city}
              onChange={handleChange}
              borderRadius={"1px"}
              bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  mb={4}>
            <FormLabel>State</FormLabel>
            <Input
              placeholder="Enter state"
              name="state"
              defaultValue={formData.state}
              onChange={handleChange}
              borderRadius={"1px"}
              bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""}  mb={4}>
            <FormLabel>Zip Code</FormLabel>
            <Input
              placeholder="Enter zip code"
              name="zip"
              defaultValue={formData.zip}
              onChange={handleChange}
              borderRadius={"1px"}
              bg={"white"}
            />
          </FormControl>
          <FormControl  w={{base:"100%",lg:"700px"}} bg={""} mb={4}>
            <FormLabel>Country</FormLabel>
            <Input
              placeholder="Enter country"
              name="country"
              defaultValue={formData.country}
              onChange={handleChange}
              borderRadius={"1px"}
              bg={"white"}
            />
          </FormControl>
<Flex justifyContent={"start"}>

          <Button type="submit" mb={10} colorScheme="orange" width={{base:"100%",lg:"50%"}}>
            Update Payment Method
          </Button>
          </Flex>
        </Box>
      </form>
    </Box>
  );
};

export default PaymentMethods;
