import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaImage, FaTrashAlt } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import axiosInstance from "../../../../../../../libs/axiosService";
import { reward_tiers_initial_state } from "../../../../../../../libs/constants";
import { useParams } from "react-router-dom";
import useUpdateProject from "../../../../../../../libs/useUpdateProjectService";

const NewAddOns = ({ project, setaddOnList, setNewaddOn, editaddOn, setEditaddOn }) => {
  // const [newitem, setnewitem] = React.useState(false);

  const [formData, setFormData] = React.useState(reward_tiers_initial_state);

  useEffect(() => {

    if (editaddOn) {
      setFormData(editaddOn);

      if (editaddOn?.image) {
        setSelectedImage(editaddOn.image);
      }
    }

  }, [editaddOn]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleNestedChange = (e, category, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: value,
      },
    }));
  };

  // Deep merge function
  const deepMerge = (target, source) => {
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object && key in target) {
        Object.assign(source[key], deepMerge(target[key], source[key]));
      }
    }
    return { ...target, ...source };
  };

  const handleRadioChange = (value, category) => {
    const keys = category.split('.');
    let updateBody = {};
    let currentLevel = updateBody;

    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        currentLevel[k] = value;
      } else {
        currentLevel[k] = {};
        currentLevel = currentLevel[k];
      }
    });

    // Update state with deep merge
    setFormData((prevData) => deepMerge(prevData, updateBody));
  };


  const handleNestedRadioChange = (value, category, key) => {
    console.log(value, category, key);
    setFormData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [key]: value,
      },
    }));
  };

  
  const { id } = useParams();
  const { updateProjectField } = useUpdateProject(id);


  const handleSaveAddOn = () => {

    if (editaddOn) {
      // Update the AddOn in the list
      const updatedList = project.rewards.add_ons.map((addOn) => {
        if (addOn._id === editaddOn._id) {
          return formData;
        }
        return addOn;
      });

      updateProjectField("rewards", { add_ons: updatedList });

      setaddOnList(updatedList);
      setNewaddOn(false);
    } else {

      const updatedProject = { ...project };
      updatedProject.rewards.add_ons.push(formData);
      updateProjectField("rewards", { add_ons: updatedProject.rewards.add_ons });
      setaddOnList(updatedProject.rewards.add_ons);
      setNewaddOn(false);
    }
  };


  const [selectedImage, setSelectedImage] = React.useState(null);


  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('image', file);


    try {
      const response = await axiosInstance.post('/upload', formData); // Adjust the URL according to your API
      console.log(response.data);
      setSelectedImage(response.data.urls.image); // Adjust according to your API response
      setFormData((prevData) => ({
        ...prevData,
        image: response.data.urls.image,
      }));

    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      console.log('Image uploaded successfully');
    }
  };

  const handleImageDelete = (url) => {
    // get the image name from the URL
    const imageName = url.split('/').pop();
    // send a DELETE request to the server
    axiosInstance.delete(`/uploads/${imageName}`)
      .then(response => {
        // clear the selected image
        setSelectedImage(null);
        // set formData.image to an empty string
        setFormData((prevData) => ({
          ...prevData,
          image: "",
        }));
      })
      .catch(error => console.error('Error deleting image:', error));

    setSelectedImage(null);
  };


  return (
    <>
      <Box textAlign={"start"}>
        <Text fontSize={"35px"} fontWeight={""} color={""}>
          {editaddOn ? "Update " : "Add a"} Add-On
         {editaddOn ? <Button size="sm" float={"right"} onClick={
            () => {
              setFormData(reward_tiers_initial_state)
              setEditaddOn(null)
              setSelectedImage(null)
            }
          }>
            Skip Updating : Create New
          </Button> : 
          <Button size="sm" float={"right"} onClick={
            () => {
              setFormData(reward_tiers_initial_state)
              setEditaddOn(null)
              setNewaddOn(false)
            }
          }>
            Go Back
          </Button>
          
          }
        </Text>
        <Text mt={""} fontSize={""} fontWeight={""} color={""}>
        Offer your backers additional add-ons bundles to add to their pledge.

        </Text>

        <Box mt={"50px"}>
          <Flex alignItems={"center"} gap={"20px"}>
            <Box w={"100%"}>
              <Text fontSize={""} fontWeight={""} color={""}>
                Title
              </Text>
              <Input
                w={"100%"}
                mt={"5px"}
                placeholder="Get an early copy of the game"
                size="md"
                name="title"
                value={formData.title}
                onChange={handleChange}
              />
            </Box>
          </Flex>

          <Divider my={"30px"} />

          <Box>
            <Text fontSize={"20px"} fontWeight={"500"} color={""}>
              Pricing
            </Text>
            <Text fontSize={""} fontWeight={""} color={""}>
              Pledge amount
            </Text>
            <InputGroup mt={"10px"}>
              <InputLeftAddon>$</InputLeftAddon>
              <Input
                type="number"
                placeholder="500"
                name="pricing"
                value={formData.pricing}
                onChange={handleChange}
              />
            </InputGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Image
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Show your backers what they'll receive for their support. Images
              should be honest, and should avoid banners, badges, and overlaid
              text.
            </Text>
            {(!selectedImage || selectedImage === "") ? (
              <Box
                border={"1px solid rgba(0,0,0,0.1)"}
                p={"40px"}
                width={"50%"}
                bg={"#FBFBFA"}
                position="relative"
              >
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  opacity="0"
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  cursor="pointer"
                />
                <Flex justifyContent={"center"} mb={"30px"}>
                  <Box
                    borderRadius={"50%"}
                    bg={"white"}
                    p={"20px"}
                    boxShadow={"1px 1px 10px gray"}
                  >
                    <FaImage size={"30px"} />
                  </Box>
                </Flex>
                <Text
                  fontSize={""}
                  textAlign={"center"}
                  fontWeight={""}
                  color={""}
                >
                  Drop an image here or select a file
                </Text>
                <Text
                  fontSize={"14px"}
                  textAlign={"center"}
                  fontWeight={""}
                  mt={"5px"}
                  color={"#656E72"}
                >
                  It must be a JPG, PNG, GIF, or WEBP, no larger than 50 MB.
                </Text>
              </Box>
            ) : (
              <Box position="relative" width={"50%"}>
                <Image src={process.env.REACT_APP_API_BASE_URL + selectedImage} alt="Selected" borderRadius="10px" />
                <IconButton
                  icon={<FaTrashAlt />}
                  position="absolute"
                  top="10px"
                  right="10px"
                  onClick={() => handleImageDelete(selectedImage)}
                  aria-label="Delete Image"
                />
              </Box>
            )}
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Description (optional)
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Describe what makes this Add-On stand out from your other
              offerings. Avoid re-listing items as this will look repetitive to
              backers.
            </Text>
            <Textarea
              mt={"20px"}
              placeholder="Get an early copy"
              resize={"none"}
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </Box>

          <Divider my={"30px"} />


          {/* <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Items *
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Including items in your Add-On and add-ons makes it easy for
              backers to understand and compare <br /> your offerings. An item
              can be anything you plan to offer your backers. Some examples
              include <br /> playing cards, a digital copy of a book, a ticket
              to a play, or even a thank-you in your documentary.
            </Text>

            {newitem && (
              <>
                <Box
                  my={"20px"}
                  bg={""}
                  p={"20px"}
                  border={"1px solid rgba(0,0,0,0.1)"}
                >
                  <Box>
                    <Text fontSize={""}>Add an existing item</Text>
                    <Select mt={"10px"} placeholder="Choose one" size="md">
                      <option>Choose one</option>
                    </Select>
                    <Text my={"10px"} fontSize={""}>
                      Or
                    </Text>

                    <Text mt={"10px"} fontSize={""}>Create a new item</Text>
                    <Input
                        mt={"10px"}
                        type="text"
                        placeholder="30"
                        size="md"
                      />
                  </Box>

                  <Text mt={"10px"} fontSize={""}>
                    Item image (optional)
                  </Text>
                  <Box
                    mt={"5px"}
                    boxShadow={"1px 1px 5px rgba(0,0,0,0.5)"}
                    w={{ base: "100%", lg: "400px" }}
                    border={"1px solid rgba(0,0,0,0.1)"}
                    p={"40px"}
                    bg={"#FBFBFA"}
                  >
                    <Flex justifyContent={"center"} mb={"30px"}>
                      <Box
                        borderRadius={"50%"}
                        bg={"white"}
                        p={"20px"}
                        boxShadow={"1px 1px 10px gray"}
                      >
                        <FaImage size={"30px"} />
                      </Box>
                    </Flex>
                    <Text
                      fontSize={""}
                      textAlign={"center"}
                      fontWeight={""}
                      color={""}
                    >
                      Drop a image here or select a file
                    </Text>
                    <Text
                      fontSize={"14px"}
                      textAlign={"center"}
                      fontWeight={""}
                      mt={"5px"}
                      color={"#656E72"}
                    >
                      It must be a JPG,PNG,GIF or WEBP,no larger than 50 MB.
                    </Text>
                  </Box>

                  <Button
                    mt={"20px"}
                    w={"100%"}
                    colorScheme="orange"
                    fontSize={"14px"}
                    fontWeight={500}
                    
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setnewitem(false)}
                    mt={"10px"}
                    w={"100%"}
                    colorScheme="gray"
                    fontSize={"14px"}
                    fontWeight={500}
                    
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            )}
            <Button
              onClick={() => setnewitem(true)}
              mt={"10px"}
              w={"100%"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}
              
            >
              New Item
            </Button>
            <Flex
              mt={"10px"}
              border={"1px solid red"}
              borderLeft={"4px solid red"}
              alignItems={"center"}
              px={"60px"}
              py={"10px"}
              bg={"#FFF2EC"}
              gap={"20px"}
            >
              <RiErrorWarningLine size={"30px"} color="red" />
              <Text fontSize={"20px"}>
                Add all items included in this add-on
              </Text>
            </Flex>
          </Box>
          <Divider my={"30px"} /> */}

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Contents
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Will your backers receive any physical goods?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "content.content")}
              value={formData.content.content === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.content.content === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "content", "content_type")
                        }
                        value={formData.content.content_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="physical-goods">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Only physical goods
                              </Text>
                            </Radio>
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="physical-digital-goods">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Physical and digital goods/services
                              </Text>
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No, only digital goods and/or services
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>

            <Flex mt={"20px"} alignItems={"center"} gap={"10px"}>
              <CiLocationOn size={"20px"} color="orange" fontWeight={"bold"} />
              <Text fontSize={"12px"} fontWeight={""} color={"#656969"}>
                Make sure that your description of the physical goods meets
                Gorillafunder's{" "}
                <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                  requirements.
                </span>
              </Text>
            </Flex>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Shipping
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Do you need to collect a shipping address?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "shipping.shipping")}
              value={formData.shipping.shipping === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.shipping.shipping === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "shipping", "shipping_type")
                        }
                        value={formData.shipping.shipping_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="only-physical">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Only physical goods
                              </Text>
                            </Radio>
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="physical-and-digital">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Physical and digital goods/services
                              </Text>
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>

                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Where will you ship to?
                      </Text>

                      <Select
                        placeholder="Select option"
                        name="shipping.destination"
                        value={formData.shipping.destination}
                        onChange={(e) => handleNestedChange(e, "shipping", "destination")}
                      >
                        <option value="domestic">Domestic (only in your country)</option>
                        <option value="international">International (anywhere in the world)</option>
                      </Select>

                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Shipping cost
                      </Text>
                      <Input
                        mt={"10px"}
                        type="number"
                        placeholder="Enter shipping cost"
                        name="shipping.shipping_cost"
                        value={formData.shipping.shipping_cost}
                        onChange={(e) => handleNestedChange(e, "shipping", "shipping_cost")}
                      />
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No, only digital goods and/or services
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Estimated delivery
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              When do you expect to deliver this add-on to your backers?
            </Text>

            <Flex gap={"20px"} mt={"20px"}>
              <Select
                placeholder="Month"
                name="estimated_delivery_month"
                value={formData.estimated_delivery_month}
                onChange={handleChange}
              >
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <option value={month} key={month}>
                    {month}
                  </option>
                ))}
              </Select>
              <Input
                type="number"
                placeholder="Year"
                name="estimated_delivery_year"
                value={formData.estimated_delivery_year}
                onChange={handleChange}
              />
            </Flex>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Project quantity
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Do you need to limit the quantity available of this add-on?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "project_quantity.project_quantity")}
              value={formData.project_quantity.project_quantity === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.project_quantity.project_quantity === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "project_quantity", "quantity_type")
                        }
                        value={formData.project_quantity.quantity_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="available-quantity">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Limit the quantity available
                              </Text>
                            </Radio>
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="available-per-backer">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Limit the quantity available per backer
                              </Text>
                            </Radio>
                          </Box>
                        </Stack>
                      </RadioGroup>

                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Quantity
                      </Text>
                      <Input
                        mt={"10px"}
                        type="number"
                        placeholder="Enter quantity"
                        name="project_quantity.quantity"
                        value={formData.project_quantity.quantity}
                        onChange={(e) => handleNestedChange(e, "project_quantity", "quantity")}
                      />
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Text mb={"10px"} fontSize={"18px"} fontWeight={""} color={""}>
              Time limit
            </Text>
            <Text fontSize={""} fontWeight={""} color={"#656969"}>
              Do you need to limit the availability of this add-on?
            </Text>

            <RadioGroup
              onChange={(value) => handleRadioChange(value, "time_limit.time_limit")}
              value={formData.time_limit.time_limit === "yes" ? "yes" : "no"}
            >
              <Stack>
                <Box border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="yes">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      Yes
                    </Text>
                  </Radio>

                  {formData.time_limit.time_limit === "yes" && (
                    <>
                      <Divider my={"20px"} />
                      <Text mt={"20px"} fontSize={""} fontWeight={""} color={""}>
                        Which of the following best applies?
                      </Text>

                      <RadioGroup
                        onChange={(value) =>
                          handleNestedRadioChange(value, "time_limit", "limit_type")
                        }
                        value={formData.time_limit.limit_type}
                      >
                        <Stack>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="end-date">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Set a specific end date
                              </Text>
                            </Radio>
                            {formData.time_limit.limit_type === "end-date" && (
                              <Box mt={"10px"}>
                                <Input
                                  type="date"
                                  placeholder="End date"
                                  name="time_limit.specific_end_date"
                                  value={formData.time_limit.specific_end_date}
                                  onChange={(e) => handleNestedChange(e, "time_limit", "specific_end_date")}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                            <Radio value="days">
                              <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                                Set the number of days this add-on is available
                              </Text>
                            </Radio>
                            {formData.time_limit.limit_type === "days" && (
                              <Box mt={"10px"}>
                                <Input
                                  type="number"
                                  placeholder="Number of days"
                                  name="time_limit.days_available"
                                  value={formData.time_limit.days_available}
                                  onChange={(e) => handleNestedChange(e, "time_limit", "days_available")}
                                />
                              </Box>
                            )}
                          </Box>
                        </Stack>
                      </RadioGroup>
                    </>
                  )}
                </Box>
                <Box mt={"10px"} border={"1px solid rgba(0,0,0,0.2)"} p={"20px"}>
                  <Radio value="no">
                    <Text ml={"20px"} fontSize={""} fontWeight={""} color={""}>
                      No
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </Box>

          <Divider my={"30px"} />

          <Box>
            <Button colorScheme="orange" size="md" onClick={handleSaveAddOn}>
              {editaddOn ? "Update " : "Save"} Add-On
            </Button>
            <Button ml={5} colorScheme="gray" size="md" onClick={() => setNewaddOn(false)}>
              Cancel
            </Button>
          </Box>

        </Box>
      </Box >

    </>
  );
};

export default NewAddOns;
