import React, { useState, useCallback, useEffect } from 'react';
import { Box, Text, Grid, GridItem, Input, Flex, Image, IconButton, Textarea, Checkbox, Select, FormControl, FormLabel, Button, useToast } from '@chakra-ui/react';
import { FaImage, FaTrashAlt } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import useGetUser from '../../../libs/useGetUser';
import useUpdateProfile from '../../../libs/useUpdateProfile';
import axiosInstance from '../../../libs/axiosService';

const EditProfile = () => {
    const { user } = useGetUser();
    const { updateProfileField } = useUpdateProfile();
    const toast = useToast();
    const [image, setImage] = useState('');
    const [privacyChecked, setPrivacyChecked] = useState(user?.privacy === 'public' ? true : false);

    useEffect(() => {

        if (user?.avatar) {
            setImage(user.avatar);
        }

    }, [user]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedUpdateProfileField = useCallback(
        debounce((field, value) => {
            updateProfileField(field, value);
        }, 300), // Adjust the debounce delay as needed
        []
    );

    const handleChange = (e, key, customValue) => {
        if (customValue !== undefined) {
            debouncedUpdateProfileField(key, customValue);
            return;
        }
        if (e.target.value !== undefined) {
            const { value } = e.target;
            debouncedUpdateProfileField(key, value);
        }
    };

    const handleImageUpload = async (event) => {
        // Handle image upload logic
        const file = event.target.files[0];

        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);


        try {
            const response = await axiosInstance.post('/upload', formData); // Adjust the URL according to your API
            setImage(response?.data?.urls?.image); // Adjust according to your API response
            handleChange(null, 'avatar', response?.data?.urls?.image); // Adjust according to your API response
        } catch (error) {
            console.error('Error uploading image:', error);
        } finally {
            console.log('Image uploaded successfully');
        }

    };

    const handleImageDelete = (url) => {

        const imageName = url.split('/').pop();
        // send a DELETE request to the server
        axiosInstance.delete(`/uploads/${imageName}`)
            .then(response => {
                // clear the selected image
                setImage("");
                handleChange(null, 'avatar', ''); // Send empty string to API

            })
            .catch(error => console.error('Error deleting image:', error));

        setImage("");
    };

    return (
        <Box mt={"30px"}>
            
                {/* First Column */}
                <Box bg={"#FBFBFA"} p={{base:"10px",lg:"40px"}} borderRadius={"10px"}>
                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            placeholder="Enter your name"
                            defaultValue={user?.name}
                            onChange={(e) => handleChange(e, 'name')}
                            borderRadius={"1px"}
                            bg={"white"}
                        />
                    </FormControl>

                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Avatar</FormLabel>
                        {(!image || image === '') ? (
                            <Box
                                border="1px solid rgba(0,0,0,0.1)"
                                p="40px"
                                width="100%"
                           
                                position="relative"
                                bg={"white"}
                            >
                                <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    opacity="0"
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    width="100%"
                                    height="100%"
                                    cursor="pointer"
                                    borderRadius={"1px"}
                                    bg={"white"}
                                />
                                <Flex justifyContent="center" mb="30px">
                                    <Box
                                        borderRadius="50%"
                                        bg="white"
                                        p="20px"
                                        boxShadow="1px 1px 10px gray"
                                    >
                                        <FaImage size="30px" />
                                    </Box>
                                </Flex>
                                <Text
                                    fontSize="16px"
                                    textAlign="center"
                                    fontWeight="bold"
                                    color="black"
                                >
                                    Drop an image here or select a file
                                </Text>
                                <Text
                                    fontSize="14px"
                                    textAlign="center"
                                    mt="5px"
                                    color="#656E72"
                                >
                                    It must be a JPG, PNG, GIF, or WEBP, no larger than 50 MB.
                                </Text>
                            </Box>
                        ) : (
                            <Box position="relative" width="50%">
                                <Image src={process.env.REACT_APP_API_BASE_URL + image} alt="Selected" borderRadius="10px" />
                                <IconButton
                                    icon={<FaTrashAlt />}
                                    position="absolute"
                                    top="10px"
                                    right="10px"
                                    onClick={() => handleImageDelete(image)}
                                    aria-label="Delete Image"
                                />
                            </Box>
                        )}
                    </FormControl>

                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Biography</FormLabel>
                        <Textarea
                            placeholder="Tell us about yourself"
                            defaultValue={user?.biography}
                            onChange={(e) => handleChange(e, 'biography')}
                            bg={"white"}
                            borderRadius={"1px"}
                        />
                    </FormControl>

                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Privacy</FormLabel>
                        <Checkbox
                            isChecked={privacyChecked}
                            onChange={(e) => {
                                setPrivacyChecked(e.target.checked);
                                handleChange(null, 'privacy', e.target.checked ? 'public' : 'private');
                            }}
                        >
                            Only show my name and avatar.
                        </Checkbox>
                        <Text fontSize="14px" color="#656E72" mt={2}>
                            Uncheck this box to also show your biography, websites, and projects you've backed.
                        </Text>
                    </FormControl>
                </Box>

                {/* Second Column */}
             
            


                <Box mt={"30px"} bg={"#FBFBFA"} p={{base:"10px",lg:"40px"}} borderRadius={"10px"}>
                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Location</FormLabel>
                        <Input
                            placeholder="Enter your location"
                            defaultValue={user?.location}
                            onChange={(e) => handleChange(e, 'location')}
                            borderRadius={"1px"}
                            bg={"white"}
                        />
                    </FormControl>

                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Timezone</FormLabel>
                        <Select
                          borderRadius={"1px"}
                          bg={"white"}
                            placeholder="Select timezone"
                            defaultValue={user?.timezone}
                            onChange={(e) => handleChange(e, 'timezone')}
                        >
                            {/* Populate with all timezones */}
                            <option value="GMT-12:00">GMT-12:00</option>
                            <option value="GMT-11:00">GMT-11:00</option>
                            <option value="GMT-10:00">GMT-10:00</option>
                            <option value="GMT-09:00">GMT-09:00</option>
                            <option value="GMT-08:00">GMT-08:00</option>
                            <option value="GMT-07:00">GMT-07:00</option>
                            <option value="GMT-06:00">GMT-06:00</option>
                            <option value="GMT-05:00">GMT-05:00</option>
                            <option value="GMT-04:00">GMT-04:00</option>
                            <option value="GMT-03:00">GMT-03:00</option>
                            <option value="GMT-02:00">GMT-02:00</option>
                            <option value="GMT-01:00">GMT-01:00</option>
                            <option value="GMT+00:00">GMT+00:00</option>
                            <option value="GMT+01:00">GMT+01:00</option>
                            <option value="GMT+02:00">GMT+02:00</option>
                            <option value="GMT+03:00">GMT+03:00</option>
                            <option value="GMT+04:00">GMT+04:00</option>
                            <option value="GMT+05:00">GMT+05:00</option>
                            <option value="GMT+06:00">GMT+06:00</option>
                            <option value="GMT+07:00">GMT+07:00</option>
                            <option value="GMT+08:00">GMT+08:00</option>
                            <option value="GMT+09:00">GMT+09:00</option>
                            <option value="GMT+10:00">GMT+10:00</option>
                            <option value="GMT+11:00">GMT+11:00</option>
                            <option value="GMT+12:00">GMT+12:00</option>
                        </Select>
                    </FormControl>

                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Vanity URL</FormLabel>
                        <Input
                            placeholder="Enter your vanity URL"
                            defaultValue={user?.vanity_url}
                            onChange={(e) => handleChange(e, 'vanity_url')}
                            borderRadius={"1px"}
                            bg={"white"}
                        />
                    </FormControl>

                    <FormControl mb={4}  w={{base:"100%",lg:"700px"}} bg={""}>
                        <FormLabel>Your Websites</FormLabel>
                        <Input
                            placeholder="Enter your websites"
                            defaultValue={user?.your_website}
                            onChange={(e) => handleChange(e, 'your_website')}
                            borderRadius={"1px"}
                            bg={"white"}
                        />
                    </FormControl>
                        
<Flex justifyContent={"start"}>


                    <Button  w={{base:"100%",lg:"700px"}}  colorScheme="orange"  mt={"80px"} onClick={() => {
                        toast ({
                            title: "Profile Updated",
                            description: "Your profile has been updated successfully",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                        })
                    }}>Update Profile</Button>
                    </Flex>
</Box>
        </Box>
    );
};

export default EditProfile;
