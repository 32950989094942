import React, { useState } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import axiosInstance from "../libs/axiosService";
import Login2 from "./Login2";
import Signup2 from "./ModalSignup";

const Signup = () => {
  const [signupInputValue, setSignupInputValue] = useState({
    name: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSignupInputValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/signup", signupInputValue);
      toast({
        title: "Account created.",
        description: "You have successfully created an account.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.error || "Something went wrong.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Navbar />
      <Grid templateColumns={{base:"1fr",lg:"1fr 1fr"}} p={"20px"} h={"80vh"}>
    <GridItem  colSpan={{ base: "1fr" }} overflow={"hidden"}>
              <Box transform={"rotate(5deg)"} mt={""} >

                <Image
                  src="../../images/movies/authSideImage.jpg"
                  height={"800px"}
                  width={"100%"}
                // objectPosition={"contain"}
                objectFit={"cover"}
                />
              </Box>
              </GridItem>

      <GridItem colSpan={{base:"1fr"}} h={"80vh"}>
      <Flex alignItems={"center"} px={{base:"",lg:"20px"}} h={"100%"}>

<Signup2/>
      </Flex>
      </GridItem>
    </Grid>
      <Footer />
    </>
  );
};

export default Signup;
