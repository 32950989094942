import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  Progress,
  Button,
  useToast,
  Flex,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import Navbar from "../../../Components/Navbar";
import Footer from "../../../Components/Footer";
import "./FilmsDetails.css";
import {
  FaCode,
  FaCompass,
  FaEnvelope,
  FaFacebook,
  FaHeart,
  FaRegBookmark,
  FaTwitter,
} from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import FluidContainer from "../../../Components/shared/containerFluid";
import { TiMessages } from "react-icons/ti";
import { AiFillNotification } from "react-icons/ai";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  GridItem,
} from "@chakra-ui/react";
import Campaign from "./Campaign/Campaign";
import Rewards from "./Rewards/Rewards";
import FAQ from "./FAQ/FAQ";
import Comments from "./Comments/Comments";
import Community from "./Community/Community";
import Updates from "./Updates/Updates";
import { RemindMeModal } from "../../../Components/RemindMeModal/RemindMeModal";
import { Link, NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axiosInstance from "../../../libs/axiosService";
import LoadingBox from "../../../Components/shared/Loading";
import { SeeOptions } from "./SeeOptions/SeeOptions";
import PerksComponentMobile from "./perksComponentMobile";

const FilmsDetailsComponent = () => {
  const navigate = useNavigate()
  const [filmDetails, setFilmDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [isClosed, setIsClosed] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isTooltipClose, setTooltipClose] = useState(false);

  const [projects, setProjects] = useState([]);

  const handleMouseEnter = () => {
    setTooltipOpen(true)
    setTooltipClose(true)
  };
  const handleMouseLeave = () => {
    setTooltipOpen(true)
  };
  const handleViewFullProfile = (id) => {
    navigate("/view-full-profile/" + id)
  }
  useEffect(() => {
    const fetchFilmDetails = async () => {
      try {
        const response = await axiosInstance.get("/project/" + id);
        setFilmDetails(response.data);
        console.log("++++++++++++++++++", filmDetails);
        setLoading(false);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch film details.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchFilmDetails();
  }, [toast, id]);


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project-lists");

        if (response.data.projects.length >= 2) {
          const trimCurrentProject = response.data.projects.filter(
            (project) => project._id !== id
          );

          const randomProjects = trimCurrentProject.sort(() => 0.5 - Math.random());

          const selectedProjects = randomProjects.slice(0, 3);

          setProjects(selectedProjects);
        }


        setLoading(false);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch projects.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });

        setLoading(false);
      }
    };

    if (filmDetails?.stats?.days_left === 0) {
      setIsClosed(true);
    }

    fetchProjects();
  }, [filmDetails]);



  const navigateToDetails = (id) => {

    navigate(`/film/${id}`);
  };


  useEffect(() => {
    const tab = searchParams.get("tab");
    switch (tab) {
      case "campaign":
        setTabIndex(0);
        break;
      case "rewards":
        setTabIndex(1);
        break;
      case "faq":
        setTabIndex(2);
        break;
      case "updates":
        setTabIndex(3);
        break;
      case "comments":
        setTabIndex(4);
        break;
      case "community":
        setTabIndex(5);
        break;
      default:
        setTabIndex(0);
    }
  }, [searchParams]);

  const handleTabChange = (index) => {
    setTabIndex(index);
    switch (index) {
      case 0:
        setSearchParams({ tab: "campaign" });
        break;
      case 1:
        setSearchParams({ tab: "rewards" });
        break;
      case 2:
        setSearchParams({ tab: "faq" });
        break;
      case 3:
        setSearchParams({ tab: "updates" });
        break;
      case 4:
        setSearchParams({ tab: "comments" });
        break;
      case 5:
        setSearchParams({ tab: "community" });
        break;
      default:
        setSearchParams({ tab: "campaign" });
    }
  };

  if (loading) {
    return (
      <>
        <Navbar logoLevel={"2"} />
        <LoadingBox />
        <Footer />
      </>
    );
  }

  return (
    <Box>
      <Navbar logoLevel={"2"} />
      <Container maxW="container.xl" py={{ base: 2, md: 12 }}>
        {
          isClosed &&
          <>
            <Box mt={"30px"} mb={"30px"} w={{ base: "90%", md: "70%", lg: "50%" }} mx={{
              base: "auto",
              md: "5px",
              lg: "5px",
            }}>
              <Text
                textAlign={{ base: "center", md: "start" }}
                fontSize={"14px"}
                fontWeight={"500"}
                color={"gray"}
              >
                THIS CAMPAIGN IS CLOSED
              </Text>
              <Box>
                <Heading
                  color={"#1f0733"}
                  fontWeight={"500"}
                  as="h1"
                  py={"20px"}
                  textAlign={{ base: "center", md: "left" }}
                  fontSize={{ base: "24px", md: "28px", lg: "32px" }}
                >
                  {filmDetails?.basic?.title}
                </Heading>
                <Text
                  mb={""}
                  fontSize={{ base: "14px", md: "16px" }}
                  lineHeight={"24px"}
                  color={"#5b595d"}
                  textAlign={{ base: "justify", md: "left" }}
                >
                  {filmDetails?.basic?.subtitle}
                </Text>
              </Box>
              <Box>
                <Flex
                  my={"20px"}
                  alignItems={"center"}
                  gap={{ base: "10px", md: "5px" }}
                  flexDirection={{ base: "column", md: "row" }}
                  justifyContent={{ base: "center", md: "flex-start" }}
                >
                  <Button
                    colorScheme="orange"
                    onClick={() => navigate("/")}
                    cursor={"pointer"}
                    size={"xs"}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight={500}
                    p={4}
                    px={6}
                    w={{ base: "100%", md: "auto" }}
                  >
                    VIEW NEWEST CAMPAIGN
                  </Button>
                  <Button
                    colorScheme="gray"
                    onClick={() => navigate("/view-campaigns")}
                    cursor={"pointer"}
                    size={"xs"}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight={500}
                    p={4}
                    px={6}
                    w={{ base: "100%", md: "auto" }}
                  >
                    DISCOVER SIMILAR CAMPAIGNS
                  </Button>
                  <Button
                    colorScheme="gray"
                    onClick={() => setIsClosed(false)}
                    cursor={"pointer"}
                    size={"xs"}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight={500}
                    p={4}
                    px={6}
                    w={{ base: "100%", md: "auto" }}
                  >
                    ABOUT THIS CAMPAIGN
                  </Button>
                </Flex>
              </Box>
            </Box>
            <hr style={{ marginBottom: "30px" }} />
          </>
        }

        {/* Video or image section */}
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={10} >


          <Box
            w={{ base: "110%", sm: "100%" }} // Adjust width for smaller screens
            position="relative"
            left={{ base: "-16px", sm: "0" }} // Adjust left position for smaller screens
            overflow={{ base: "hidden !important", sm: "visible" }} // Adjust overflow for smaller screens
          >
            {/* Video or image */}

            {filmDetails?.basic?.project_video ? (
              <Box
                overflow={"hidden"}
                overflowY={"auto"}
                bg="gray.200"
                w="100%"
                height={"500px"}
                borderRadius={{ base: "none", md: "lg" }}
                mb={{ base: 4, md: 0 }}
              >
                <video
                  controls={true}
                  width="100%"
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                    filmDetails?.basic?.project_video
                  }
                ></video>
              </Box>
            ) : (
              <Box
                overflow={"hidden"}
                bg="gray.200"
                maxH={{ base: "240px", md: "460px" }}
                w="100%"
                borderRadius={{ base: "none", md: "lg" }}
                mb={{ base: 4, md: 0 }}
              >
                <img
                  width={"100%"}
                  height={"auto"}
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                    filmDetails?.basic?.project_image
                  }
                  alt=""
                />
              </Box>
            )}
          </Box>
          <Box>
            <Text
              textAlign={"start"}
              fontSize={"14px"}
              fontWeight={"600"}
              color={"green"}
            >
              {filmDetails?.stats?.days_left === 0 ? "CLOSED" : filmDetails?.basic?.subcategory}
            </Text>
            {/* Funding details */}
            <Box textAlign={"left"} mt={"5px"}>
              <Heading
                color={"#1f0733"}
                fontWeight={"500"}
                as="h1"
                mb={1}
                fontSize={{ base: "24px", lg: "40px" }}
              >
                {filmDetails?.basic?.title}
              </Heading>
              <Text
                mb={""}
                fontSize={"18px"}
                lineHeight={"24px"}
                color={"#5b595d"}
                textAlign={"justify"}
              >
                {filmDetails?.basic?.subtitle}
              </Text>

              <Flex my={"20px"} alignItems={"center"} gap={"20px"} >
                <Box w={"50px"} h={"50px"} bg={"red"} borderRadius={"50%"}>
                  <Image
                    borderRadius={"50%"}
                    objectFit={"cover"}
                    w={"100%"}
                    h={"100%"}
                    src={
                      process.env.REACT_APP_API_BASE_URL +
                      filmDetails?.people?.user?.avatar
                    }
                  />
                </Box>
                <Box position={"relative"}>

                  <Text className="userName"
                    p={"0px"} borderBottom={"1px solid transparent"} _hover={{ borderBottom: "1px solid #1f0733" }} fontSize={"20px"} fontWeight={500} color={"#1f0733"}>
                    {filmDetails?.people?.user?.name}
                    <Box top={"-120px"} className="tooltip" display={"none"} color={"white"} position={"absolute"} bg={"#2A2A2A"} p={"15px"} alignItems={"center"} justifyContent={"center"} flexDir={"column"}>

                      <Flex textAlign={"start"} my={"20px"} alignItems={""} gap={"20px"}>
                        <Box w={"50px"} h={"50px"} bg={"red"} borderRadius={"50%"}>
                          <Image
                            borderRadius={"50%"}
                            objectFit={"cover"}
                            w={"100%"}
                            h={"100%"}
                            src={
                              process.env.REACT_APP_API_BASE_URL +
                              filmDetails?.people?.user?.avatar
                            }
                          />
                        </Box>
                        <Box width={"150px"}>

                          <Text cursor={"pointer"} p={"0px"} borderBottom={"1px solid transparent"} _hover={{ borderBottom: "1px solid #1f0733" }} fontSize={"15px"} fontWeight={500} color={"white"}
                          >
                            {filmDetails?.people?.user?.name}
                          </Text>
                          {/* <Text   fontSize={"15px"}>http://www.oliveunion.com</Text> */}

                          <Text fontSize={"15px"} onClick={() => handleViewFullProfile(filmDetails?.people?.user?._id)} cursor={"pointer"} textDecoration={"underline"} mt={"10px"}>View full profile</Text>


                        </Box>
                      </Flex>
                    </Box>
                  </Text>



                  <Text fontSize={"14px"} mt={"5px"} color={"#5b595d"}>
                    2 Campaigns | {filmDetails?.people?.user?.location}
                  </Text>


                </Box>
              </Flex>

              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text
                  fontSize="24px"
                  color={"#1f0733"}
                  fontWeight="bold"
                  mb={1}
                >
                  US$ {filmDetails?.stats?.fund_raised?.toLocaleString()}
                </Text>
                <Flex alignItems={"center"} gap={"5px"}>
                  <Text
                    fontSize={"18px"}
                    color={"#1f0733"}
                    fontWeight={"bold"}
                    className="backers-count"
                  >
                    {filmDetails?.stats?.total_backers}
                  </Text>
                  <Text
                    fontSize={"18px"}
                    color={"#1f0733"}
                    className="film-backers"
                  >
                    backers
                  </Text>
                </Flex>
              </Flex>
              <Progress
                borderRadius={"10px"}
                colorScheme="orange"
                size="sm"
                value={filmDetails?.stats?.funded_percentage?.toFixed(2)}
              />

              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={"10px"}
              >
                <Flex alignItems={"center"} gap={"2px"}>
                  <Text
                    color={"#1f0733"}
                    fontSize={{ base: "16px", lg: "18px" }}
                  >
                    {filmDetails?.stats?.funded_percentage?.toFixed(2)}% of $
                    {parseInt(
                      filmDetails?.stats?.funding_goal
                    )?.toLocaleString()}
                  </Text>
                  <Tooltip
                    px={"40px"}
                    textAlign={"center"}
                    py={"20px"}
                    sx={{ bg: "#E6E6E7" }}
                    color={"#1f0733"}
                    label="This campaign will receive all funds raised even if it does not reach its funding goal."
                    placement="top"
                  >
                    <Text
                      borderBottom={"1px dashed #1f0733"}
                      cursor={"pointer"}
                      color={"#1f0733"}
                      ml="10px"
                      fontSize={"14px"}
                      fontWeight={"bold"}
                    >
                      {"  "} Flexible Goal
                    </Text>
                  </Tooltip>
                </Flex>
                <Flex alignItems={"center"} color={"#1f0733"} gap={"5px"}>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    fontWeight={"bold"}
                    color={"#1f0733"}
                    className="backers-count"
                  >
                    {filmDetails?.stats?.days_left}
                  </Text>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={"#1f0733"}
                    className="film-backers"
                  >
                    days left
                  </Text>
                </Flex>
              </Flex>

              <Flex
                flexDir={{ base: "column", lg: "row" }}
                justifyContent={"space-between"}
                mt={"20px"}
              >
                <Flex
                  display={{
                    base: "flex",
                    md: "none",
                  }}
                  mt={{ base: "30px", lg: "0px" }}
                  order={{ base: "2", lg: "1" }}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Box w={"100%"}>
                    <SeeOptions
                      options={false}
                      withoutPerk={true}
                      filmDetails={filmDetails}
                    />
                  </Box>
                </Flex>

                <Box
                  bg={"#fff"}
                  position={"fixed"}
                  zIndex={99}
                  width={"100%"}
                  bottom={"0px"}
                  height={"85px"}
                  left="0px"
                  display={{
                    base: "flex",
                    md: "none",
                  }}
                />
                <Flex
                  display={{
                    base: "flex",
                    md: "none",
                  }}
                  position={"fixed"}
                  bottom={"18px"}
                  zIndex={100}
                  w={"92%"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Box w={"100%"}>
                    <SeeOptions
                      options={true}
                      withoutPerk={false}
                      buttonLabel="View Perks"
                      filmDetails={filmDetails}
                    />
                  </Box>
                </Flex>

                <Flex
                  display={{ base: "none", md: "flex" }}
                  mt={{ base: "30px", lg: "0px" }}
                  order={{ base: "2", lg: "1" }}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <Box w={"100%"}>
                    <SeeOptions
                      options={true}
                      withoutPerk={true}
                      buttonLabel="See Options"
                      filmDetails={filmDetails}
                    />
                  </Box>
                  <Button
                    w={{ base: "100%", lg: "" }}
                    colorScheme="gray"
                    size={"lg"}
                    fontSize={"17px"}
                    fontWeight={500}
                  >
                    <FaRegBookmark style={{ marginRight: "10px" }} />
                    Follow
                  </Button>
                </Flex>

                <Box
                  mt={{ base: "10px", lg: "0px" }}
                  order={{ base: "1", lg: "2" }}
                  display={"flex"}
                  gap={6}
                  justifyContent={{ base: "space-between" }}
                  alignItems="center"
                >
                  <FaFacebook size={"20px"} color="#39579a" />

                  <FaTwitter size={"20px"} color="#00abf0" />

                  <FaEnvelope size={"20px"} color="#656969" />

                  <FaCode size={"20px"} color="#656969" />
                </Box>
              </Flex>
            </Box>
          </Box>
        </Grid>
      </Container>
      <PerksComponentMobile filmDetails={filmDetails} />
      {/* <Box px={{base:"0px",lg:"320px"}}> */}
      <Container maxW={"container.xl"} px={{ base: "0px", lg: "" }}>
        <Tabs
          position="relative"
          variant="unstyled"
          // mx={"20px"}
          index={tabIndex}
          onChange={handleTabChange}
        >
          <TabList display="flex">
            <Tab fontWeight={"600"}>Story</Tab>
            {/* <Tab fontWeight={"600"}>Rewards</Tab> */}
            <Tab fontWeight={"600"}>FAQ</Tab>
            {/* <Tab>Updates</Tab> */}
            <Tab fontWeight={"600"}>Discussion</Tab>
            <Tab fontWeight={"600"}>Community</Tab>

            {/* <Box
            display="flex"
            flexDirection={{ base: "column", lg: "row" }}
            ml={"auto"}
            gap={4}
            w={{ base: "100%", md: "30%" }}
          >
            <Link to={"/back-this/" + id}>
              <Button
                colorScheme="orange"
                width="100%"
                fontSize={"14px"}
                fontWeight={500}
              >
                Back this project
              </Button>
            </Link>
            <RemindMeModal remindMe={"remindMe"} />
          </Box> */}
          </TabList>

          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <Campaign filmDetails={filmDetails} />
            </TabPanel>
            {/* <TabPanel>
            <Rewards filmDetails={filmDetails} />
          </TabPanel> */}
            <TabPanel>
              <FAQ filmDetails={filmDetails} />
            </TabPanel>
            {/* <TabPanel>
            <Updates />
          </TabPanel> */}
            <TabPanel>
              <Comments filmDetails={filmDetails} />
            </TabPanel>
            <TabPanel>
              <Community filmDetails={filmDetails} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
      {/* </Box> */}

      <Box textAlign={"start"} height={""} bg={"#F5F5F5"} >
        <Container maxW={"container.xl"} py={"50px"}>
          <Text fontSize={"24px"} fontWeight={""} color={"#1f0733"}>Tags for this project</Text>
          <Flex mt={"20px"} alignItems={"center"} gap={"10px"}>

            {
              filmDetails?.promotion?.tags?.length > 0 ?
                filmDetails?.promotion?.tags?.map((tag, index) => (
                  <Text _hover={{ backgroundColor: "#1f0733", color: "white" }}
                    color={"#1f0733"}
                    bg={"#E6E6E7"}
                    borderRadius={"18px"}
                    px={"10px"}
                    py={"5px"}
                    border={"1px solid #e6e6e7"} fontSize={"14px"} fontWeight={""}   >{tag}</Text>
                ))
                :
                <Text fontSize={"14px"} fontWeight={""} color={"#1f0733"}>No tags found</Text>
            }

          </Flex>

          <Text fontSize={"40px"} fontWeight={"600"} mt={"10px"} color={"#1f0733"}>You may also be interested in</Text>


          {projects.length > 0 ? (
            <Grid
              templateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
              mt={"20px"}
              gap={"40px"}
            >
              {projects?.map((ele, index) => (
                <GridItem colSpan={{ base: "1fr" }}>

                  <Box
                    textAlign={"start"}
                    w={{ base: "100%", lg: "336px" }}
                    h={"560px"}
                    onClick={() => {
                      navigateToDetails(ele._id);
                    }}
                    bg={""}
                  >
                    <Link to={`/film/${ele._id}`}>
                      <Box
                        h={"312px"}
                        borderRadius={"20px"}
                        position={"relative"}
                        className="relative-box"
                      >
                        <Box
                          w={"100%"}
                          h={"100%"}
                          position={"absolute"}
                          top={0}
                          left={0}
                          className="absolute-box"
                          borderRadius={"20px"}
                        >

                          <Box
                            w={"full"}
                            px={"10px"}
                            className="view-campaign-btn"
                          >
                            <Button
                              w={"full"}
                              size={"lg"}
                              colorScheme="orange"
                            >
                              View Campaign
                            </Button>
                          </Box>
                        </Box>
                        <Image
                          h={"312px"}
                          objectFit={"cover"}
                          src={
                            process.env.REACT_APP_API_BASE_URL +
                            ele.basic?.project_image
                          }
                          borderRadius={"20px"}
                        />
                      </Box>

                      <Box py={"10px"}>
                        <Text
                          lineHeight={"24px"}
                          fontSize={"18px"}
                          fontWeight={"400"}
                          color={"#1f0733"}
                        >
                          {" "}
                          {ele.basic.title}
                        </Text>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#1f0733"}
                          mt={"15px"}
                        >
                          STARTING AT
                        </Text>
                        <Text
                          fontSize={"24px"}
                          fontWeight={"500"}
                          color={"#1f0733"}
                          mt={""}
                        >
                          {ele.basic.funding_goal_type}
                          {ele.basic?.funding_goal_amount?.toLocaleString()}
                        </Text>
                        <Progress
                          mt={"15px"}
                          colorScheme="orange"
                          value={ele?.funded_percentage?.toFixed(2)}
                          max={100}
                          className="films-grid-progress"
                        />
                        <Flex mt={"5px"} alignItems={"center"} gap={"15px"}>
                          <Text
                            fontSize={"14px"}
                            fontWeight={""}
                            color={"#1f0733"}
                          >
                            ${ele.fund_raised?.toLocaleString()} raised
                          </Text>
                          <Text
                            fontSize={"14px"}
                            fontWeight={""}
                            color={"#1f0733"}
                            mt={""}
                          >
                            {ele.funded_percentage?.toFixed(2)}% funded
                          </Text>
                        </Flex>
                        <Text
                          fontSize={"14px"}
                          fontWeight={""}
                          color={""}
                          mt={"10px"}
                        >
                          {ele.basic.subcategory}
                        </Text>
                      </Box>
                    </Link>
                  </Box>

                </GridItem>
              ))}
            </Grid>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              width="100%"
              bg="white.100"
              borderRadius="md"
              m={5}
              boxShadow="md"
            >
              Couldn't find any campaign.
            </Box>
          )}


        </Container>
      </Box>

      <Footer logoLevel={"2"} />
    </Box>
  );
};

export default FilmsDetailsComponent;
