import { Box, Spinner } from "@chakra-ui/react";

const LoadingBox = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="200px"
    width="100%"
    bg="white.100"
    borderRadius="md"
    boxShadow="md"
  >
    <Spinner size="xl" color="orange.400" />
  </Box>
);

export default LoadingBox;
