import { Avatar, Box, Button, Divider, Flex, Grid, GridItem, Input, Text, useToast } from "@chakra-ui/react";
import React from "react";
import useGetUser from "../../../../../libs/useGetUser";

const People = ({ project, handleNextClick }) => {

  const { user } = useGetUser();
  

  return (
    <Box textAlign={"start"}>
      <Box py={"50px"}>
        <Text textAlign={"center"} fontSize={"30px"}>
          Introduce yourself
        </Text>
        <Text
          mt={"15px"}
          textAlign={"center"}
          fontSize={"20px"}
          color={"#656969"}
        >
          Give backers an idea of who you are, and add collaborators if you work with a team.
        </Text>
      </Box>

      <Divider my={"30px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />

      <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Your profile</Text>
            <Text w={"400px"} mt={"15px"} color={"#656969"} fontSize={""}>
              This will appear on your project page and must include your name, photo, and biography.

            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          {user?.biography ? <Box border={"1px solid rgba(0,0,0,0.1)"} p={"20px"}>
            <Flex>
              <Avatar size="md" name={user?.name} src={process.env.REACT_APP_API_BASE_URL + user?.avatar} borderRadius="50%" />
              <Box ml={4}>
                <Text fontWeight={"500"} fontSize={"20px"}>{user?.name}</Text>
                <Text w={""} mt={"5px"} color={"#656969"} fontSize={""}>
                  {user?.biography}
                </Text>
              </Box>
            </Flex>
            <Divider my={"30px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />
            <Button
              mt={"10px"}
              w={"100%"}
              colorScheme="orange"
              fontSize={"14px"}
              onClick={() => 
              {
                const url = "/settings?tab=Edit%20Profile";
                const fullUrl = `${window.location.origin}${url}`;
                window.open(fullUrl, '_blank');
              }
              }
              fontWeight={500}

            >
              Edit your profile
            </Button>
          </Box>
            :
            <Box border={"1px solid rgba(0,0,0,0.1)"} p={"20px"}>
              <Text fontWeight={"500"} fontSize={"20px"}>
                User
              </Text>
              <Text w={""} mt={"5px"} color={"#656969"} fontSize={""}>
                Project creator
              </Text>
              <Divider my={"30px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />
              <Button
                mt={"10px"}
                w={"100%"}
                colorScheme="orange"
                fontSize={"14px"}
                fontWeight={500}

                onClick={() => {
                  const url = "/settings?tab=Edit%20Profile";
                  const fullUrl = `${window.location.origin}${url}`;
                  window.open(fullUrl, '_blank');
                }}
              >
                Complete your profile
              </Button>
            </Box>
          }
        </GridItem>
      </Grid>

      <Divider my={"60px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />


      <Grid mt={""} templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Vanity URL</Text>
            <Text w={"400px"} mt={"15px"} color={"#656969"} fontSize={""}>
              Create a custom URL for your profile page with at least three
              characters. This will also be the beginning of your project
              URL—we’ll generate that later.
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>

            <Text w={""} mt={"5px"} color={""} fontSize={""}>
              https://gorillafunder.com/profile/
            </Text>
            <Input
              mt={"10px"}
              type="text"
              placeholder="Digital photo"
              defaultValue={user?.vanity_url}
              size="md"
            />
            <Text w={""} mt={"5px"} color={"green.500"} fontSize={""}>
              You can change your vanity URL from your profile settings.
            </Text>
            <Button
              mt={"20px"}
              w={"100%"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}
              onClick={() => {
                const url = "/settings?tab=Edit%20Profile";
                const fullUrl = `${window.location.origin}${url}`;
                window.open(fullUrl, '_blank');
              }
              }
            >
              Change Now
            </Button>
          </Box>
        </GridItem>
      </Grid>



      <Divider my={"60px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />


      <Grid mt={""} templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Collaborators (optional)</Text>
            <Text w={"400px"} mt={"15px"} color={"#656969"} fontSize={""}>
              If you're working with others, you can grant them permission to edit this project, communicate with backers, and coordinate reward fulfillment.
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Button
              mt={"20px"}
              w={"100%"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}

            >
              Add your first collaborator
            </Button>
          </Box>
        </GridItem>
      </Grid>




      <Divider my={"60px"} h={"1px"} bg={"rgba(0,0,0,0.2)"} />


      <Grid mt={""} templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>
            <Text fontSize={"20px"}>Demographics (optional)</Text>
            <Text w={"400px"} mt={"15px"} color={"#656969"} fontSize={""}>
              Your information helps us equitably support a diverse creator community. We’ll only use it in connection with our anti-discrimination research and work.
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box>



            <Button
              mt={"20px"}
              w={"100%"}
              colorScheme="orange"
              fontSize={"14px"}
              fontWeight={500}

            >
              Go to questions
            </Button>
          </Box>
        </GridItem>
      </Grid>


      <Flex justifyContent={"end"}>
        <Button
          mt={"30px"}
          colorScheme="orange"
          fontSize={"14px"}
          fontWeight={500}
          onClick={() => {
            handleNextClick();
            window.scrollTo(0, 0);
          }}
        >
          Save & Continue
        </Button>
      </Flex>

    </Box>
  );
};

export default People;
