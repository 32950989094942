import { VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import Footer from "../../../Components/Footer";
import AdminAllProjectsComponent from "../../../Components/AdminComponents/AllProjectsComponent";

const AdminAllProjects = () => {


	return (
		<>
			<VStack spacing={"auto"}>
				<Navbar />
				<AdminAllProjectsComponent />
			</VStack>
			<Footer />
		</>
	);
};

export default AdminAllProjects;
