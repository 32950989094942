// src/hooks/useGetUser.js
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../Context/AuthContextProvider';
import axiosInstance from './axiosService';

const useGetUser = () => {
    const { user, setUser } = useContext(AppContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('gf-token');
        if (!user && token) {
            const fetchUser = async () => {
                try {
                    const response = await axiosInstance.get('/user', {
                        headers: { Authorization: `${token}` }
                    });
                    const user = response.data.value;
                    setUser(user);

                } catch (error) {

                } finally {
                    setLoading(false);
                }
            };
            fetchUser();
        } else {
            setLoading(false);
        }
    }, [user, setUser]);

    return { user, loading };
};

export default useGetUser;
