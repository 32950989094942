import { useEffect, useState } from "react";
import { Flex, Heading, Text, Image, Box, Button, VStack, Grid, GridItem } from "@chakra-ui/react";
import axiosInstance from "../libs/axiosService";
import { useNavigate } from "react-router-dom";
import LoadingBox from "./shared/Loading";
import useGetUser from "../libs/useGetUser";

const MyProjectsComponent = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const navigate = useNavigate();
  const { user } = useGetUser();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/user-project-lists/" + user._id);
        setProjects(response.data.projects);

        const statsResponse = await axiosInstance.get("/user-stats");
        setStats(statsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleEdit = (projectId) => {
    navigate(`/edit/${projectId}`);
  };


  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Box mb={5} mt={2}>
      <Heading as="h2" size="lg" p="3vh">
        Track Your Projects
      </Heading>
      <Text className="sub_headings">
        Here you can see the list of projects you have created.
      </Text>
      <Flex flexWrap="wrap" justifyContent="center" sx={{ marginBottom: "20px" }}>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {stats.total_projects || 0}
          </Heading>
          <Text>your projects</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${stats.total_amount_raised?.toLocaleString() || 0}
          </Heading>
          <Text>total fund-raised</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {stats.total_pledges || 0}
          </Heading>
          <Text>total pledges</Text>
        </Flex>
      </Flex>
      <VStack spacing={4}>
        {projects.map((project) => (
          <Box
            key={project._id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            w={"90%"}
            p={5}
            m={2}
          >

            <Grid templateColumns="repeat(7, 1fr)" gap={6} alignItems="center" textAlign={"left"}>
              <GridItem colSpan={[7, 1]}>
                <Image
                  borderRadius={8}
                  src={process.env.REACT_APP_API_BASE_URL + project.basic.project_image}
                  alt={project.basic.title}
                  boxSize="100px"
                  objectFit="cover"
                />
              </GridItem>
              <GridItem colSpan={[7, 2]}>
                <Text><strong>Title:</strong> {project.basic.title}</Text>
                <Text><strong>Subcategory:</strong> {project.basic.subcategory}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Funding Goal:</strong> ${project.basic.funding_goal_amount?.toLocaleString()}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Fund Raised:</strong> ${project.fund_raised?.toLocaleString() || "0"}</Text>
              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Text><strong>Status:</strong> {project.published ? <span className="published">Published</span> :
                  <span className="unpublished">UnPublished</span>}</Text>

              </GridItem>
              <GridItem colSpan={[7, 1]}>
                <Box >
                  <Button colorScheme="teal" size="sm" onClick={() => handleEdit(project._id)}>
                    Edit
                  </Button>

                  <Button colorScheme="green" size="sm" ml={2} onClick={() =>
                    navigate(`/project/${project._id}`)
                  }>
                    Details
                  </Button>
                </Box>
              </GridItem>
            </Grid>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default MyProjectsComponent;
