import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomNotepad = ({ handleChange, project }) => {
  const [value, setValue] = useState(project?.story?.story_content || '');

  useEffect(() => {
    if (value) {
      handleChange(null, "story.story_content", value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {

    if (project.story && project.story.story_content) {
      setValue(project.story.story_content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  // Define the modules for the Quill editor, including the extended toolbar
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']   // remove formatting button
    ],
  };

  // Define the formats supported by the Quill editor
  const formats = [
    'header', 'font', 'list', 'bullet',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'color', 'background', 'align',
    'link', 'image', 'video', 'clean'
  ];

  return (
    <div style={{ width: '100%' }}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        style={{ width: '100%' }}
      />
    </div>
  );
}

export default CustomNotepad;
