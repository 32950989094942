import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Image } from "@chakra-ui/react";

function SimpleSlider() {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    pauseOnHover: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };

  return (
    <Box className="slider-container" overflow={"hidden"}>
      <Slider ref={sliderRef} {...settings}>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/friends-watching-movie-together-2023-11-27-04-54-24-utc-min.jpg"
            alt="Slide 1"
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0, 0, 0, 0.6)"
          />
        </Box>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/rear-view-of-videographer-and-his-assistant-adjust-2023-11-27-05-14-25-utc-min.jpg"
            alt="Slide 2"
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0, 0, 0, 0.6)"
          />
        </Box>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/selective-focus-of-art-director-touching-glasses-a-2023-11-27-04-59-35-utc-min.jpg"
            alt="Slide 3"
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0, 0, 0, 0.6)"
          />
        </Box>
        <Box h={"95vh"} position={"relative"}>
          <Image
            w={"100%"}
            h={"100%"}
            objectFit={"cover"}
            src="/images/movies/screen-of-video-camera-with-part-of-face-of-fashio-2023-11-27-05-33-35-utc-min.jpg"
            alt="Slide 4"
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="rgba(0, 0, 0, 0.6)"
          />
        </Box>
      </Slider>
    </Box>
  );
}

export default SimpleSlider;
