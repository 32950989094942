import { Box, Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useGetUser from "../../../../libs/useGetUser";
import axiosInstance from "../../../../libs/axiosService";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../../../Components/shared/Loading";

const MyProfile = () => {
  const { user } = useGetUser();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/user-project-lists/" + user._id);
        setProjects(response.data.projects);

        const statsResponse = await axiosInstance.get("/user-stats");
        setStats(statsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleEdit = (projectId) => {
    navigate(`/edit/${projectId}`);
  };
  console.log("projectssss", projects)
  console.log("statsssssss", stats)

  if (loading) {
    return <LoadingBox />;
  }





  console.log("userrrrrrrr", user)
  return (
    <>
      <Grid mt={"40px"} templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Box bg={"#F5F5F5"} w={{ base: "100%", lg: "460px" }} h={"285px"}>
            <Image
              w={"100%"}
              h={"100%"}
              objectFit={"cover"}
              src={process.env.REACT_APP_API_BASE_URL + user?.avatar}
            />
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: "1fr" }} display={"flex"} justifyContent={{ base: "start", lg: "end" }} >
          <Box>
            <Flex mt={{ base: "20px", lg: "" }} alignItems={"center"} gap={"20px"}>
              <Box w={"60px"} h={"60px"}>
                <Image
                  w={"100%"}
                  h={"100%"}
                  borderRadius={"50%"}
                  objectFit={"cover"}
                  src={process.env.REACT_APP_API_BASE_URL + user?.avatar}
                />
              </Box>
              <Text fontSize={"24px"} fontWeight={"500"} color={"#6A6A6A"}>
                About Me
              </Text>
            </Flex>

            <Box mt={"20px"}>

              <Flex alignItems={"center"} gap={"5px"}>
                <Text fontSize={"24px"} fontWeight={"600"} color={""}>
                  {stats?.total_projects || "0"}
                </Text>
                <Text fontSize={"16px"} fontWeight={"500"} color={"#6A6A6A"}>
                  Campaigns
                </Text>
              </Flex>

              <Flex alignItems={"center"} gap={"5px"}>
                <Text fontSize={"24px"} fontWeight={"600"} color={""}>
                  {stats?.total_pledges}
                </Text>
                <Text fontSize={"16px"} fontWeight={"500"} color={"#6A6A6A"}>
                  Contributions{" "}
                </Text>
              </Flex>

              <Flex alignItems={"center"} gap={"5px"}>
                <Text fontSize={"24px"} fontWeight={"600"} color={""}>
                  ${stats?.total_amount_raised?.toLocaleString() || "0"}
                </Text>
                <Text fontSize={"16px"} fontWeight={"500"} color={"#6A6A6A"}>
                  Amount Raised
                </Text>
              </Flex>
            </Box>

            <Box textAlign={"left"} mt={"20px"}>
              <Text color={"#6A6A6A"} fontSize={"24px"}>
                Verifications
              </Text>
            </Box>
            <Box h={"2px"} my={"10px"} bg={"#F5F5F5"} />

            {
              user?.is_auth ? (
                <Flex alignItems={"center"} p={"10px"} textAlign={"left"} w={{ base: "100%", lg: "380px" }} h={"80px"} border={"10px solid #9AE6B4"}>
                  <Text color={"#6A6A6A"} fontWeight={"500"} fontSize={"16px"}>
                    This person is verified.
                  </Text>
                </Flex>
              )
                :
                (
                  <Flex alignItems={"center"} p={"10px"} textAlign={"left"} w={{ base: "100%", lg: "380px" }} h={"97px"} border={"10px solid #F5F5F5"}>
                    <Text color={"#6A6A6A"} fontWeight={"500"} fontSize={"16px"}>
                      This person has not verified their email address yet.
                    </Text>
                  </Flex>
                )
            }



          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

export default MyProfile;
