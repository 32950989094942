import React from "react";
import {
  Box,
  Grid,
  Text,
  Button,
  GridItem,
  useToast,
  Image,
  Flex,
  Tooltip,
} from "@chakra-ui/react";

import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import PerksComponent from "../perksComponent";
import { FaStar } from "react-icons/fa";

const Campaign = ({ filmDetails }) => {
  const [bonus, setBonus] = React.useState(0);
  const navigate = useNavigate();

  const { id } = useParams();
  const toast = useToast();

  // Function to extract and render headings
  const renderHeadings = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    const headings = doc.querySelectorAll("h1, h2, h3, h4, h5, h6");

    return Array.from(headings).map((heading, index) => {
      return (
        <Text
          key={index}
          fontWeight="bold"
          // fontSize={size}
          mt="10px"
          pl="10px"
          cursor="pointer"
          borderLeft="5px solid orange"
        >
          {heading.textContent}
        </Text>
      );
    });
  };

  return (
    <>
      {/* <Container maxW="container.2xl" py={{ base: 8, md: 12 }}> */}
      <Grid
        // px={"140px"}
        mt={"30px"}
        width="100%"
        templateColumns={{ base: "1fr", lg: "2fr 1fr" }}
      >
        <GridItem order={{ base: 2, lg: 1 }} colSpan={{ base: "1fr" }}>
          <Box mt={"5px"} p={"1.5rem"} textAlign={"start"} border={"1px solid #c8c8c8"}>
            <Text fontSize={"18px"} fontWeight={"600"} color={"#1f0733"}>
              Prototype
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={"400"}
              mt={"10px"}
              color={"#1f0733"}
            >
              The project team has a working demo, not the final product. Their
              ability to begin production may be affected by product development
              or financial challenges.
            </Text>
            <Text
              fontSize={"18px"}
              fontWeight={"600"}
              color={"#1f0733"}
              mt={"20px"}
            >
              Highlights
            </Text>
            <Flex mt={"10px"} alignItems={"center"} gap={"20px"}>
              <Tooltip
                px={"40px"}
                textAlign={""}
                py={"20px"}
                sx={{ bg: "#E6E6E7" }}
                color={"#1f0733"}
                label={
                  <>
                    <Text>Campaigns we're loving this week</Text>
                    <Text mt={"10px"} fontWeight={"600"}>
                      Browse the collection
                    </Text>
                  </>
                }
                placement="bottom"
              >
                <Flex
                  _hover={{ backgroundColor: "#1f0733", color: "white" }}
                  color={"#1f0733"}
                  alignItems={"center"}
                  gap={"10px"}
                  bg={"#D9D9D9"}
                  borderRadius={"18px"}
                  px={"10px"}
                  py={"5px"}
                  border={"1px solid #e6e6e7"}
                >
                  <FaStar />
                  <Text fontSize={"14px"} fontWeight={""}>
                    GorillaFunder Team Favorites
                  </Text>
                </Flex>
              </Tooltip>
              {/* <Tooltip
                px={"40px"}
                textAlign={""}
                py={"20px"}
                sx={{ bg: "#E6E6E7" }}
                color={"#1f0733"}
                minW={{base:"100%",lg:"400px"}}
                label={
                  <>
                    <Flex justifyContent={"space-between"} alignItems={"center"}>
                      <Box>
                        <Text fontSize={"18px"}>4</Text>
                        <Text fontSize={"18px"} mt={"10px"} fontWeight={"600"}>
                          Projects
                        </Text>
                      </Box>
                      <Box>
                        <Text fontSize={"18px"} fontWeight={""}>
                          $4,007,840 USD
                        </Text>
                        <Text mt={"10px"}  fontSize={"18px"} fontWeight={""}>
                          Total Funds Raised
                        </Text>
                      </Box>
                    </Flex>

                    <Text mt={"15px"} fontWeight={"600"}>
                    VIEW PAST PROJECTS
                    </Text>

                  </>
                }
                placement="bottom"
              >
                <Flex
                  _hover={{ backgroundColor: "#1f0733", color: "white" }}
                  color={"#1f0733"}
                  alignItems={"center"}
                  gap={"10px"}
                  bg={"#D9D9D9"}
                  borderRadius={"18px"}
                  pl={"40px"}
                  pr={"10px"}
                  py={"5px"}
                  border={"1px solid #e6e6e7"}
                >
                  <Text fontSize={"14px"} fontWeight={""}>
                    4 Projects
                  </Text>
                </Flex>
              </Tooltip> */}
            </Flex>
          </Box>

          <Box
            // maxH={"100vh"}
            // overflowY="auto"
            // mr={"50px"}
            textAlign={"justify"}
            bg={""}
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
                background:
                  "transparent" /* Optional: background color of the scrollbar track */,
              },
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: filmDetails?.story?.story_content,
              }}
            ></div>
          </Box>
        </GridItem>
        <GridItem order={{ base: 1, lg: 2 }} colSpan={{ base: "1fr" }}>
          <Box>
            <PerksComponent filmDetails={filmDetails} />
          </Box>
        </GridItem>
      </Grid>
      {/* </Container> */}
    </>
  );
};

export default Campaign;
