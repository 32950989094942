import { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Select
} from "@chakra-ui/react";
import axiosInstance from "../../libs/axiosService";
import { useNavigate, useParams } from "react-router-dom";
import { formatTimestamp } from "../../libs/functions";
import LoadingBox from "../shared/Loading";

const AllApprovalRequests = () => {
  const [loading, setLoading] = useState(true);
  const [withdraws, setWithdraws] = useState([]);
  const [withdrawStatus, setWithdrawStatus] = useState(""); // Add this line
  const [requestDescription, setRequestDescription] = useState(""); // Add this line
  const navigate = useNavigate();
  const { isOpen: isWithdrawOpen, onOpen: onWithdrawOpen, onClose: onWithdrawClose } = useDisclosure();
 
  const [withDrawDetails, setWithdrawDetails] = useState({});
  const { id } = useParams();
  const toast = useToast();
  useEffect(() => {

    fetchWithdrawRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchWithdrawRequest = async () => {
    try {
      const response = await axiosInstance.get("/requested-projects");
      const withdrawData = response.data.projects;
      console.log("withdrawData", withdrawData);
      setLoading(false);
      setWithdraws(withdrawData);

    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false);
    }
  };


  const submitWithdrawRequest = async () => {
    try {
      const response = await axiosInstance.put("/update-project/" + withDrawDetails?._id, {
        approval: withdrawStatus,
        // response_message: requestDescription
      });

      if (response.status === 200) {
        fetchWithdrawRequest();
        setWithdrawStatus("");
        setRequestDescription("");
        onWithdrawClose();
      }
    } catch (error) {
      console.error("Error submitting withdraw request:", error);
      toast({
        title: "Error",
        description: error.response.data || "Failed to submit withdraw request.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  };



  if (loading) {
    return <LoadingBox />;
  }

  return (
    <>


      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" w={"100%"} p={5} m="auto">

        <Table variant="simple" mt={3}>
          <TableCaption color={"#e98429"} fontWeight={600}>
            All Approval Requests
          </TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>User</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdraws.map((withdraw) => (
              <Tr key={withdraw._id}>
                <Td>{withdraw?.basic?.title}</Td>
                <Td>{
                  withdraw.approval === "approved"
                    ? <span className="published">
                      Approved
                    </span>
                    :
                    withdraw.approval === "rejected"
                      ? <span className="unpublished">
                        Rejected
                      </span>
                      :
                      withdraw.approval === "requested"
                        ? <span className="onholdStatus">
                          Requested
                        </span>
                        :
                        <span className="pendingStatus">
                          Pending
                        </span>
                }</Td>
                <Td>{withdraw?.people?.user?.name}</Td>
           
                <Td>
                  
                  <Button colorScheme="orange" size={"xs"}
                    onClick={() => {
                      onWithdrawOpen();
                      setWithdrawStatus(withdraw.approval_status);
                      setRequestDescription(withdraw.response_message);
                      setWithdrawDetails(withdraw);
                    }}
                  >Response</Button>

                  <Button colorScheme="blue" size={"xs"} ml={2}
                    onClick={() => navigate(`/film/${withdraw._id}`)}
                  >View Details</Button>

                </Td>


              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>


      {/* Modal for submitting withdraw request */}
      <Modal isOpen={isWithdrawOpen} onClose={onWithdrawClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reponse to Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>


            <FormControl>
              <FormLabel>Select Status</FormLabel>
              <Select placeholder="Select the status"
                value={withdrawStatus}
                onChange={(e) => {
                  setWithdrawStatus(e.target.value);
                }}
              >
                <option value={"pending"}>Pending</option>
                <option value={"approved"}>Approved</option>
                <option value={"rejected"}>Rejected</option>

              </Select>

            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Response Message</FormLabel>
              <Textarea placeholder="Enter the description"
                value={requestDescription}
                onChange={(e) => {
                  setRequestDescription(e.target.value);
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={submitWithdrawRequest}>
              Submit
            </Button>
            <Button onClick={onWithdrawClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>



    </>
  );
};

export default AllApprovalRequests;
