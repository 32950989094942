import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  VStack,
  useToast,
  Grid,
  GridItem,
  Image,
  Flex,
} from '@chakra-ui/react';
import { AppContext } from '../Context/AuthContextProvider';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';
import { login } from '../libs/authService';

import Login2 from './Login2';

const Login = () => {
  const context = useContext(AppContext);
  const { isAuth, login: loginContext } = context;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { user, user: { token } } = await login(email, password);
      loginContext(token, user);
      toast({
        title: 'Login successful.',
        description: "You've successfully logged in.",
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Login failed.',
        description: error.response?.data || 'An error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const navigate = useNavigate();
  const location = useLocation();
  const afterAuth = () => {

    if (context.user.role === 'admin') {
      // return <Navigate to="/admin/dashboard" />;
      return <Navigate to="/all-projects" />;
    }

    if (location.state?.from?.pathname) {
      localStorage.setItem('gf-token', context.token);

      // Redirect to the path the user was trying to access, or to home page if not available
      const from = location.state?.from?.pathname + location.state?.from?.search || '/';
      navigate(from, { replace: true });
      return <></>
    }
    else {
      return <Navigate to="/" />;
    }

  }


  return (
    // <>
    //   {isAuth ? (
    //     // <Navigate to="/" />
    //     afterAuth()
    //   ) : (
    //     <>
    //       <Navbar />
    //     <Grid templateColumns={{base:"1fr",lg:"1fr 1fr"}} h={""}>
    //     <GridItem  colSpan={{ base: "1fr" }} bg={""} overflow={"hidden"}>
    //           <Box  transform={"rotate(5deg)"} mt={""} w={""}  bg={""} p={{base:"",lg:"10px"}} >

    //             <Image
    //               src="../../images/movies/authSideImage.jpg"
    //               height={"800px"}
    //               width={"100%"}

    //             objectPosition={"center"}
    //             objectFit={"cover"}
    //             />
    //           </Box>
    //           </GridItem>
    //         <GridItem colSpan={{base:'1fr'}} display={"flex"} alignItems={"center"}>
    //         <Box
    //         w={['full', 'full']}
    //         p={[8, 10]}
    //         // marginBottom="10vh"
    //         // mt={[20, '10vh']}
    //         // mx="auto"
    //         // border={['none', '1px']}
    //         // borderColor={['', 'gray.300']}
    //         // borderRadius={10}
    //       >
    //         <VStack spacing={4} align="flex-start" w="full">
    //           <VStack spacing={4} align={['flex-start', 'center']} w="full" mb={3}>
    //             <Heading size="lg">Login</Heading>

    //             <Text>
    //               New to GorillaFunder?{' '}
    //               <Button color="#e98429" variant="link">
    //                 <Link to="/signup">Signup</Link>
    //               </Button>
    //             </Text>
    //           </VStack>
    //         </VStack>
    //         <FormControl padding={'2vh 0'}>
    //           <FormLabel>E-mail Address</FormLabel>
    //           <Input
    //             rounded={10}
    //             variant="filled"
    //             name="email"
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //           />
    //         </FormControl>
    //         <FormControl padding={'1vh 0'}>
    //           <FormLabel>Password</FormLabel>
    //           <Input
    //             rounded={10}
    //             variant="filled"
    //             type="password"
    //             name="password"
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //           />
    //         </FormControl>
    //         <HStack w="full" justify={'space-between'} padding={'2vh 0 3vh'}>
    //           <Checkbox>Remember me.</Checkbox>
    //           <Link to="/forgot-password">
    //             <Button variant={'link'} color="#e98429">
    //               Forget Password?
    //             </Button>
    //           </Link>
    //         </HStack>
    //         <Button
    //           rounded={10}
    //           sx={{ bg: '#e98429', color: 'white' }}
    //           w={'full'}
    //           padding={'1vh 5vw'}
    //           onClick={handleSubmit}
    //           disabled={email === '' || password === ''}
    //         >
    //           Login
    //         </Button>
    //       </Box>
    //         </GridItem>
    //     </Grid>
    //       <Footer />
    //     </>
    //   )}
    // </>
    <>


      {isAuth ? (

        afterAuth()


      ) : (
        <>
          <Navbar />
          <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} p={"20px"} h={"80vh"}>
            <GridItem colSpan={{ base: "1fr" }} overflow={"hidden"}>
              <Box transform={"rotate(5deg)"} mt={""} >

                <Image
                  src="../../images/movies/authSideImage.jpg"
                  height={"800px"}
                  width={"100%"}
                  // objectPosition={"contain"}
                  objectFit={"cover"}
                />
              </Box>
            </GridItem>

            <GridItem colSpan={{ base: "1fr" }} h={"80vh"}>
              <Flex alignItems={"center"} px={{ base: "", lg: "20px" }} h={"100%"}>

                <Login2 />
              </Flex>
            </GridItem>
          </Grid>
          <Footer />
        </>
      )
      }
    </>
  );
};

export default Login;
