import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import axiosInstance from "../../libs/axiosService";
import { useEffect } from "react";

export default function SuccessPageComponent() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("redirect_status");

  useEffect(() => {
    const sendData = async () => {
      try {
        const pledgeData = JSON.parse(localStorage.getItem("pledgeData"));
        if (pledgeData) {
          await axiosInstance.post("/pledge", pledgeData);
          localStorage.removeItem("pledgeData");
        }
      } catch (error) {
        console.error("Error sending pledge data:", error);
      }
    };

    if (status === "succeeded") {
      sendData();
    }
  }, [status]);

  return (
    <>
      <Navbar />
      <Center minHeight="50vh" backgroundColor="gray.50" padding="20px">
        <Box
          width={{ base: "90%", md: "60%", lg: "40%" }}
          backgroundColor="white"
          padding="40px"
          borderRadius="md"
          boxShadow="lg"
          textAlign="center"
        >
          <Heading as="h2" size="md" mb="20px">
            Thank you for the pledge 🎉
          </Heading>
          <Text fontSize="lg" mb="40px">
            We will proceed with your payment and send a confirmation.
          </Text>
          <Button as={Link} to="/" colorScheme="orange" size="lg" width="100%">
            Go Back Home
          </Button>
        </Box>
      </Center>
      <Footer />
    </>
  );
}
