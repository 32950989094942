// src/libs/AuthGuard.jsx
import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../Context/AuthContextProvider';
import { useToast } from '@chakra-ui/react';

const AdminAuthGuard = ({ children }) => {
  const location = useLocation();

  const { user } = useContext(AppContext);
  const token = localStorage.getItem('gf-token');

  const toast = useToast();

  if (token && user && user.role === 'admin') {
    return children;
  } else {
    toast({
      title: 'Unauthorized',
      description: 'You are not authorized to access this page.',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
    // Save the path the user was trying to access
    return <Navigate to="/login" state={{ from: location }} />;
  }
};

export default AdminAuthGuard;
