import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../../Components/Navbar";
import Footer from "../../../Components/Footer";
import AllApprovalRequests from "../../../Components/AdminComponents/AllApprovalRequests";

const AdminApprovalRequests = () => {


    return (
        <>
        <VStack spacing={"auto"}>
            <Navbar logoLevel="2" />
            <Box mb={5} mt={2} w={"90%"}>
                <Heading as="h2" size="lg" p="3vh">
                    Approval Requests
                </Heading>
                <Text className="sub_headings">
                    Here you can view all the approval requests submitted by creators.
                </Text>
                <AllApprovalRequests />
            </Box>
        </VStack>
        <Footer />
        </>
    );
};

export default AdminApprovalRequests;
