import { Box,  Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../libs/axiosService";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const MyContributions = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/user-pledge-projects");
        setProjects(response.data.projectsPledged);
        console.log("projects", response.data.projectsPledged);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  console.log("backerrrssssssss", projects);

  return (
    <>
      <Box textAlign={"left"} mt={"40px"}>
        <Text color={"#2A2A2A"} fontSize={"24px"} fontWeight={"600"}>
          My Contributions
        </Text>

        <TableContainer mt={"40px"}>
          <Table variant="simple">

            <Thead>
              <Tr>
                <Th>Campaign</Th>
                <Th>Category</Th>
                <Th>Amount Pledged</Th>
                <Th> Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Text>Loading...</Text>
              ) : (
                projects?.map((project) => (
                  <Tr>
                    <Td> {project?.title} </Td>
                    <Td> {project?.subcategory} </Td>
                    <Td>${project?.my_pledge_amount?.toLocaleString()}</Td>
                    <Td
                      onClick={() => navigate(`/film/${project.project_id}`)}
                      color={"blue"}
                      cursor={"pointer"}
                    >
                      View-Detail
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default MyContributions;
