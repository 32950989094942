import { Box, Divider, Grid, GridItem, Text } from "@chakra-ui/react";
import React from "react";

const Community = ({ filmDetails }) => {
  return (
    <>
      <Box py={"100px"}>
        <Text fontSize={"20px"}>{filmDetails?.stats?.total_backers} people are supporting {
          filmDetails?.people?.user?.name
        }</Text>
      </Box>
      <Divider />
      <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
        <GridItem colSpan={{ base: "1fr" }}>
          <Text pt={"50px"} fontSize={"20px"}>
            Where Backers Come From <br /> Top Locations
          </Text>
          <Divider />
          <Box p={"40px"}>

            {
              filmDetails?.stats?.top_locations?.map((location, index) => (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", lg: "row" }}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"15px"}>
                    <Text fontSize={""} color={"blue"}>
                      {location?.location}
                    </Text>

                  </Box>
                  <Text mt={{ base: "5px", lg: "0" }} fontSize={"12px"}>
                    ${location?.amount} fund raised
                  </Text>
                </Box>
              ))
            }


          </Box>
        </GridItem>

        <GridItem colSpan={{ base: "1fr" }}>

          <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} border={"1px solid #DCDEDD"} p={"30px"}>
            <GridItem colSpan={{ base: "1fr" }} pb={{ base: "20px", lg: "0" }} borderBottom={{ base: "2px solid #DCDEDD", lg: "0px solid #000" }} borderRight={{ base: "0px solid #000", lg: "1px solid #DCDEDD" }}>
              <Box>
                <Text fontSize={"15px"} fontWeight={"500"}>New Backers</Text>
                <Text fontSize={"60px"} py={"35px"}>{filmDetails?.stats?.unique_backers_count}</Text>
                <Text>backers had never backed a project on GorillaFunder before</Text>
              </Box>
            </GridItem>
            <GridItem colSpan={{ base: "1fr" }}>
              <Box mt={{ base: "20px", lg: "0" }}>
                <Text fontSize={"15px"} fontWeight={"500"}>Returning Backers</Text>
                <Text fontSize={"60px"} py={"35px"}>{filmDetails?.stats?.repetitive_backers_count}</Text>

                <Text>backers had backed a project on GorillaFunder before</Text>
              </Box>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>


    </>
  );
};

export default Community;
