import { useEffect, useState } from "react";
import {
  Heading,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Flex,
  SimpleGrid
} from "@chakra-ui/react";
import axiosInstance from "../../libs/axiosService";
import {  useParams } from "react-router-dom";
import LoadingBox from "../shared/Loading";

const ProjectDetailsComponent = () => {
  const [projectStats, setProjectStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [backers, setBackers] = useState([]);
  const [topLocations, setTopLocations] = useState([]);
  const { id } = useParams();
  useEffect(() => {

    fetchProjects();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get("/project-stats/" + id);
      const projectData = response.data.stats;

      setProjectStats(projectData);
      setLoading(false);

      setBackers(projectData.backers);

      setTopLocations(projectData.top_locations);


    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false);
    }
  };


  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Box mb={5} mt={2}>
      <Heading as="h2" size="lg" p="3vh">
        Project Details
      </Heading>
      <Text className="sub_headings">
        Here you can view the details of the project.
      </Text>
      <Flex flexWrap="wrap" justifyContent="center" sx={{ marginBottom: "20px" }}>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projectStats?.funding_goal?.toLocaleString() || 0}
          </Heading>
          <Text>Funding Goal</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projectStats?.fund_raised?.toLocaleString() || 0}
          </Heading>
          <Text>Fund Raised</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projectStats?.available_balance?.toLocaleString() || 0}
          </Heading>
          <Text>Available Balance</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {projectStats?.total_pledges || 0}
          </Heading>
          <Text>Total Pledges</Text>
        </Flex>
      </Flex>

      <SimpleGrid columns={[1, 1, 2]} spacing={10} justifyContent="center" sx={{ marginBottom: "20px", marginTop: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5}>
          <Heading as="h3" size="md" mb={2}>
            Total Backers ({projectStats?.total_backers || 0})
          </Heading>
          <Table variant="simple">
            <TableCaption color={"#e98429"} fontWeight={600}>Backers of the project</TableCaption>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Amount</Th>
                <Th>Pledge</Th>
              </Tr>
            </Thead>
            <Tbody>
              {backers.map((backer, i) => (
                <Tr key={i + 1123}>
                  <Td>{backer.name}</Td>
                  <Td>{backer.email}</Td>
                  <Td>${backer.total_amount?.toLocaleString()}</Td>
                  <Td>{backer.times_pledged} Time</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5}>
          <Heading as="h3" size="md" mb={2}>
            Top Locations
          </Heading>
          <Table variant="simple">
            <TableCaption color={"#e98429"} fontWeight={600}>Top locations of the project</TableCaption>
            <Thead>
              <Tr>
                <Th>Location</Th>
                <Th>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {topLocations.map((location, i) => (
                <Tr key={i}>
                  <Td>{location.location}</Td>
                  <Td>${location.amount?.toLocaleString()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </SimpleGrid>



    </Box>
  );
};

export default ProjectDetailsComponent;
