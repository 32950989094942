import { VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import MyBacklistComponent from "../../Components/MyBacklistComponent";

const MyBackList = () => {


	return (
		<>
			<VStack spacing={"auto"}>
				<Navbar />
				<MyBacklistComponent />
			</VStack>
			<Footer />
		</>
	);
};

export default MyBackList;
