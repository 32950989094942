import React, { useEffect, useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Box, Checkbox, Spinner, Text } from "@chakra-ui/react";

export default function CheckoutForm({ filmDetails, pricing, user }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    
    const pledgeData = {
      project_id: filmDetails?._id,
      amount: pricing,
      user_id: user?._id,
    };

    localStorage.setItem("pledgeData", JSON.stringify(pledgeData));

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/success`
      }
    });


    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  };


  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Box mt={4} textAlign={"left"}>

        <Checkbox mb={3}>Remember this payment method</Checkbox>
        <Box
          sx={{
            background: "url('/images/black_brush_stroke_background.avif') no-repeat 15px 50%",
            color: "white",
            fontWeight: "600",
          }}
          p={4} borderRadius="md" mb={3}>
          <Text ml={20}>Rewards aren't guaranteed.</Text>
        </Box>
        <Checkbox mb={3}>I understand that rewards or reimbursements aren’t guaranteed by either GorillaFunder or the creator.</Checkbox>


        <button disabled={isLoading || !stripe || !elements} id="submit" style={{
          background: "#DD6B20",
          color: "white",
          padding: "10px 0px",
          fontWeight: "600",
          borderRadius: "6px",
          marginTop: "5px",
          width: "100%",
        }}>
          <span id="button-text">
            {isLoading ? <Spinner color='white.900' /> : "Pay now"}
          </span>
        </button>
      </Box>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}


