import { Heading, StackDivider, VStack } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import FilmsGrid from "./filmGrid";

const FilmsPage = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const category = queryParams.get("category");
	const decodedCategory = decodeURIComponent(category);

	return (
		<>
		<VStack divider={<StackDivider borderColor="gray.200" />} spacing={"auto"}>
			<Navbar />
			<Heading sx={{
				fontSize: "24px",
				padding: "30px",
				fontWeight: 500
			}}>
				Creative Films filtered by <span style={{ color: "orange" }}>{decodedCategory}</span>
			</Heading>
			<FilmsGrid category={decodedCategory} />
		</VStack>
			<Footer />
		</>
	);
};

export default FilmsPage;
