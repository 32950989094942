import {  VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import MyProjectsComponent from "../../Components/MyProjectsComponent";

const MyProjects = () => {
	

	return (
		<>
		<VStack spacing={"auto"}>
			<Navbar />
				<MyProjectsComponent />
		</VStack>
			<Footer  />
		</>
	);
};

export default MyProjects;
