import { useEffect, useState } from "react";
import {
  Heading,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Flex,
  SimpleGrid,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast
} from "@chakra-ui/react";
import axiosInstance from "../libs/axiosService";
import { useNavigate, useParams } from "react-router-dom";
import { formatTimestamp } from "../libs/functions";
import LoadingBox from "./shared/Loading";

const MyProjectStatsComponent = () => {
  const [projectStats, setProjectStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [backers, setBackers] = useState([]);
  const [topLocations, setTopLocations] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [withdraws, setWithdraws] = useState([]);
  const [withdrawAmount, setWithdrawAmount] = useState(0); // Add this line
  const [requestDescription, setRequestDescription] = useState(""); // Add this line
  const navigate = useNavigate();
  const { isOpen: isWithdrawOpen, onOpen: onWithdrawOpen, onClose: onWithdrawClose } = useDisclosure();
  const { isOpen: isFaqOpen, onOpen: onFaqOpen, onClose: onFaqClose } = useDisclosure();
  const { isOpen: isWithdrawDetailOpen, onOpen: onWithdrawDetailOpen, onClose: onWithdrawDetailClose } = useDisclosure();
  const [withDrawDetails, setWithdrawDetails] = useState({});
  const [selectedFaq, setSelectedFaq] = useState({});
  const { id } = useParams();
  const toast = useToast();
  useEffect(() => {

    fetchProjects();
    fetchWithdrawRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get("/project-stats/" + id);
      const projectData = response.data.stats;

      setProjectStats(projectData);
      setLoading(false);

      setBackers(projectData.backers);

      setTopLocations(projectData.top_locations);

      const faqsResponse = await axiosInstance.get("/faqs/" + id);
      setFaqs(faqsResponse.data.faqs);

    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false);
    }
  };

  const fetchWithdrawRequest = async () => {
    try {
      const response = await axiosInstance.get("/withdraws/" + id);
      const withdrawData = response.data.withdraws;

      setWithdraws(withdrawData);

    } catch (error) {
      console.error("Error fetching projects:", error);
      setLoading(false);
    }
  };

  const submitFaqAnswer = async () => {
    try {
      const response = await axiosInstance.put("/faq/" + selectedFaq._id, {
        answer: selectedFaq.answer,
        status: "replied"
      });

      if (response.status === 200) {
        setFaqs(faqs.map(faq => {
          if (faq._id === selectedFaq._id) {
            return { ...faq, answer: selectedFaq.answer, status: "replied" };
          }
          return faq;
        }));

        onFaqClose();
      }
    } catch (error) {
      console.error("Error submitting faq answer:", error);
      toast({
        title: "Error",
        description: "Failed to submit the answer.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  };

  const submitWithdrawRequest = async () => {
    try {
      const response = await axiosInstance.post("/withdraw", {
        project_id: id,
        amount: withdrawAmount,
        request_message: requestDescription
      });

      if (response.status === 200) {
        fetchWithdrawRequest();
        setWithdrawAmount(0);
        setRequestDescription("");
        onWithdrawClose();
        fetchProjects();
      }
    } catch (error) {
      console.error("Error submitting withdraw request:", error);
      toast({
        title: "Error",
        description: error.response.data || "Failed to submit withdraw request.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  };

  const onWithdrawDetailCloseFtn = () => {
    onWithdrawDetailClose();
    setWithdrawDetails({});
  };

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Box mb={5} mt={2}>
      <Heading as="h2" size="lg" p="3vh">
        Project Dashboard
      </Heading>
      <Text className="sub_headings">
        Here you can see the stats of your project.
      </Text>
      <Flex flexWrap="wrap" justifyContent="center" sx={{ marginBottom: "20px" }}>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projectStats?.funding_goal?.toLocaleString() || 0}
          </Heading>
          <Text>Funding Goal</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projectStats?.fund_raised?.toLocaleString() || 0}
          </Heading>
          <Text>Fund Raised</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projectStats?.available_balance?.toLocaleString() || 0}
          </Heading>
          <Text>Available Balance</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={[5, 10]} m={"auto"} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {projectStats?.total_pledges || 0}
          </Heading>
          <Text>Total Pledges</Text>
        </Flex>
      </Flex>

      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" w={"100%"} p={5} m="auto">
        <Flex position={"relative"}>
          <Heading as="h3" size="md" mb={2}>
            Withdraw Requests
          </Heading>
          <Button
            size={"sm"}
            colorScheme="orange"
            position={"absolute"}
            right={"10px"}
            onClick={onWithdrawOpen}
          >
            Submit Request
          </Button>
        </Flex>
        <Table variant="simple" mt={3}>
          <TableCaption color={"#e98429"} fontWeight={600}>Withdraw Requests That You've Submitted.</TableCaption>
          <Thead>
            <Tr>
              <Th>Requested Amount</Th>
              <Th>Status</Th>
              <Th>Description</Th>
              <Th>Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {withdraws.map((withdraw) => (
              <Tr key={withdraw._id}>
                <Td>${withdraw.amount?.toLocaleString()}</Td>
                <Td>{
                  withdraw.approval_status === "approved"
                    ? <span className="published">
                      Approved
                    </span>
                    :
                    withdraw.approval_status === "rejected"
                      ? <span className="unpublished">
                        Rejected
                      </span>
                      :
                      withdraw.approval_status === "on-hold"
                        ? <span className="onholdStatus">
                          On-Hold
                        </span>
                        :
                        <span className="pendingStatus">
                          Pending
                        </span>
                }</Td>
                <Td>{withdraw.request_message.length > 30
                  ? withdraw.request_message.substring(0, 30) + "..."
                  : withdraw.request_message
                }</Td>
                <Td>{
                  formatTimestamp(withdraw.createdAt)
                }</Td>

                <Td>
                  <Button colorScheme="teal" size={"xs"}
                    onClick={() => {
                      onWithdrawDetailOpen();
                      setWithdrawDetails(withdraw);
                    }}
                  >View Details</Button>
                </Td>


              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <SimpleGrid columns={[1, 1, 2]} spacing={10} justifyContent="center" sx={{ marginBottom: "20px", marginTop: "20px" }}>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5}>
          <Heading as="h3" size="md" mb={2}>
            Total Backers ({projectStats?.total_backers || 0})
          </Heading>
          <Table variant="simple">
            <TableCaption color={"#e98429"} fontWeight={600}>Backers of the project</TableCaption>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Amount</Th>
                <Th>Pledge</Th>
              </Tr>
            </Thead>
            <Tbody>
              {backers.map((backer, i) => (
                <Tr key={i + 1123}>
                  <Td>{backer.name}</Td>
                  <Td>{backer.email}</Td>
                  <Td>${backer.total_amount?.toLocaleString()}</Td>
                  <Td>{backer.times_pledged} Time</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={5}>
          <Heading as="h3" size="md" mb={2}>
            Top Locations
          </Heading>
          <Table variant="simple">
            <TableCaption color={"#e98429"} fontWeight={600}>Top locations of the project</TableCaption>
            <Thead>
              <Tr>
                <Th>Location</Th>
                <Th>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {topLocations.map((location, i) => (
                <Tr key={i}>
                  <Td>{location.location}</Td>
                  <Td>${location.amount?.toLocaleString()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </SimpleGrid>

      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" w={"100%"} p={5} m="auto">
        <Heading as="h3" size="md" mb={2}>
          FAQs Received
        </Heading>
        <Table variant="simple">
          <TableCaption color={"#e98429"} fontWeight={600}>FAQs received for the project</TableCaption>
          <Thead>
            <Tr>
              <Th>Question</Th>
              <Th>Answer</Th>
              <Th>Asked-At</Th>
              <Th>Status</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {faqs.map((faq) => (
              <Tr key={faq._id}>
                <Td>{
                  faq.question.length > 30
                    ? faq.question.substring(0, 30) + "..."
                    : faq.question
                }</Td>
                <Td>{
                  faq.answer.length > 30
                    ? faq.answer.substring(0, 30) + "..."
                    : faq.answer
                }</Td>
                <Td>{formatTimestamp(faq.createdAt)}</Td>
                <Td>{
                  faq.status === "replied"
                    ? <span className="published">
                      Replied
                    </span>
                    : <span className="unpublished">
                      Pending
                    </span>
                }</Td>
                <Td>
                  <Button colorScheme="orange" size={"sm"}
                    onClick={() => {
                      onFaqOpen();
                      setSelectedFaq(faq)
                    }}>Reply</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Modal for reply to the faq */}
      <Modal isOpen={isFaqOpen} onClose={onFaqClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reply to the Question</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Text>
              Question: {selectedFaq?.question || ""}
            </Text>


            <FormControl mt={4}>
              <FormLabel>Answer</FormLabel>
              <Textarea placeholder="Enter the answer"
                value={selectedFaq?.answer || ""}
                onChange={(e) => {
                  setSelectedFaq({ ...selectedFaq, answer: e.target.value });
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={submitFaqAnswer}>
              Submit
            </Button>
            <Button onClick={onFaqClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for submitting withdraw request */}
      <Modal isOpen={isWithdrawOpen} onClose={onWithdrawClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Submit Withdraw Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Text>
              Available Balance: ${projectStats?.available_balance || 0}
            </Text>

            <br />

            <FormControl>
              <FormLabel>Requested Amount</FormLabel>
              <Input placeholder="Enter the amount" type="number"
                min="0" max={projectStats?.available_balance || 0}
                value={withdrawAmount}
                onChange={(e) => {
                  // user should not be able to request more than the available balance
                  if (e.target.value > projectStats?.available_balance) {
                    setWithdrawAmount(projectStats?.available_balance);
                  } else {
                    setWithdrawAmount(e.target.value);
                  }
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea placeholder="Enter the description"
                value={requestDescription}
                onChange={(e) => {
                  setRequestDescription(e.target.value);
                }}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="orange" mr={3} onClick={submitWithdrawRequest}>
              Submit
            </Button>
            <Button onClick={onWithdrawClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for withdraw request details */}
      <Modal isOpen={isWithdrawDetailOpen} onClose={onWithdrawDetailCloseFtn} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Withdraw Request Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

            <Text>
              <b>Requested Amount:</b> ${withDrawDetails?.amount}
            </Text>
            <Text mt={2}>
              <b style={{ marginRight: "5px" }}>Status:</b>
              {
                withDrawDetails?.approval_status === "approved"
                  ? <span className="published">
                    Approved
                  </span>
                  :
                  withDrawDetails?.approval_status === "rejected"
                    ? <span className="unpublished">
                      Rejected
                    </span>
                    :
                    withDrawDetails?.approval_status === "on-hold"
                      ? <span className="onholdStatus">
                        On-Hold
                      </span>
                      :
                      <span className="pendingStatus">
                        Pending
                      </span>
              }
            </Text>
            <Text mt={2}>
              <b>Requested At:</b> {formatTimestamp(withDrawDetails?.createdAt)}
            </Text>
            <Text mt={2}>
              <b>Request Message:</b> {withDrawDetails?.request_message}
            </Text>
            <Text mt={2}>
              <b>Response Message:</b> {withDrawDetails?.response_message}
            </Text>

          </ModalBody>

          <ModalFooter>
            <Button onClick={onWithdrawDetailCloseFtn}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>





    </Box>
  );
};

export default MyProjectStatsComponent;
