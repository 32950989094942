import { Box, Flex, Image, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../libs/axiosService";
import LoadingBox from "../../../Components/shared/Loading";

const MyCampaigns = () => {
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/user-project-lists/" + id);

        if (response.data.projects.length > 0) {
          const filterPublished = response.data.projects.filter((pro) => pro.published === true);
          setProjects(filterPublished);
        }


        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  console.log("projectssss", projects)
  if (loading) {
    return <LoadingBox />;
  }
  return (
    <>
      <Box textAlign={"left"} mt={"40px"}>
        <Text color={"#2A2A2A"} fontSize={"24px"} fontWeight={"600"}>
          Recent Campaigns I'm On
        </Text>

        <Box h={"2px"} my={"20px"} bg={"#F5F5F5"} />

        {projects?.map((pro, i) => (
          <>
            <Flex alignItems={"start"} flexDirection={{ base: "column", lg: "row" }} justifyContent={"space-between"}>
              <Flex flexDirection={{ base: "column", lg: "row" }} alignItems={"start"} gap={"20px"}>
                <Image
                  objectFit={"cover"}
                  w={{ base: "100%", lg: "200px" }}
                  h={{ base: "100%", lg: "200px" }}
                  src={process.env.REACT_APP_API_BASE_URL + pro?.basic?.project_image}
                />
                <Box>
                  <Flex alignItems={"center"} gap={"20px"}>

                    <Text fontSize={"24px"} fontWeight={""} color={"#2a2a2a"}>
                      {pro?.basic?.title}
                    </Text>
                    <Text cursor={"pointer"} py={"5px "} fontSize={"12px"} bg={
                      pro.published ? "#9AE6B4" : "#a8a8a8"

                    } px={"7px"} borderRadius={"2px"}>{pro.published ? " Published" : "Draft"}</Text>
                  </Flex>
                  <Text fontSize={"16px"} fontWeight={""} color={"orange"}>
                    by    {pro?.people?.user?.name}
                  </Text>
                  <Text
                    mt={"20px"}
                    fontSize={"14px"}
                    fontWeight={""}
                    color={"#a8a8a8"}
                  >
                    Campaign ID: {pro?._id}
                  </Text>
                </Box>
              </Flex>
              <Box>
                <Select mt={{ base: "20px", lg: "0px" }} placeholder="Actions"
                  onChange={(e) => {

                    if (e.target.value === "Details") {
                      navigate(`/project/${pro._id}`);
                    }
                  }}
                  w="auto" fontSize={"16px"}>
                  <option style={{ color: "black" }} value="Details">
                    View Details
                  </option>

                </Select>
              </Box>
            </Flex>
            <Box h={"2px"} my={"20px"} bg={"#F5F5F5"} />
          </>
        ))}


      </Box>


    </>
  );
};

export default MyCampaigns;
