import { useEffect, useState } from "react";
import {
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  Avatar
} from "@chakra-ui/react";
import axiosInstance from "../../libs/axiosService";
import LoadingBox from "../shared/Loading";

const AllCreatorsComponent = ({setCreators}) => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const { isOpen: isUserDetailOpen, onOpen: onUserDetailOpen, onClose: onUserDetailClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchUsersList();
  }, []);

  const fetchUsersList = async () => {
    try {
      const response = await axiosInstance.get("/users-list");
      const usersData = response.data.users;
      setCreators(usersData.length);
      setLoading(false);
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await axiosInstance.get(`/single-user/${userId}`);
      setUserDetails(response.data);
      onUserDetailOpen();
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast({
        title: "Error",
        description: error.response.data || "Failed to fetch user details.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden" w={"100%"} p={5} m="auto">
        <Table variant="simple" mt={3}>
          <TableCaption color={"#e98429"} fontWeight={600}>List of All Creators</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Avatar</Th>
              <Th>Total Projects</Th>
              <Th>Total Fundraised</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user) => (
              <Tr key={user._id}>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>
                    {/* <Im src={user.avatar} alt={user.name} style={{ width: "50px", borderRadius: "50%" }} /> */}
                    <Avatar name={user.name} src={process.env.REACT_APP_API_BASE_URL + user?.avatar} />
                    </Td>
                <Td>{user.total_projects}</Td>
                <Td>${user.total_fundraised?.toLocaleString()}</Td>
                <Td>
                  <Button colorScheme="teal" size={"xs"}
                    onClick={() => fetchUserDetails(user._id)}
                  >Details</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Modal for user details */}
      <Modal isOpen={isUserDetailOpen} onClose={onUserDetailClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mt={4} fontSize="lg" fontWeight="bold">
              User Info:
            </Text>
            <Box sx={{ ml: 5, mt: 2 }}>
              <Text><b>Name:</b> {userDetails.name}</Text>
              <Text mt={2}><b>Email:</b> {userDetails.email}</Text>
              <Text mt={2}><b>Biography:</b> {userDetails.biography}</Text>
              <Text mt={2}><b>Location:</b> {userDetails.location}</Text>
              <Text mt={2}><b>Timezone:</b> {userDetails.timezone}</Text>
              <Text mt={2}><b>Website:</b> {userDetails.your_website}</Text>
              <Text mt={2}><b>Vanity URL:</b> {userDetails.vanity_url}</Text>
              <Text mt={2}><b>Referral Code:</b> {userDetails.referralCode}</Text>
              <Text mt={2}><b>Referred By:</b> {userDetails.referredBy}</Text>
            </Box>

            <Text mt={4} fontSize="lg" fontWeight="bold">
              Payment Info:
            </Text>
            <Box sx={{ ml: 5, mt: 2 }}>
              <Text><b>Account Holder Name:</b> {userDetails.payment_info?.account_holder_name}</Text>
              <Text mt={2}><b>Bank Name:</b> {userDetails.payment_info?.bank_name}</Text>
              <Text mt={2}><b>Account Number:</b> {userDetails.payment_info?.account_number}</Text>
              <Text mt={2}><b>IBAN Number:</b> {userDetails.payment_info?.iban_number}</Text>
              <Text mt={2}><b>Payment Method:</b> {userDetails.payment_info?.payment_method}</Text>
            </Box>

            <Text mt={4} fontSize="lg" fontWeight="bold">
              Shipping Address:
            </Text>
            <Box sx={{ ml: 5, mt: 2 }}>
              <Text><b>Address:</b> {userDetails.shipping_address?.address}</Text>
              <Text mt={2}><b>City:</b> {userDetails.shipping_address?.city}</Text>
              <Text mt={2}><b>State:</b> {userDetails.shipping_address?.state}</Text>
              <Text mt={2}><b>Zip:</b> {userDetails.shipping_address?.zip}</Text>
              <Text mt={2}><b>Country:</b> {userDetails.shipping_address?.country}</Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onUserDetailClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AllCreatorsComponent;
