import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Button,
  InputLeftAddon,
  InputGroup,
  Box,
  Image,
  Input,
  Avatar,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { RemindMeModal } from "../../../../Components/RemindMeModal/RemindMeModal";
import { useNavigate, useParams } from "react-router-dom";

export function SeeOptions({ options, withoutPerk, filmDetails, buttonLabel }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [bonus, setBonus] = React.useState(0);
  const navigate = useNavigate();

  const { id } = useParams();
  const toast = useToast();
  return (
    <>


      {
        withoutPerk &&
        <Button
        display={{base:"block",lg:"none"}}
          w={{ base: "100%", lg: "" }}
          bg={"transparent"}
          size={"lg"}
          border={"2px solid #9e9e9e99"}
          fontSize={"17px"}
          fontWeight={600}
          onClick={onOpen}
        >
          Contribute Without Perk
        </Button>
      }


      {options &&
        <Button w={"100%"} size={"lg"} colorScheme="orange" onClick={onOpen} fontSize={"17px"} fontWeight={500}>
          {buttonLabel || "See Options"}
        </Button>}

      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxW={{ base: "100%", lg: "600px" }} maxH={"800px"} >
            <ModalCloseButton />

            <ModalBody overflowY={"auto"} p={"20px"}>
              <Text fontSize={"24px"} fontWeight={"600"} textAlign={"center"}>Back this project</Text>





              <Flex justifyContent={"center"}>
                <Box
                  p={"20px"}
                  maxH={"100vh"}
                  bg={""}
                  color={""}

                >


                {
                  withoutPerk &&
                  <Box>

                  <Text mb={"20px"} textAlign={"start"} fontSize={"25px"}>
                    Support
                  </Text>

                  <Box

                    position={"relative"}
                    textAlign={"start"}
                    border={"1px solid gray"}
                    w={{ base: "100%", md: '450px' }}
                  >
                    {/* <Image src="/images/movies/socialmediashoutout.png" width={""} /> */}
                    <Box p={"20px"}>
                      <Text mt={""} textAlign={"start"} fontSize={""}>
                        Make a pledge without a reward
                      </Text>
                      <Text mt={"20px"} textAlign={"start"} fontSize={""}>
                        Pledge amount
                      </Text>
                      <InputGroup>
                        <InputLeftAddon>US$</InputLeftAddon>
                        <Input type="tel" placeholder="$"
                          onChange={(e) => {
                            setBonus(e.target.value);
                          }}
                        />
                      </InputGroup>

                      {/* <Box
                        backgroundImage={
                          " -webkit-linear-gradient(top, #DBE7FF, #FFF2EC)"
                        }
                        p={"20px"}
                        mt={"15px"}
                      >
                        <Text mt={""}>Back it because you believe in it.</Text>
                        <Text mt={"20px"}>
                          Support the project for no reward, just because it speaks to
                          you.
                        </Text>
                      </Box> */}

                      <Button
                        mt={"25px"}
                        borderRadius={"5px"}
                        colorScheme="orange"
                        width="100%"
                        disabled={bonus === 0}
                        onClick={() => {
                          if (bonus === 0) {
                            toast({
                              title: "Error",
                              description: "Pledge amount should be greater than 0.",
                              status: "error",
                              duration: 3000,
                              isClosable: true,
                            });
                            return;
                          }
                          else {
                            navigate(`/checkout/${id}?noReward=${bonus}`);
                          }
                        }
                        }
                      >
                        PLEDGE NOW
                      </Button>
                    </Box>
                  </Box>

                </Box>}

                    
                  {options &&
                    
                    <Box>

                  <Text mt={"20px"} textAlign={"start"} fontSize={"25px"}>
                    Available Perks
                  </Text>


                  {filmDetails?.rewards?.reward_tiers.map((e, i) => {
                    return (
                      <>
                        <Box
                          my={"20px"}

                          position={"relative"}
                          textAlign={"start"}
                          border={"1px solid gray"}
                          w={{ base: "100%", md: '450px' }}
                        >
                          <Image src={process.env.REACT_APP_API_BASE_URL + e.image || "/images/movies/socialmediashoutout.png"} width={""} />
                          <Box p={"20px"}>
                            <Box display={"flex"} justifyContent={"space-between"}>
                              <Text fontWeight={"bold"}>{e?.title}</Text>
                              <Text>${parseInt(e?.pricing).toLocaleString()}</Text>
                            </Box>
                            <Text fontSize={"14px"}>{e?.description}</Text>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                              mt={"20px"}
                            >
                              {/* <Box>
                                <Text fontSize={"12px"}>Backers</Text>
                                <Text fontSize={"12px"}>0</Text>
                              </Box> */}
                              {e?.shipping?.shipping !== "no" &&
                                <Box>
                                  <Text fontSize={"12px"}>Ships to</Text>
                                  <Text fontSize={"12px"}>{e?.shipping?.destination}</Text>
                                </Box>}
                            </Box>
                            <Text mt={"20px"} fontSize={"12px"}>
                              Estimated delivery
                            </Text>
                            <Text fontSize={"12px"}> {e?.estimated_delivery_month + " " + e?.estimated_delivery_year}</Text>

                            <Text mt={"20px"} fontSize={"12px"}>
                              {e?.items.length} item included
                            </Text>
                            <Text fontSize={"12px"}>
                              {e?.items?.map((e) => {
                                return <span>{e?.title}, </span>
                              })}

                              <Box
                                width={"100%"}
                                mt={"15px"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                gap={"10px"}
                              >
                                {/* <Button borderRadius={"5px"} width="100%">
                          Details
                        </Button> */}
                                <Button
                                  borderRadius={"5px"}
                                  colorScheme="orange"
                                  width="100%"
                                  onClick={() => navigate(`/checkout/${id}?rewardId=${e?._id}`)}
                                >
                                  {/* Pledge US${e?.pricing} */}
                                  GET THIS PERK
                                </Button>
                                {/* <Popover placement='top-start'>
                          <PopoverTrigger>
                            <Button
                              borderRadius={"5px"}
                              colorScheme="orange"
                              width="100%"
                            >
                              Pledge US${e?.pricing}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent>


                            <PopoverCloseButton />
                            <PopoverBody>
                              <Box>


                                <Text mt={"20px"} textAlign={"start"} fontSize={""}>
                                  Shipping destination
                                </Text>
                                <Select placeholder='United States'>
                                  <option value='option1'>Iran</option>
                                  <option value='option2'>Gaza</option>
                                  <option value='option3'>Palestine</option>
                                </Select>

                                <Text mt={"20px"} textAlign={"start"} fontSize={""}>
                                  Pledge amount
                                </Text>
                                <InputGroup>
                                  <InputLeftAddon>US$</InputLeftAddon>
                                  <Input type="tel" placeholder="$$$$" />
                                </InputGroup>
                                <a href="https://stripe.com/">

                                  <Button
                                    mt={"25px"}
                                    borderRadius={"5px"}
                                    colorScheme="orange"
                                    width="100%"
                                  >
                                    Continue
                                  </Button>
                                </a>
                              </Box>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover> */}
                              </Box>
                            </Text>
                          </Box>
                        </Box>
                      </>
                    );
                  })}

                  </Box>}
                  <br />

                </Box>
              </Flex>

            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </>
  );
}
