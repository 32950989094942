import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, FormLabel, Input, Grid, GridItem, useToast, Text, Flex } from "@chakra-ui/react";
import axiosInstance from '../../../libs/axiosService';
import useGetUser from "../../../libs/useGetUser";

const ShippingAddress = () => {
    const { user } = useGetUser();
    const [formData, setFormData] = useState({
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        shipping_address: {
            address: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        }
    });

    useEffect(() => {
        if (user) {
            const { shipping_address } = user;
            setFormData(prevData => ({
                ...prevData,
                shipping_address: {
                    address: shipping_address?.address || '',
                    city: shipping_address?.city || '',
                    state: shipping_address?.state || '',
                    zip: shipping_address?.zip || '',
                    country: shipping_address?.country || '',
                }
            }));
        }
    }, [user]);

    const toast = useToast();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            shipping_address: {
                ...prevData.shipping_address,
                [name]: value
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.put('/profile', { shipping_address: formData.shipping_address });
            toast({
                title: "Success",
                description: "Shipping address added successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: error.response?.data || "An error occurred while adding the shipping address",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box mt="30px">
            <form onSubmit={handleSubmit}>
                
                    
                        <Text textAlign={"start"} fontSize="2xl" mb={4} fontWeight={600}>Shipping Address</Text>
                        <Text textAlign={"start"} fontSize="sm" mb={4}>Please enter your shipping address below.
                        So we can deliver your rewards to the right place.

                        </Text>
                    
                        <Box bg={"#FBFBFA"} p={{base:"10px",lg:"40px"}} borderRadius={"10px"}>
                        <FormControl   w={{base:"100%",lg:"700px"}} isRequired mb={4}>
                            <FormLabel>Address</FormLabel>
                            <Input
                              borderRadius={"1px"}
                            bg={"white"}
                                placeholder="Enter address"
                                name="address"
                                value={formData.shipping_address.address}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl   w={{base:"100%",lg:"700px"}} isRequired mb={4}>
                            <FormLabel>City</FormLabel>
                            <Input
                              borderRadius={"1px"}
                            bg={"white"}
                                placeholder="Enter city"
                                name="city"
                                value={formData.shipping_address.city}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl   w={{base:"100%",lg:"700px"}} isRequired mb={4}>
                            <FormLabel>State</FormLabel>
                            <Input
                              borderRadius={"1px"}
                            bg={"white"}
                                placeholder="Enter state"
                                name="state"
                                value={formData.shipping_address.state}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Box>
                    
                    <Box mt={"30px"} bg={"#FBFBFA"} p={{base:"10px",lg:"40px"}} borderRadius={"10px"}>
                        <FormControl   w={{base:"100%",lg:"700px"}} isRequired mb={4}>
                            <FormLabel>Zip Code</FormLabel>
                            <Input
                              borderRadius={"1px"}
                            bg={"white"}
                                placeholder="Enter zip code"
                                name="zip"
                                value={formData.shipping_address.zip}
                                onChange={handleChange}
                            />
                        </FormControl>
                        <FormControl   w={{base:"100%",lg:"700px"}} isRequired mb={4}>
                            <FormLabel>Country</FormLabel>
                            <Input
                              borderRadius={"1px"}
                            bg={"white"}
                                placeholder="Enter country"
                                name="country"
                                value={formData.shipping_address.country}
                                onChange={handleChange}
                            />
                        </FormControl>
                    
                    <Flex justifyContent={"start"}>

                        <Button type="submit" mb={10} mt={"20px"}  colorScheme="orange"  w={{base:"100%",lg:"700px"}}>
                            Update Shipping Address
                        </Button>
                    </Flex>
                    </Box>
                
            </form>
        </Box>
    );
};

export default ShippingAddress;
