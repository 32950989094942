import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Container, Text } from "@chakra-ui/react";


import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import MyProfile from "./MyProfile/MyProfile";
import useGetUser from "../../libs/useGetUser";
import MyCampaigns from "./MyCampaigns/MyCampaigns";

const ViewFullProfile = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useGetUser();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tabNames = ["Profile", "Campaigns"];

  useEffect(() => {
    const tab = searchParams.get("tab");
    const tabIndex = tabNames.indexOf(tab);
    if (tabIndex !== -1) {
      setActiveTabIndex(tabIndex);
    }
  }, [searchParams, tabNames]);
  const { id } = useParams();
  const handleTabClick = (index) => {
    navigate(`/view-full-profile/${id}?tab=${tabNames[index]}`);
    // setActiveTabIndex(index);
  };
  return (
    <>
      <Navbar />
      <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
        <Text textAlign={"start"} fontSize={"32px"} color={"#2A2A2A"} fontWeight={"600"}>{user?.name}</Text>
        <Box mt={"30px"}>
          <Tabs index={activeTabIndex} onChange={handleTabClick}>
            <TabList display="flex" flexDirection={["row", null, "row"]}>
              {tabNames.map((tabName, index) => (
                <Tab key={index} fontSize={"16px"}>
                  {tabName}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel>
                <MyProfile />
              </TabPanel>
              <TabPanel>
                <MyCampaigns />
              </TabPanel>


            </TabPanels>
          </Tabs>
        </Box>
      </Container>
      <Footer />
    </>
  )
}

export default ViewFullProfile


