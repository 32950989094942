import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, Container, Flex, Grid, Image, Text, useToast } from "@chakra-ui/react";
import CheckoutForm from "./CheckoutForm";
import Navbar from "../Navbar";
import Footer from "../Footer";
import useGetUser from "../../libs/useGetUser";
import { useParams } from "react-router-dom";
import axiosInstance from "../../libs/axiosService";
import { useQuery } from "../../libs/functions";
import LoadingBox from "../shared/Loading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

function ProductCheckoutPage() {
  const [clientSecret, setClientSecret] = useState("");
  const { user } = useGetUser();
  
  const [loading, setLoading] = useState(true);

  const [filmDetails, setFilmDetails] = useState({});
  const { id } = useParams();
  const toast = useToast();
  const query = useQuery();

  const rewardId = query.get("rewardId");
  const noReward = query.get("noReward");
  const [reward, setReward] = useState(null);

  useEffect(() => {
    const fetchFilmDetails = async () => {
      try {
        const response = await axiosInstance.get("/project/" + id);
        setFilmDetails(response.data);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch film details.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    };

    fetchFilmDetails();
  }, [toast, id]);

  useEffect(() => {

    // filter the reward from the rewards array
    if (filmDetails?.rewards?.reward_tiers && rewardId && !noReward) {
      const reward = filmDetails?.rewards?.reward_tiers.find(reward => reward._id === rewardId);
      setReward(reward);
      
    setLoading(false);
    }

    // if no reward is selected
    if (noReward) {
      setReward({ title: "No reward", pricing: noReward });
      
    setLoading(false);
    }
    
  }, [filmDetails, rewardId, noReward]);

  useEffect(() => {
    if (reward?.pricing) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: reward?._id || "gorillafunder_project" }], price: reward?.pricing })
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, [reward]);

  const appearance = {
    theme: "stripe"
  };
  const options = {
    clientSecret,
    appearance
  };



  if (loading) {
    return <>
      <Navbar logoLevel={"2"} />
      <LoadingBox />
      <Footer />
    </>;
  }

  return (
    <>
      <Navbar logoLevel="2" hideSubNav={true} />
      <Container maxW="container.xl" py={{ base: 8, md: 12 }}>
      <Box p={{ base: "20px", md: "30px" }}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={10}>
          <Box>
          <Text fontWeight="600" color={"rgba(0,0,0,0.5)"} fontSize={"16px"} textAlign={"start"}>YOU'RE CONTRIBUTING TO</Text>
          <Text  fontWeight="600" fontSize={"30px"} color={"orange"} textAlign={"start"}>{filmDetails?.basic?.title}</Text>
            <Text textAlign={"start"} fontSize="xl" fontWeight="700">Pledge summary</Text>
            <Text textAlign={"justify"} mt={2}>We will charge right now and hold your payment in our end, but we will release to the creator when campaigns ends.
              You can submit your request to cancel this pledge any time before ending campaign.
              You’ll receive a confirmation email at <b>{user?.email}</b> when your pledge is successfully processed.</Text>
            {filmDetails?.basic?.title && <Flex mt={4} alignItems="center">
              <Image src={
                process.env.REACT_APP_API_BASE_URL + filmDetails?.basic?.project_image
              } alt="Project" boxSize="100px" objectFit="cover" />
              <Box ml={8} textAlign={"left"}>
                <Text textAlign={"start"} fontWeight="bold">{filmDetails?.basic?.title}</Text>
                <Text textAlign={"start"}>{filmDetails?.stats?.funded_percentage?.toFixed(2)}% funded</Text>
                <Text textAlign={"start"}>By {filmDetails?.people?.user?.name}</Text>
              </Box>
            </Flex>}
            {reward && <Box mt={6}>
              <Text fontSize="xl" fontWeight="bold">Your pledge</Text>
              <Flex justify="space-between" mt={2}>
                <Text>Reward</Text>
                <Text>{reward?.title}</Text>
              </Flex>
              {reward?.shipping?.shipping === "yes" && <Flex justify="space-between" mt={2}>
                <Text>Shipping</Text>
                <Text>{reward?.shipping?.destination}</Text>
              </Flex>}
              <Flex justify="space-between" mt={2} fontWeight="bold">
                <Text>Total amount</Text>
                <Text>${ parseInt(reward?.pricing).toLocaleString()}.00</Text>
              </Flex>
            </Box>}
          </Box>
          <Box>
            <Text fontSize="2xl" fontWeight="bold" mb={5}>Add new payment method</Text>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm filmDetails={filmDetails} pricing={reward?.pricing} user={user} />
              </Elements>
            )}

          </Box>
        </Grid>
      </Box>
      </Container>
      <Footer hideSubNav={true} />
    </>
  );
}

export default ProductCheckoutPage;
