
import { Box, Button, Flex, Image, Text, IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import useUpdateProject from "../../../../../../libs/useUpdateProjectService";
import NewAddOns from "./NewAddOns/NewAddOns";
import axiosInstance from "../../../../../../libs/axiosService";

const AddOnItem = ({ addOn, onEdit, onDelete }) => {
  return (
    <Flex
      p={4}
      mb={4}
      borderWidth={1}
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex alignItems="center">
        <Image
          src={process.env.REACT_APP_API_BASE_URL + addOn.image}
          alt={addOn.title}
          boxSize="100px"
          objectFit="cover"
          mr={4}
        />
        <Box sx={{ textAlign: "left", marginLeft: "20px" }}>
          <Text fontWeight="bold" fontSize="lg">
            {addOn.title}
          </Text>
          <Text fontSize="md">${addOn.pricing}</Text>
          <Text>{
            // Truncate the description to 100 characters and add "..." at the end
            addOn.description.length > 100
              ? addOn.description.substring(0, 100) + "..."
              : addOn.description
          }</Text>
        </Box>
      </Flex>
      <Flex>
        <IconButton
          icon={<FaEdit />}
          mr={2}
          onClick={() => onEdit(addOn.id)}
        />
        <IconButton
          icon={<FaTrash />}
          onClick={() => onDelete()}
        />
      </Flex>
    </Flex>
  );
};

const AddOns = ({ project, handleNextClick }) => {
  const [newaddOn, setNewaddOn] = useState(false);
  const [addOnList, setaddOnList] = useState([]);
  const [editaddOn, setEditaddOn] = useState(null);

  useEffect(() => {

    if (project?.rewards?.add_ons.length > 0) {
      setaddOnList(project.rewards.add_ons);
    }

  }, [project]);

  const handleEdit = (addOn) => {
    setEditaddOn(addOn);
    setNewaddOn(true); // Show NewaddOn component for editing
    window.scrollTo(0, 0);
  };
  
  const { id } = useParams();
  const { updateProjectField } = useUpdateProject(id);


  const handleDelete = (index) => {

    // get the item with the given index
    const itemToDelete = addOnList[index];
    console.log('Item to delete:', itemToDelete); 
    if (itemToDelete.image) {
      // delete the image from the server
      axiosInstance.delete(`uploads/${itemToDelete.image.split('/').pop()}`)
        .then(response => {
          console.log('Image deleted successfully');
        })
        .catch(error => console.error('Error deleting image:', error));
    }

    // remove the item from the list
    setaddOnList((prevList) =>
      prevList.filter((item, i) => i !== index)
    );

    const items = addOnList.filter((item, i) => i !== index);

    updateProjectField("rewards", { add_ons: items || [] });
  

  };

  const handleNewaddOn = () => {

    setEditaddOn(null);
    setNewaddOn(true);
    window.scrollTo(0, 0);
  };

  const handleaddOnAdded = (newaddOn) => {
    setaddOnList((prevList) => [...prevList, newaddOn]);
    setNewaddOn(false); // Hide NewaddOn component after adding a new addOn
  };

  const saveContinue = () => {
    updateProjectField("rewards", { add_ons: addOnList });
    handleNextClick();
  };

  return (
    <>
      {!newaddOn && (
        <Box mt={"30px"} textAlign={"start"}>
          <Flex justifyContent={"space-between"}>
            <Text>
              Add-ons are optional reward backers can add to their pledges—accessories, game <br /> expansion packs, movie posters, copies of an earlier publication—that complement <br /> their chosen reward tier.
            </Text>
            <Button
              onClick={handleNewaddOn}
              width={"150px"}
              colorScheme="orange"
              fontWeight={500}
              size={"md"}
              fontSize={"14px"}
            >
              <FaPlus size={"15px"} style={{ marginRight: "10px" }} />
              New Add-On
            </Button>
          </Flex>

          <Text
            mt={"20px"}
            fontSize={""}
            fontWeight={""}
            color={"orange"}
            textDecoration={"underline"}
          >
            Learn about creating and managing Add-Ons
          </Text>
        </Box>
      )}

      {newaddOn && (
        <NewAddOns
          project={project}
          onaddOnAdded={handleaddOnAdded}
          setNewaddOn={setNewaddOn}
          editaddOn={editaddOn}
          setEditaddOn={setEditaddOn}
          setaddOnList={setaddOnList}
          addOnList={addOnList}
        />
      )}

      {(addOnList.length > 0 && !newaddOn) && (
        <Box mt={4}>
          {addOnList.map((addOn, index) => (
            <AddOnItem
              key={index}
              addOn={addOn}
              onEdit={() => handleEdit(addOn)}
              onDelete={() => handleDelete(index)}
            />
          ))}
        </Box>
      )}
      <Flex justifyContent={"end"}>

        <Button
          mt={"20px"}
          w={"160px"}
          colorScheme="orange"
          fontSize={"14px"}
          fontWeight={500}
          onClick={() => {
            saveContinue();
          }}
        >
          Save & Continue
        </Button>
      </Flex>

    </>
  );
};

export default AddOns;
