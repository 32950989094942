export const reward_tiers_initial_state = {
    title: "",
    pricing: "",
    image: "",
    description: "",
    content: {
      content: "no",
      content_type: "",
    },
    shipping: {
      shipping: "no",
      shipping_type: "",
      destination: "",
      shipping_cost: "",
    },
    estimated_delivery_month: "",
    estimated_delivery_year: "",
    project_quantity: {
      project_quantity: "yes",
      quantity_type: "",
      quantity: "",
    },
    time_limit: {
      time_limit: "yes",
      limit_type: "",
      limit_start_date: "",
      limit_end_date: "",
    },
  }

