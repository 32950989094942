import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { Link,  useParams } from "react-router-dom";
import axiosInstance from "../../../../libs/axiosService"; // Adjust the import path accordingly
import useGetUser from "../../../../libs/useGetUser";
import LoadingBox from "../../../../Components/shared/Loading";
import PerksComponent from "../perksComponent";

const Comments = ({ filmDetails }) => {
  const [reply, setReply] = useState(null);
  const [showReplies, setShowReplies] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [replyValue, setReplyValue] = useState("");
  const [editCommentIndex, setEditCommentIndex] = useState(null);
  const [editReplyIndex, setEditReplyIndex] = useState(null);
  const { user } = useGetUser();
  const { id } = useParams();
  const projectId = id;
  const userId = user?._id;

  const [loading, setLoading] = useState(true);

  const [comments, setComments] = useState([]);

  // Fetch comments
  const fetchComments = async () => {
    try {
      const response = await axiosInstance.get(`/comments/${projectId}`);
      setComments(response.data.comments);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  // Create or update a comment
  const handleComment = async () => {
    try {

      setLoading(true);
      if (editCommentIndex !== null) {
        const commentId = comments[editCommentIndex]._id;
        await axiosInstance.put(`/comment/${commentId}`, {
          project_id: projectId,
          comment: commentValue,
          user_id: userId,
        });
        setEditCommentIndex(null);

        setLoading(false);
      } else {
        await axiosInstance.post("/comment", {
          project_id: projectId,
          comment: commentValue,
          user_id: userId,
        });

        setLoading(false);
      }
      await fetchComments();
      setCommentValue("");
    } catch (error) {
      console.error("Error creating/updating comment:", error);
    }
  };

  // Create or update a reply
  const handleReply = async (commentId, commentIndex) => {
    try {

      setLoading(true);
      if (editReplyIndex !== null) {
        const replyId = comments[commentIndex].replies[editReplyIndex]._id;
        await axiosInstance.put(`/comment/${commentId}/reply/${replyId}`, {
          project_id: projectId,
          reply: replyValue,
          user_id: userId,
        });
        setEditReplyIndex(null);

        setLoading(false);
      } else {
        await axiosInstance.post(`/comment-reply/${commentId}`, {
          project_id: projectId,
          reply: replyValue,
          user_id: userId,
        });

        setLoading(false);
      }
      await fetchComments();
      setReply(null);
      setReplyValue("");
    } catch (error) {
      console.error("Error creating/updating reply:", error);
    }
  };

  // Delete a comment
  const handleDeleteComment = async (commentId) => {
    try {

      setLoading(true);
      await axiosInstance.patch(`/comment/${commentId}`, {
        user_id: userId
      });

      setLoading(false);
      await fetchComments();
    } catch (error) {
      console.error("Error deleting comment:", error);

      setLoading(false);
    }
  };

  // Delete a reply
  const handleDeleteReply = async (commentId, replyId) => {
    try {

      setLoading(true);
      await axiosInstance.patch(`/comment/${commentId}/reply/${replyId}`, {
        user_id: userId,
      });
      await fetchComments();

      setLoading(false);
    } catch (error) {
      console.error("Error deleting reply:", error);

      setLoading(false);
    }
  };

  const handleEditComment = (comment, index) => {
    setCommentValue(comment.comment);
    setEditCommentIndex(index);
  };

  const handleEditReply = (reply, commentIndex, replyIndex) => {
    setReplyValue(reply.reply);
    setReply(commentIndex);
    setEditReplyIndex(replyIndex);
  };


  if (loading) {
    return <>
      <LoadingBox />
    </>;
  }

  return (
    <Grid p={{ base: "0px", lg: "" }} mb={"30px"} templateColumns={{ base: "1fr", lg: "2fr 1fr 1fr" }}>
      <GridItem order={{base:2,lg:1}} colSpan={{ base: "1fr" }}>
        {comments.map((comment, commentIndex) => (
          <Flex
            key={comment._id}
            mt={"10px"}
            gap={"20px"}
            alignItems={"start"}
            textAlign={"start"}
            border={"1px solid rgba(0,0,0,0.2)"}
            p={{ base: "5px", lg: "10px" }}
          >
            <Box overflow={"hidden"} w={""} h={""} borderRadius={"50%"}>
              <Avatar size="md" src={process.env.REACT_APP_API_BASE_URL + comment?.user?.avatar} name={comment?.user?.name} />
            </Box>
            <Box>
              <Text fontSize={"20px"} fontWeight={"500"} color={""}>
                {comment?.user?.name}
              </Text>
              <Text fontSize={""} fontWeight={""} color={""}>
                {comment.comment}
              </Text>
              <Flex alignItems={"center"} gap={"15px"}>
                <Text
                  cursor={"pointer"}
                  onClick={() => setReply(commentIndex)}
                  fontSize={"14px"}
                  mt={"5px"}
                  fontWeight={"500"}
                  color={"orange"}
                >
                  Reply
                </Text>
                {comment?.replies?.length > 0 && (
                  <Text
                    cursor={"pointer"}
                    onClick={() => setShowReplies(commentIndex)}
                    fontSize={"14px"}
                    mt={"5px"}
                    fontWeight={"500"}
                    color={"orange"}
                  >
                    Show Replies
                  </Text>
                )}
                {userId === comment.user_id && (
                  <>
                    <Text
                      cursor={"pointer"}
                      onClick={() => handleEditComment(comment, commentIndex)}
                      fontSize={"14px"}
                      mt={"5px"}
                      fontWeight={"500"}
                      color={"orange"}
                    >
                      Edit
                    </Text>
                    <IconButton
                      icon={<FaTrashAlt />}
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={() => handleDeleteComment(comment._id)}
                    />
                  </>
                )}
              </Flex>

              {showReplies === commentIndex && (
                <>
                  {comment?.replies?.map((reply, replyIndex) => (
                    <Flex
                      key={reply._id + replyIndex}
                      w={"500px"}
                      ml={"55px"}
                      mt={"15px"}
                      gap={"20px"}
                      alignItems={"start"}
                      textAlign={"start"}
                      border={"1px solid rgba(0,0,0,0.2)"}
                      p={{ base: "5px", lg: "10px" }}
                    >
                      <Avatar size="sm" src={process.env.REACT_APP_API_BASE_URL + reply?.user?.avatar} name={reply?.user?.name} />
                      <Box w={"100%"}>
                        <Text fontSize={"16"} fontWeight={"500"} color={""}>
                          {reply?.user?.name}
                        </Text>
                        <Text fontSize={"14px"} fontWeight={""} color={""}>
                          {reply?.reply}
                        </Text>
                        {userId === reply.user_id && (
                          <Box sx={{ display: "flex" }}>
                            <Text
                              cursor={"pointer"}
                              onClick={() => handleEditReply(reply, commentIndex, replyIndex)}
                              fontSize={"14px"}
                              mt={"5px"}
                              fontWeight={"500"}
                              color={"orange"}
                            >
                              Edit
                            </Text>
                            <IconButton
                              icon={<FaTrashAlt />}
                              size="sm"
                              colorScheme="red"
                              variant="ghost"
                              onClick={() => handleDeleteReply(comment._id, reply._id)}
                            />
                          </Box>
                        )}
                      </Box>
                    </Flex>
                  ))}
                </>
              )}

              {reply === commentIndex && (
                <Flex mt={"20px"} gap={"20px"} alignItems={"center"}>
                  <Avatar size="sm" src={process.env.REACT_APP_API_BASE_URL + user?.avatar} background={"orange"} name={user?.name} />
                  <Textarea
                    rows={"2"}
                    placeholder="Write a reply"
                    resize={"none"}
                    value={replyValue}
                    onChange={(e) => setReplyValue(e.target.value)}
                  />
                  <Button
                    onClick={() => handleReply(comment._id, commentIndex)}
                    colorScheme="orange"
                    fontSize={"14px"}
                    fontWeight={500}
                    borderRadius={"none"}
                  >
                    {editReplyIndex !== null ? "Update Reply" : "Reply"}
                  </Button>
                </Flex>
              )}
            </Box>
          </Flex>
        ))}

        <Flex mt={"20px"} gap={"20px"} alignItems={"center"}>
          <Avatar size="sm" src={process.env.REACT_APP_API_BASE_URL + user?.avatar} background={"orange"} name={user?.name} />
          <Textarea
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
            rows={"2"}
            placeholder="Write a comment"
            resize={"none"}
          />
          <Button
            onClick={handleComment}
            colorScheme="orange"
            fontSize={"14px"}
            fontWeight={500}
            borderRadius={"none"}
          >
            {editCommentIndex !== null ? "Update Comment" : "Comment"}
          </Button>
        </Flex>
      </GridItem>
      <GridItem order={{base:3,lg:2}} colSpan={{ base: "1fr" }}>
        <Box>
          <Box textAlign={"start"} mt={"30px"} p={"20px"}>
            <Text fontSize={"14px"}>
              This is your space to offer support and feedback. Remember to be constructive—there's a human behind this project.
            </Text>
            <Box mt={"20px"}>
              <Text fontSize={"15px"}>Have a question for the creator?</Text>
              <Link to={"/film/" + id + "?tab=faq"}>
                <Text fontSize={"14px"} fontWeight={"bold"} textDecoration={"underline"}>
                  Check this project's FAQ
                </Text>
              </Link>
            </Box>
          </Box>
        </Box>
      </GridItem>

      <GridItem order={{base:1,lg:3}}  colSpan={{ base: "1fr" }}>
          
          <PerksComponent filmDetails={filmDetails} />

        </GridItem>

    </Grid>
  );
};

export default Comments;
