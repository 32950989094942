import { VStack } from "@chakra-ui/react";
import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import MyProjectStatsComponent from "../../Components/MyProjectStatsComponent";

const ProjectDetails = () => {


	return (
		<>
			<VStack spacing={"auto"}>
				<Navbar logoLevel="2" />
				<MyProjectStatsComponent />
			</VStack>
			<Footer />
		</>
	);
};

export default ProjectDetails;
