import { useEffect, useState } from "react";
import { Flex, Heading, Text, Image, Box, Button, VStack, Grid, GridItem } from "@chakra-ui/react";
import axiosInstance from "../libs/axiosService";
import { useNavigate } from "react-router-dom";
import LoadingBox from "./shared/Loading";

const MyBacklistComponent = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/user-pledge-projects");
        setProjects(response.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [])

  if (loading) {
    return <LoadingBox />;
  }

  return (
    <Box mb={5} mt={2}>
      <Heading as="h2" size="lg" p="3vh">
        Track Your Backlist
      </Heading>
      <Text className="sub_headings">
        Here you can see the list of projects you have backed.
      </Text>
      <Flex flexWrap="wrap" justifyContent="center" sx={{ marginBottom: "20px" }}>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {projects?.totalProjectsPledged || 0}
          </Heading>
          <Text>total backed project</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            ${projects?.totalAmountPledged?.toLocaleString() || 0}
          </Heading>
          <Text>total pledged amount</Text>
        </Flex>
        <Flex flexDirection="column" borderWidth="1px" w={["90%", "23vw"]} p={5} m={2} textAlign="center">
          <Heading as="h3" fontWeight="normal" color="#e98429">
            {projects?.totalPledges || 0}
          </Heading>
          <Text>total pledges</Text>
        </Flex>
      </Flex>
      <VStack spacing={4}>
        {
          projects?.projectsPledged?.length > 0 ?
            projects?.projectsPledged?.map((project, index) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
                w={"90%"}
                p={5}
                m={2}
              >

                <Grid templateColumns="repeat(7, 1fr)" gap={6} alignItems="center" textAlign={"left"}>
                  <GridItem colSpan={[7, 1]}>
                    <Image
                      borderRadius={8}
                      src={process.env.REACT_APP_API_BASE_URL + project.project_image}
                      alt={project.title}
                      boxSize="100px"
                      objectFit="cover"
                    />
                  </GridItem>
                  <GridItem colSpan={[7, 2]}>
                    <Text><strong>Title:</strong> {project.title}</Text>
                    <Text><strong>Subcategory:</strong> {project.subcategory}</Text>
                  </GridItem>
                  <GridItem colSpan={[7, 1]}>
                    <Text><strong>Funding Goal:</strong> ${project.funding_goal_amount?.toLocaleString()}</Text>
                  </GridItem>
                  <GridItem colSpan={[7, 1]}>
                    <Text><strong>My Pledge:</strong> ${project.my_pledge_amount?.toLocaleString() || "0"}</Text>
                  </GridItem>

                  <GridItem colSpan={[7, 1]}>
                    <Box >

                      <Button colorScheme="green" size="sm" ml={2} onClick={() =>
                        navigate(`/film/${project.project_id}`)
                      }>
                        View Details
                      </Button>
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
            ))
            :
            <Box display="flex"
              alignItems="center"
              justifyContent="center"
              height="200px"
              width="100%"
              bg="white.100"
              borderRadius="md"
              boxShadow="md">
              You haven't support any project yet.
            </Box>
        }
      </VStack>
    </Box>
  );
};

export default MyBacklistComponent;
