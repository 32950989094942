import React, { useEffect, useState } from "react";
import Navbar from "../../../../Components/Navbar";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useToast,
} from "@chakra-ui/react";
// import {
//   Breadcrumb,
//   BreadcrumbItem,
//   BreadcrumbLink,
// } from "@chakra-ui/react";
// import { MdChevronRight } from "react-icons/md";
import FluidContainer from "../../../../Components/shared/containerFluid";
import { FaCaretRight } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../libs/axiosService";
import LoadingBox from "../../../../Components/shared/Loading";
import Footer from "../../../../Components/Footer";

const Category = () => {
  // const [faq, setfaq] = useState("zero");
  const [filmDetails, setFilmDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const [bonus, setBonus] = useState(0);
  const { id } = useParams();
  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFilmDetails = async () => {
      try {
        const response = await axiosInstance.get("/project/" + id);
        setFilmDetails(response.data);
        setLoading(false);
      } catch (error) {
        toast({
          title: "Error",
          description: error.response?.data || "Failed to fetch film details.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      }
    };

    fetchFilmDetails();
  }, [toast, id]);

  const [faq, setFaq] = useState("zero");
  const [pledgeReward, setPledgeReward] = useState(false);

  const handleClickFaq = (e) => {
    // if click on same faq then close it
    if (faq === e) {
      setFaq("zero");
      return;
    }
    setFaq(e);
  };


  if (loading) {
    return <>
      <Navbar logoLevel={"2"} />
      <LoadingBox />
      <Footer />
    </>;
  }

  return (
    <>
      <Navbar logoLevel={"2"} />
      <Box py={"50px"}>
        <Text fontSize={"35px"} fontWeight={""}>
          {filmDetails?.basic?.title} - {filmDetails?.basic?.subcategory}
        </Text>
        <Text fontSize={""} fontWeight={""}>
          by {filmDetails?.people?.user?.name}
        </Text>
      </Box>

      <FluidContainer>
        <Box px={{ base: "0", lg: "100px" }}>
          {/* <Breadcrumb spacing="8px" separator={<MdChevronRight size={""} />}>
          <BreadcrumbItem>
            <BreadcrumbLink fontSize={"20px"} href="#">
              REWARDS
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink fontSize={"20px"} href="#">
              ADD-ONS
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink fontSize={"20px"} href="#">
              PAYMENT
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb> */}

          <Box mt={"50px"} textAlign={"start"}>
            <Text fontSize={{ base: "", lg: "30px" }} fontWeight={""}>
              Select your reward
            </Text>
            <Text mt={""} fontSize={""} fontWeight={""}>
              Select an option below
            </Text>

            <Box
              position={"relative"}
              mt={"20px"}
              border={"1px solid rgba(0,0,0,0.2)"}
              p={"20px"}
            >
              <Flex>
                <Text fontSize={"20px"}>
                  Pledge without a reward
                </Text>

                {!pledgeReward && <Button
                  w={"20%"}
                  onClick={() => setPledgeReward(!pledgeReward)}
                  colorScheme="orange"
                  fontSize={"14px"}
                  fontWeight={500}
                  borderRadius={"none"}
                  position={"absolute"}
                  right={"15px"}
                  bottom={"16px"}
                >
                  Pledge Now
                </Button>}
              </Flex>

              {pledgeReward && (
                <>
                  <Text
                    fontSize={"12px"}
                    p={""}
                    borderRadius={""}
                    mt={"20px"}
                    fontWeight={""}
                  >
                    Bonus support(optional)
                  </Text>
                  <Flex alignItems={"center"} gap={"20px"}>
                    <InputGroup w={"70%"}>
                      <InputLeftAddon>$</InputLeftAddon>
                      <Input type="number" placeholder="0"
                        onChange={(e) => setBonus(e.target.value)}
                      />
                    </InputGroup>
                    <Button
                      mt={""}
                      w={"30%"}
                      //   W={{base:"100%",lg:"250px"}}
                      colorScheme="orange"
                      fontSize={"14px"}
                      disabled={bonus === 0}
                      fontWeight={500}
                      borderRadius={"none"}
                      onClick={() => {
                        if (bonus === 0) {
                          toast({
                            title: "Error",
                            description: "Pledge amount should be greater than 0.",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                          });
                          return;
                        }
                        else {
                          navigate(`/checkout/${id}?noReward=${bonus}`);
                        }
                      }}
                    >
                      Pledge
                    </Button>
                    <Button
                      onClick={() => setPledgeReward(!pledgeReward)}
                      fontWeight={500}
                      borderRadius={"none"}>
                      Cancel
                    </Button>
                  </Flex>
                </>
              )}
            </Box>
          </Box>

          <Grid mb={10} templateColumns={{ base: "1fr", lg: "2fr 1fr" }} gap={"20px"}>
            <GridItem colSpan={{ base: "1fr" }}>
              {
                filmDetails?.rewards?.reward_tiers?.length > 0 ?
                  filmDetails?.rewards?.reward_tiers.map((reward, index) => (
                    <Box
                      textAlign={"start"}
                      mt={"20px"}
                      border={"1px solid rgba(0,0,0,0.2)"}
                      p={"20px"}
                    >
                      <Text fontSize={"22px"} fontWeight={""}>
                        Pledge ${reward?.pricing}
                      </Text>
                      <Text mt={"10px"} fontSize={"20px"} fontWeight={"500"}>
                        {
                          reward?.title
                        }
                      </Text>
                      <Text
                        mt={"10px"}
                        fontSize={""}
                        w={{ base: "100%", lg: "400px" }}
                        fontWeight={""}
                        color={"#656969"}
                      >
                        {
                          reward?.description
                        }
                      </Text>
                      {reward?.items.length > 0 &&

                        <Text mt={"10px"} fontSize={""} fontWeight={""}>
                          INCLUDES
                        </Text>}
                      {
                        reward?.items?.map((include, index) => (
                          <Flex mt={"10px"} alignItems={"center"} gap={"20px"}>
                            <Box bg={"black"} borderRadius={"50%"} p={"3px"} />
                            <Text mt={""} fontSize={""} fontWeight={""}>
                              {include?.title}
                            </Text>
                          </Flex>
                        ))
                      }
                      <Text mt={"20px"} fontSize={""} fontWeight={""}>
                        ESTIMATED DELIVERY
                      </Text>
                      <Text mt={"5px"} fontSize={""} fontWeight={""}>
                        {reward?.estimated_delivery_month} {reward?.estimated_delivery_year}
                      </Text>
                      <Text
                        bg={"gray.50"}
                        p={"10px"}
                        w={"fit-content"}
                        borderRadius={"5px"}
                        mt={"20px"}
                        fontSize={"15px"}
                        fontWeight={""}
                      >
                        {reward?.backers || 0} backers
                      </Text>

                      <Flex alignItems={"center"} gap={"20px"}>
                        {/* <InputGroup w={"70%"}>
                          <InputLeftAddon>$</InputLeftAddon>
                          <Input type="number"
                            min={reward?.pricing}                            
                          placeholder="0" />
                        </InputGroup> */}
                        <Button
                          mt={"20px"}
                          w={"30%"}
                          colorScheme="orange"
                          fontSize={"14px"}
                          fontWeight={500}
                          borderRadius={"none"}
                          onClick={() => {
                            navigate(`/checkout/${id}?rewardId=${reward?._id}`);
                          }}
                        >
                          Pledge ${reward?.pricing}
                        </Button>
                      </Flex>
                    </Box>
                  )
                  )
                  :
                  (
                    <Box
                      textAlign={"start"}
                      mt={"20px"}
                      border={"1px solid rgba(0,0,0,0.2)"}
                      p={"20px"}
                    >
                      <Text fontSize={"22px"} fontWeight={""}>
                        No Reward Found Yet
                      </Text>
                    </Box>
                  )
              }
            </GridItem>
            <GridItem colSpan={{ base: "1fr" }}>
              <Box mt={"20px"} textAlign={"start"}>
                <Text fontSize={"14px"}>
                  Your pledge will support an ambitious creative project that has
                  yet to be developed. There’s a risk that, despite a creator’s
                  best efforts, your reward will not be fulfilled, and we urge you
                  to consider this risk prior to pledging. Gorillafunder is not
                  responsible for project claims or reward fulfillment.
                </Text>
                <Text mt={"15px"} textDecoration={"underline"} fontSize={"14px"}>
                  Learn more about accountability
                </Text>
                <Text fontWeight={"600"} mt={"15px"} fontSize={"14px"}>
                  FREQUENTLY ASKED QUESTIONS
                </Text>
                <Box>
                  <Flex
                    mt={"10px"}
                    alignItems={"center"}
                    onClick={() => handleClickFaq("first")}
                  >
                    {faq === "first" ? <FaCaretDown /> : <FaCaretRight />}

                    <Text fontWeight={""} fontSize={"14px"}>
                      How do I pledge?
                    </Text>
                  </Flex>
                  {faq === "first" && (
                    <Text mt={"5px"} fontWeight={""} fontSize={"14px"}>
                      Enter your pledge amount and select a reward. Then, enter
                      your payment information to complete the checkout process.
                    </Text>
                  )}
                </Box>

                <Box>
                  <Flex
                    mt={"10px"}
                    alignItems={"center"}
                    onClick={() => handleClickFaq("second")}
                  >
                    {faq === "second" ? <FaCaretDown /> : <FaCaretRight />}

                    <Text fontWeight={""} fontSize={"14px"}>
                      When is my card charged?
                    </Text>
                  </Flex>
                  {faq === "second" && (
                    <Text mt={"5px"} fontWeight={""} fontSize={"14px"}>
                      If this project is successfully funded, your card will be
                      charged on Sat, May 25 2024 3:00 AM, along with all the
                      other backers of this project.
                    </Text>
                  )}
                </Box>

                <Box>
                  <Flex
                    mt={"10px"}
                    alignItems={"center"}
                    onClick={() => handleClickFaq("third")}
                  >
                    {faq === "third" ? <FaCaretDown /> : <FaCaretRight />}

                    <Text fontWeight={""} fontSize={"14px"}>
                      So I'm only charged if funding succeeds?
                    </Text>
                  </Flex>
                  {faq === "third" && (
                    <Text mt={"5px"} fontWeight={""} fontSize={"14px"}>
                      Yes! That's part of what makes Gorillafunder special. If a
                      project isn't successfully funded, no one pays anything.
                    </Text>
                  )}
                </Box>

                <Box>
                  <Flex
                    mt={"10px"}
                    alignItems={"center"}
                    onClick={() => handleClickFaq("fourth")}
                  >
                    {faq === "fourth" ? <FaCaretDown /> : <FaCaretRight />}

                    <Text fontWeight={""} fontSize={"14px"}>
                      What can others see about my pledge?
                    </Text>
                  </Flex>
                  {faq === "fourth" && (
                    <Text mt={"5px"} fontWeight={""} fontSize={"14px"}>
                      The project will be added to the list of backings on your
                      profile page, but the amount you pledge, and the reward you
                      choose, will not be made public.
                    </Text>
                  )}
                </Box>
                <Box>
                  <Flex
                    mt={"10px"}
                    alignItems={"center"}
                    onClick={() => handleClickFaq("fifth")}
                  >
                    {faq === "fifth" ? <FaCaretDown /> : <FaCaretRight />}

                    <Text fontWeight={""} fontSize={"14px"}>
                      What if I want to change my pledge?
                    </Text>
                  </Flex>
                  {faq === "fifth" && (
                    <Text mt={"5px"} fontWeight={""} fontSize={"14px"}>
                      You can change or cancel your pledge anytime before Sat, May
                      25 2024 3:00 AM.
                    </Text>
                  )}
                </Box>
                <Box>
                  <Flex
                    mt={"10px"}
                    alignItems={"center"}
                    onClick={() => handleClickFaq("sixth")}
                  >
                    {faq === "sixth" ? <FaCaretDown /> : <FaCaretRight />}

                    <Text fontWeight={""} fontSize={"14px"}>
                      If this project is funded, how do I get my reward?
                    </Text>
                  </Flex>
                  {faq === "sixth" && (
                    <Text mt={"5px"} fontWeight={""} fontSize={"14px"}>
                      When your reward is ready, {filmDetails?.people?.user?.name} will send you a
                      survey via email to request any info needed to deliver your
                      reward (mailing address, T-shirt size, etc).
                    </Text>
                  )}
                </Box>
              </Box>
            </GridItem>
          </Grid>




        </Box>
      </FluidContainer>
      <Footer />
    </>
  );
};

export default Category;
