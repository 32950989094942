import { Box, Flex, Image, Select, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../libs/axiosService";
import LoadingBox from "../../../../Components/shared/Loading";
import useGetUser from "../../../../libs/useGetUser";

const MyCampaigns = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({});
  const navigate = useNavigate();
  const { user } = useGetUser();
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/user-project-lists/" + user?._id);
        setProjects(response.data.projects);

        const statsResponse = await axiosInstance.get("/user-stats");
        setStats(statsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleEdit = (projectId) => {
    navigate(`/edit/${projectId}`);
  };
  console.log("projectssss", projects)
  console.log("stats", stats)

  if (loading) {
    return <LoadingBox />;
  }
  return (
    <>
      <Box textAlign={"left"} mt={"40px"}>
        <Text color={"#2A2A2A"} fontSize={"24px"} fontWeight={"600"}>
          Campaigns I'm On
        </Text>

        <Box h={"2px"} my={"20px"} bg={"#F5F5F5"} />

        {projects?.map((pro, i) => (
          <>
            <Flex alignItems={"start"} flexDirection={{ base: "column", lg: "row" }} justifyContent={"space-between"}>
              <Flex flexDirection={{ base: "column", lg: "row" }} alignItems={"start"} gap={"20px"}>
                <Image
                  objectFit={"cover"}
                  w={{ base: "100%", lg: "200px" }}
                  h={{ base: "100%", lg: "200px" }}
                  src={process.env.REACT_APP_API_BASE_URL + pro?.basic?.project_image}
                />
                <Box>
                  <Flex alignItems={"center"} gap={"20px"}>

                    <Text fontSize={"24px"} fontWeight={""} color={"#2a2a2a"}>
                      {pro?.basic?.title}
                    </Text>
                    <Text cursor={"pointer"} py={"5px "} fontSize={"12px"} bg={
                      pro.published ? "#9AE6B4" : "#a8a8a8"

                    } px={"7px"} borderRadius={"2px"}>{pro.published ? " Published" : "Draft"}</Text>
                      <Tooltip
                    px={"40px"}
                    textAlign={"center"}
                    py={"20px"}
                    sx={{ bg: "#E6E6E7" }}
                    color={"#1f0733"}
                    label={
                      pro.approval === "approved" ? "Your campaign has been approved and is live on the platform." : 
                      pro.approval === "pending" ? "Your campaign is pending." :
                      pro.approval === "requested" ? "Your campaign is requested for approval." :
                      pro.approval === "rejected" ? "Your campaign has been rejected by Admin." : ""
                    }
                    placement="top"
                  >
                    <Text cursor={"pointer"} py={"5px "} fontSize={"12px"} bg={
                      pro.approval === "approved" ? "orange" : 
                      pro.approval === "pending" ? "#a8a8a8" :
                      pro.approval === "requested" ? "#a8a8a8" :
                      pro.approval === "rejected" ? "#FF6347" : "#a8a8a8"

                    } px={"7px"} borderRadius={"2px"}>{
                      pro.approval === "approved" ? "Approved" : 
                      pro.approval === "pending" ? "Pending" : 
                      pro.approval === "requested" ? "Requested" :
                      pro.approval === "rejected" ? "Rejected" : 
                      ""}</Text>
                      </Tooltip>
                  </Flex>
                  <Text fontSize={"16px"} fontWeight={""} color={"orange"}>
                    by    {pro?.people?.user?.name}
                  </Text>
                  <Text
                    mt={"20px"}
                    fontSize={"14px"}
                    fontWeight={""}
                    color={"#a8a8a8"}
                  >
                    Campaign ID: {pro?._id}
                  </Text>
                </Box>
              </Flex>
              <Box>
                <Select mt={{ base: "20px", lg: "0px" }} placeholder="Actions"
                  onChange={(e) => {
                    if (e.target.value === "Edit") {
                      handleEdit(pro._id);
                    }
                    if (e.target.value === "Details") {
                      navigate(`/project/${pro._id}`);
                    }
                  }}
                  w="auto" fontSize={"16px"}>
                  {pro?.published &&
                    <option style={{ color: "black" }} value="Details">
                      View Details
                    </option>
                  }
                  <option style={{ color: "black" }} value="Edit">
                    Edit Campaign
                  </option>
                </Select>
              </Box>
            </Flex>
            <Box h={"2px"} my={"20px"} bg={"#F5F5F5"} />
          </>
        ))}


      </Box>


    </>
  );
};

export default MyCampaigns;
